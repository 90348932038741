import { fetchWithRetry, Fetcher } from "./fetcher.js";
import apiUrl from "./url.js";
import { store } from "../store.js";
import { tr } from "./translation.js";
import { notify } from "../actions/notification.js";
import * as userActions from "../actions/user.js";

const fetchPublishers = (callback) => {
  // Initialize available publishers
  return fetchWithRetry("getJson", [apiUrl("publishers", false)])
    .then((data) => {
      const publishers = {};
      for (const item of data) {
        publishers[item.name] = item.api_prefix;
      }

      // Set the first publisher to being currently selected
      const current = data[0].name;

      getUserData("publisher")
        .then((response) => {
          if (response === null) {
            callback(publishers, current);
          } else {
            callback(publishers, response);
          }
        })
        .catch((error) => {
          throw error;
        });
    })
    .catch(() => {
      store.dispatch(notify(tr("dbConnectFailure"), "error"));
      callback(null, null);
    });
};

export const initPublishers = (callbackFunc) => {
  fetchPublishers((publishers, current) => {
    if (publishers === null && current === null) {
      store.dispatch(notify(tr("publisherFetchFailed"), "error"));
      callbackFunc(false);
    } else {
      // Always show WebKayak in title
      // document.title = current;
      store.dispatch(userActions.setPublishers(publishers, current));
      callbackFunc(true);
    }
  });
};

export const userReady = () => {
  const user = store.getState().user;
  const publishers = user.publishers;

  const propertiesReady = Object.keys(user.properties).length > 0;
  const publishersReady =
    Object.keys(publishers.publishers).length > 0 && publishers.current !== "";

  return propertiesReady && publishersReady;
};

/*
 * User properties include information which is necessary for the application.
 * This includes name, id, and email information.
 */
export const getUserProperties = (callback) => {
  const url = apiUrl("users/properties", false);

  fetchWithRetry("getJson", [url])
    .then((response) => {
      let properties = {};
      if (response !== null) {
        properties = {
          username: response.username,
          userid: response.userid,
          email: response.emailaddress,
        };
      }
      store.dispatch(userActions.setUserInfo(properties));
      callback();
    })
    .catch(() => {
      store.dispatch(notify(tr("dbConnectFailure"), "error"));
      callback();
    });
};

/*
 * User properties include information which not mandatory for the application.
 * This includes table column visibilities.
 */
export const getUserSettings = (callback = null) => {
  return getUserData("settings")
    .then((data) => {
      if (data !== null) {
        try {
          const parsed = JSON.parse(data);
          store.dispatch(userActions.setUserSettings(parsed));
        } catch (error) {
          store.dispatch(notify(tr("userSettingsInvalid"), "warning"));
        }
      }
      if (callback !== null) {
        callback();
      }
    })
    .catch(() => {
      store.dispatch(notify(tr("dbConnectFailure"), "error"));

      if (callback !== null) {
        callback();
      }
    });
};

/*
 * Save user's settings into Kayak UsersAPI under the key 'settings'.
 *
 * Parameter 'newState' should be an object containing desired new values.
 * The given object will be merged with the existing user settings.
 *
 * Example:
 *  old settings: { table: { columns: { feedback: [6] } } }
 *  newState:     { table: { columns: { subs: [1, 2] } } }
 *  new settings: { table: { columns: { feedback: [6], subs: [1, 2] } } }
 */
export const setUserSettings = (newState) => {
  const origState = Object.assign({}, store.getState().user.settings);
  _combineObjects(newState, origState);

  return setUserData("settings", JSON.stringify(origState));
};

const _isObject = (variable) => {
  return variable !== null && typeof variable === "object";
};

const _combineObjects = (newObj, origObj) => {
  for (const [key, value] of Object.entries(newObj)) {
    if (
      // Do not access Object.prototype method 'hasOwnProperty' from target object.
      // https://stackoverflow.com/a/51148270 - Object.prototype.hasOwnProperty.call(origObj, key)
      // eslint-disable-next-line no-prototype-builtins
      origObj.hasOwnProperty(key) &&
      _isObject(value) &&
      _isObject(origObj[key])
    ) {
      _combineObjects(value, origObj[key]);
    } else {
      origObj[key] = value;
    }
  }
};

/*
 * Kayak has a separate user database for storing user specific information.
 * The user database's API allows saving miscellaneous key-value data pairs
 * to the database. The following functions getUserData and setUserData are
 * for utilizing this functionality.
 */

// Retrieve user's data from the database
export const getUserData = (key) => {
  const url = apiUrl(`users/data/${key}`, false);

  return fetchWithRetry("getJson", [url])
    .then((data) => {
      return data.value;
    })
    .catch((error) => {
      // Data for the key has not been set
      if (error.status === 404) {
        return null;
      } else {
        // Unexpected error so just throw it back
        let err = new Error();
        err = { ...error };
        throw err;
      }
    });
};

// Save user's data to the database
export const setUserData = (key, value) => {
  const url = apiUrl(`users/data/${key}`, false);
  const body = { value };

  return Fetcher.post(url, body);
};

/**
 * Check if user has access to certain action in Kayak
 * @param {string} accessRight
 */
export const userHasAccess = (accessRight) => {
  const DENIED_MODULE_NAMES = store.getState().user.DENIED_MODULE_NAMES;
  const result = DENIED_MODULE_NAMES.has(accessRight);

  // Return the opposite of result for slightly easier understanding
  return !result;
};
