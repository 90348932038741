import { store } from "../store.js";
import * as actions from "../actions/translations.js";
import { notify } from "../actions/notification.js";
import * as storage from "./storage.js";
import { Fetcher, fetchWithRetry } from "./fetcher.js";
import apiUrl from "./url.js";
import { updateLocale } from "./locale.js";
import translationData from "./translationData.js";
import { getUserData, setUserData } from "./user.js";

/*
 * Functions to handle application's translation needs.
 */

// Download translations from the server
const _getTranslations = ({ langCode = null, retry = false } = {}) => {
  const resource = langCode === null ? "translations" : `translations/${langCode}`;
  const url = apiUrl(resource, false);

  return retry ? fetchWithRetry("getJson", [url]) : Fetcher.getJson(url);
};

// Retrieve available translations from the server and set them into the Redux store
const initAvailableTranslations = (callback) => {
  _getTranslations({ retry: true })
    .then((data) => {
      store.dispatch(actions.setAvailableTranslations(data));
      callback();
    })
    .catch(() => {
      store.dispatch(notify(tr("dbConnectFailure"), "error"));
    });
};

// If localStorage has translation saved, take it into use. After that, if the
// user is authenticated, try to fetch user's language preference from the server.
// If no language can be determined, default to 'en-GB' (English).
const initTranslation = (authenticated, callback) => {
  // Build 3.6.4.2 (Issue #33376): Yabing: default language should be language of browser
  // const defaultLang = "en-GB";
  const defaultLang = browserLanguage();

  const result = storage.translation();
  if (result !== null) {
    setAndSwitchStoreTranslation(result.langCode, result.translations);
    updateLocale(result.langCode, null);
  }

  // For debug purposes, get translation debug mode from localStorage and set it to state
  const translationDebugMode = storage.getTranslationDebugMode();
  if (translationDebugMode !== null) {
    store.dispatch(actions.setTranslationDebugMode(translationDebugMode));
  } else {
    storage.writeTranslationDebugMode("TRANSLATION_ONLY");
  }

  if (authenticated) {
    getUserData("translation")
      .then((response) => {
        const current = result !== null ? result.langCode : null;

        if (response !== null && response !== current) {
          switchTranslation(response, { callback });
          return;
        } else if (current === null) {
          switchTranslation(defaultLang, { callback });
          return;
        }

        callback();
      })
      .catch((error) => {
        throw error;
      });
  } else if (result === null) {
    switchTranslation(defaultLang, { callback });
    return;
  }

  callback();
};

// Fetch user's preferred language from the user database.
// #33136, add shouldChangeTranslation to prevent changing translation when there is language specified from login url
const initUserTranslation = (callback, shouldChangeTranslation = true) => {
  getUserData("translation")
    .then((response) => {
      if (response !== null) {
        if (response !== store.getState().translation.current && shouldChangeTranslation) {
          switchTranslation(response, { callback });
          return;
        }
      }
      callback();
    })
    .catch((error) => {
      throw error;
    });
};

// #27619
// Switch to desired text mode: translation only, translation and code, code only
const switchTextMode = (textMode) => {
  storage.writeTranslationDebugMode(textMode);
  store.dispatch(actions.setTranslationDebugMode(textMode));
  // switchTranslation(store.getState().translation.current);
};

// Switch to desired translation. If a translation is not available in Redux store
// or localStorage, try requesting it from the server.
const switchTranslation = (langCode, { callback = null, retry = false } = {}) => {
  const finish = () => {
    updateLocale(langCode, null);

    // Save user's language to the database if the user is authenticated
    if (store.getState().user.authenticated) {
      setUserData("translation", langCode);
    }

    store.dispatch(actions.setProcessing(false));

    if (callback !== null) {
      callback();
    }
  };

  const state = store.getState().translation;

  // Abort if language is not supported
  if (!(langCode in state.available)) {
    store.dispatch(notify(tr("unsupportedLanguage"), "warning"));
    return;
  }

  store.dispatch(actions.setProcessing(true));

  // Check translation availability from the Redux store
  if (state.translations !== undefined && langCode in state.translations) {
    store.dispatch(actions.switchTranslation(langCode));
    storage.writeTranslation(langCode, state.translations[langCode]);
    finish();
    return;
  }

  // Check translation availability from the browser cache
  const result = storage.translation();
  if (result !== null && result.langCode === langCode) {
    setAndSwitchStoreTranslation(result.langCode, result.translations);
    finish();
  } else {
    // Download the translation from the server
    _getTranslations({ langCode, retry })
      .then((data) => {
        setAndSwitchStoreTranslation(langCode, data);
        storage.writeTranslation(langCode, data);
        finish();
      })
      .catch(() => {
        store.dispatch(notify(tr("dbConnectFailure"), "error"));
        store.dispatch(actions.setProcessing(false));
      });
  }
};

// Set given translation to Redux store and switch to it
const setAndSwitchStoreTranslation = (langCode, translations) => {
  store.dispatch(actions.setTranslation(langCode, translations));
  store.dispatch(actions.switchTranslation(langCode));
};

// Check whether the current translation is ready and available in the Redux store
const translationReady = () => {
  const state = store.getState().translation;
  const current = state.current;
  const translations = state.translations;

  if (current !== undefined && translations !== undefined && current in translations) {
    return true;
  }

  return false;
};

// Parse and return browser's default language
const browserLanguage = () => {
  const language = navigator.language.toLowerCase().substring(0, 2);
  // Convert browser language code to format that API supports
  if (language === "fi") {
    return "fi-FI";
  } else if (language === "sv") {
    return "sv-SE";
  } else if (language === "de") {
    return "de-DE";
  } else if (language === "en") {
    // Build 3.6.4.2 (Issue #33376):
    return "en-GB";
  }

  return language;
};

// Retrieve translation value for the given id
const tr = (id) => {
  const translation = translationData[id];

  // Debug translations, options TRANSLATION_ONLY, TRANSLATION_AND_CODE, ONLY_CODE
  const TRANS_CODE_DEBUG = store.getState().translation.translationDebugMode;

  // Each used translation should exist in the translationData.js so we can throw
  // an exception if it doesn't exist for some reason.
  if (!translation) {
    const err = new Error(`Translation for '${id}' doesn't exist`);
    throw err;
  }

  // Fallback to a string 'UNDEFINED' if WS translation data doesn't have the requested ID.
  // WS translations are not consistent between languages so some IDs are missing.
  // Fallback is in CAPS so that its absence would be easier to notice.
  // eslint-disable-next-line no-prototype-builtins
  if (translation.hasOwnProperty("id")) {
    const state = store.getState().translation;
    // Issue #27573
    let suffix = "";
    // eslint-disable-next-line no-prototype-builtins
    if (translation.hasOwnProperty("suffix")) {
      suffix = `${translation.suffix}`;
    }
    switch (TRANS_CODE_DEBUG) {
      case "TRANSLATION_AND_CODE":
        return (
          `${state.translations[state.current][translation.id]} (${translation.id})` || "UNDEFINED"
        );
      case "ONLY_CODE":
        return `${translation.id}`;
      default:
        return `${state.translations[state.current][translation.id]}` + suffix || "UNDEFINED";
    }
  } else {
    switch (TRANS_CODE_DEBUG) {
      case "TRANSLATION_AND_CODE":
        return `${translation.fallback} (FALLBACK)` || "UNDEFINED";
      case "ONLY_CODE":
        return "FALLBACK";
      default:
        return translation.fallback || "UNDEFINED";
    }
  }
};

// Retrieve translation value for the given textno
// Build 3.6.3.4 (Issue #31348)...
const trNo = (textNo) => {
  const state = store.getState().translation;
  return `${state.translations[state.current][textNo]}` || "UNDEFINED";
};
// ...Build 3.6.3.4 (Issue #31348)

export {
  initAvailableTranslations,
  initTranslation,
  initUserTranslation,
  browserLanguage,
  translationReady,
  switchTranslation,
  tr,
  switchTextMode, // #27619
  trNo,
};
