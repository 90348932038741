import * as actions from "./actionTypes.js";

const notify = (message, type) => {
  return { type: actions.NEW_NOTIFICATION, message: message, messageType: type };
};

const dismiss = (index) => {
  return { type: actions.DISMISS_NOTIFICATION, index: index };
};

export { notify, dismiss };
