/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import ReactDatePicker from "react-datepicker";
import {
  Col,
  FormGroup,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import moment from "moment";

import { store } from "../../store.js";
import { tr } from "../../utils/translation.js";
// import TextInput, { OptimizedTextInput } from './TextInput.jsx';
// import NumberInput, { OptimizedNumberInput } from './NumberInput.jsx';
import TextInput from "./TextInput.jsx";
import NumberInput from "./NumberInput.jsx";
import TimeInput from "./TimeInput.jsx";

import { convertAbbreviationToDate, formatDate } from "../../utils/locale.js";

// Find suitable renderer. If not found, uses text input as default.
const getRenderer = (props) => {
  return renderers[props.type] ? renderers[props.type](props) : renderers.text(props);
};

// List of different renderers for getRenderer to choose from.
const renderers = {
  dropdown: (props) => <Dropdown {...props} />,
  datepicker: (props) => <DatePicker {...props} />,
  text: (props) => <TextInput {...props} />,
  // optimizedText: (props) => <OptimizedTextInput { ...props } />,
  number: (props) => <NumberInput {...props} />,
  // optimizedNumber: (props) => <OptimizedNumberInput { ...props } />,
  time: (props) => <TimeInput {...props} />,
};

/*
 * WRAPPER
 *
 * Use FormInput wrapper for regular form inputs. Uses getRenderer to find suitable input.
 * Usage example:
 *
 *       <Field
 *        name='password'         // redux-form field name
 *        component={FormInput}
 *        type='password'         // types: datepicker, dropdown, text(default)
 *        placeholder='password'  // optional
 *        label='password'        // optional
 *        validate={[ required ]}
 *       />
 */
export const FormInput = (origProps) => {
  const meta = origProps.meta;
  // Remove FormInput specific props
  const { labelSize = 5, ...props } = origProps;

  // Attach warning or error message + appropriate style if either exists
  let style = "";
  let message = "";
  if (meta.warning) {
    style = "warning";
    message = meta.warning;
  }

  // Consider error more important, thus overwrite potential warning with error message & style
  if (meta.error) {
    style = "error";
    message = meta.error;
  }

  return (
    <FormGroup row className={meta.touched ? style : ""}>
      {props.label && (
        <Label sm={labelSize}>
          {props.required && "*"}
          {props.label}
        </Label>
      )}
      <Col sm={props.label ? 12 - labelSize : 12}>
        {getRenderer(props)}
        {meta.touched && <span>{message}</span>}
      </Col>
    </FormGroup>
  );
};

// Input with minimal amount of margin and padding.
export const CompactFormInput = (props) => {
  const { className = "", ...rest } = props;
  return getRenderer({ ...rest, className: `compact-input ${className}` });
};

/**
 * Usage similar to FormInput, however this component should be used when
 * input fields are inline. This way Internet Explorer also renders fields correctly.
 */
export const FormInputInline = (props) => {
  const meta = props.meta;

  return (
    <FormGroup className={meta.touched && meta.error && !props.disabled ? "error" : ""}>
      <div>
        {getRenderer(props)}
        {meta.touched && meta.error && !props.disabled && <span>{meta.error}</span>}
      </div>
    </FormGroup>
  );
};

/* EDITORS: Dropdown, DatePicker */

class Dropdown extends React.Component {
  _renderItems(items) {
    return items.map((item, index) => {
      const onBlur = () => {
        this.props.input.onBlur(item);
      };

      if (typeof item === "object") {
        return (
          <DropdownItem key={index} value={item.name} onClick={onBlur} disabled={item.disabled}>
            {item.name}
          </DropdownItem>
        );
      } else {
        return (
          <DropdownItem key={index} value={item} onClick={onBlur}>
            {item}
          </DropdownItem>
        );
      }
    });
  }

  render() {
    const {
      meta,
      input: { value },
      list = [],
    } = this.props;
    const error = meta.touched && meta.error && !this.props.disabled;

    // In case value is object, get name property which will be displayed in the
    // DropdownToggle. This way if empty array is given to Dropdown which has object
    // value structure, React won't go to infinite loop
    let renderedValue = value;
    if (typeof value === "object") {
      renderedValue = value.name;
    }

    return (
      <UncontrolledDropdown className="default-dropdown">
        <DropdownToggle
          color="default"
          disabled={this.props.disabled}
          className={error ? "error" : ""}
          caret
        >
          {renderedValue || this.props.placeholder || ""}
        </DropdownToggle>
        <DropdownMenu>{this._renderItems(list)}</DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

class DatePicker extends React.Component {
  _handleChange(date) {
    this.props.input.onChange(date);
  }

  _handleChangeRaw(event) {
    this.setState({ value: event.target.value || "" });
  }

  /**
   * When focus is lost from input field, try to convert value to actual date.
   */
  _handleBlur(event) {
    // Try to convert event value to date
    // Only do this if there is an event
    if (event) {
      const date = convertAbbreviationToDate(event.target.value);
      if (moment.isMoment(date) || date === null) {
        // Update value in redux-form
        this.props.input.onChange(date);
        // Update value that is displayed in the input box. Display nothing if invalid (has to be done after timeout)
        setTimeout(() => {
          this.setState({ value: date ? formatDate(date) : null });
        });
      }
    }
  }

  constructor(props) {
    super(props);

    this.defaultState = {
      value: null,
    };

    this.state = { ...this.defaultState };
    this._handleChange = this._handleChange.bind(this);
    this._handleChangeRaw = this._handleChangeRaw.bind(this);
    this._handleBlur = this._handleBlur.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.input.value !== prevProps.input.value) {
      this.setState({ value: null });
    }
  }

  render() {
    const {
      input: { value },
      ...rest
    } = this.props;
    let calendar;

    const currentLocale = store.getState().locale.current;

    return (
      <div className="datepicker-container">
        <ReactDatePicker
          todayButton={tr("today")}
          fixedHeight
          locale={currentLocale}
          className="form-control bs-table-datepicker"
          // Build 3.6.4.1 (Issue #32668) old: selected={value ? moment(value) : null}
          // eslint-disable-next-line eqeqeq
          selected={value ? (moment(value).year() == 1800 ? null : moment(value)) : null}
          ref={(c) => (calendar = c)}
          onChange={this._handleChange}
          onChangeRaw={this._handleChangeRaw}
          onBlur={this._handleBlur}
          value={this.state.value}
          {...rest}
        />
        <i
          onClick={() => calendar.setOpen(true)}
          className="icon ion-md-calendar datepicker-calendar-icon"
        />
      </div>
    );
  }
}
