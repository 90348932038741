import * as actions from "./actionTypes.js";

const setLocale = (locale) => {
  return { type: actions.SET_LOCALE, current: locale };
};

const setLocales = (locales) => {
  return { type: actions.SET_AVAILABLE_LOCALES, locales };
};

export { setLocale, setLocales };
