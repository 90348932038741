/* eslint-disable array-callback-return */
import { push as routerPush } from "connected-react-router";
import { SubmissionError } from "redux-form";
import moment from "moment";

import { store } from "../store.js";
import * as actions from "./actionTypes.js";
import { notify } from "./notification.js";
import { Fetcher, fetchWithRetry } from "../utils/fetcher";
import apiUrl from "../utils/url.js";
import { generateKey } from "../utils/random.js";
import { formatDate, momentToDateString, parseString } from "../utils/locale.js";
import { formatCurrency, sortObjects } from "../utils/validate.js";
import { tr } from "../utils/translation.js";

import {
  tempAddressStateFormatter,
  formatItemQty,
  capitalize,
} from "../components/common/formatters.js";

export const customerProcessing = (payload = false) => {
  return { type: actions.CUSTOMER_PROCESSING, payload };
};

export const clearCustomer = () => {
  return { type: actions.CLEAR_CUSTOMER };
};

/**
 * Search components
 */
export const setIsSearchingCustomer = (payload = false) => {
  return { type: actions.SET_IS_SEARCHING_CUSTOMER, payload };
};

export const toggleAdvancedSearchModal = (payload = false) => {
  return { type: actions.TOGGLE_CUSTOMER_ADVANCED_SEARCH_MODAL, payload };
};

export const setSearchExpanded = (payload = false) => {
  return { type: actions.SET_SEARCH_EXPANDED, payload };
};

export const setCustomerSearchResult = (payload = []) => {
  return { type: actions.SET_CUSTOMER_SEARCH_RESULT, payload };
};

export const selectCustomerSearchResult = (payload = "") => {
  return { type: actions.SELECT_SEARCH_RESULT, payload };
};

export const toggleAddCustomerModal = () => {
  return { type: actions.TOGGLE_ADD_CUSTOMER_MODAL };
};

// --side effects

export const searchCustomers = (values = {}, withUrlParams = false) => (dispatch) => {
  // Build 3.6.4.1 (Issue #33108)
  const searchParams = values.subscriptionType
    ? { ...values, subscriptionType: values.subscriptionType.id }
    : values;

  dispatch(setIsSearchingCustomer(true));
  dispatch(clearCustomer());

  // Yabing: 2021-11-02, no issue, bug fix
  // !withUrlParams && dispatch(routerPush("/customer/"));
  store.dispatch(setCustomerSearchResult([]));
  dispatch(routerPush("/customer/"));

  const url = apiUrl("customers/search");

  // Build 3.6.4.1 (Issue #33108): use searchParams
  return Fetcher.postJson(url, searchParams)
    .then((data) => {
      const customers = data.map((item) => {
        const customer = {};
        // delete null values and object values
        for (const [key, value] of Object.entries(item)) {
          if (!(value === null || typeof value === "object")) {
            customer[key] = value;
          }
        }
        // Search result doesn't necessarily have unique data in it so it's
        // added here manually with generateKey() function.
        return { ...customer, rowId: generateKey() };
      });

      dispatch(toggleAdvancedSearchModal(false));
      dispatch(selectCustomerSearchResult());
      dispatch(setCustomerSearchResult(customers));
      dispatch(customerProcessing(false));

      // #30612
      if (!customers.length) {
        // dispatch(setIsSearchingCustomer(false));
      }

      // #27313 if only one customer found, select customer automatically
      let current = null;
      if (customers.length === 1) {
        current = customers[0].cusno;
      } else if (customers.length > 1) {
        // #31347, open customer directly if multiple rows with same cusno
        const sameCusno = customers.every((customer) => customer.cusno === customers[0].cusno);
        if (sameCusno) {
          current = customers[0].cusno;
        }
      }

      if (current === null) {
        dispatch(setSearchExpanded(true));
      } else {
        dispatch(setIsSearchingCustomer(false));
        // clear url to root first to make it work in Customer componentDidUpdate
        dispatch(routerPush("/customer/"));
        dispatch(routerPush(`/customer/${current}`));
      }
    })
    .catch((error) => {
      dispatch(toggleAdvancedSearchModal(false));
      dispatch(notify(tr("customerReadFailed"), "error"));

      throw new SubmissionError(error);
    });
};

// ***

export const setSubscriptionsAndProducts = (combined) => {
  return { type: actions.SET_SUBSCRIPTIONS_AND_PRODUCTS, combined };
};

export const selectSubscriptionAndProductsRow = (id = "") => {
  return { type: actions.SELECT_SUBSCRIPTIONS_AND_PRODUCTS_ROW, id };
};

export const addRecentCustomer = (cusno) => (dispatch) => {
  const url = apiUrl(`customers/${cusno}/add-recent`);

  return Fetcher.postJson(url).then((customers) => {
    dispatch(updateRecentCustomers(customers));
  });
};

export const getRecentCustomers = () => (dispatch) => {
  const url = apiUrl("customers/recent");

  return Fetcher.getJson(url).then((customers) => {
    dispatch(updateRecentCustomers(customers));
  });
};

export const updateRecentCustomers = (customers) => {
  return { type: actions.UPDATE_RECENT_CUSTOMERS, customers: customers };
};

export const getCustomer = (cusno) => (dispatch) => {
  const url = apiUrl(`customers/${cusno}`);

  return Fetcher.getJson(url)
    .then((customer) => {
      dispatch(getCustomerInvoiceModes(customer.cusno));
      dispatch(loadCustomer(customer));
    })
    .catch(() => {
      dispatch(clearCustomer());
    });
};

export const loadCustomer = (customer) => (dispatch) => {
  // Build 3.6.4.1 (Issue #32668)...
  // return { type: actions.LOAD_CUSTOMER, customer: customer };
  dispatch(setSelectedCustomer(customer));
  if (customer && customer.wwwpincode) {
    dispatch(getOriginalWWWPinCode(customer.wwwpincode));
  }
  // ...Build 3.6.4.1 (Issue #32668)
};

// Build 3.6.4.1 (Issue #32668)
export const setSelectedCustomer = (customer) => {
  return { type: actions.LOAD_CUSTOMER, customer: customer };
};

// Build 3.6.4.1 (Issue #32668)
export const getOriginalWWWPinCode = (wwwPinCode) => (dispatch) => {
  if (wwwPinCode && wwwPinCode !== null && wwwPinCode !== "") {
    const url = apiUrl("customers/decrypt");
    const requestBody = {
      source: wwwPinCode,
    };
    Fetcher.postJson(url, requestBody)
      .then((response) => {
        dispatch(setOriginalWWWPinCode(response.target));
      })
      .catch(() => {
        dispatch(setOriginalWWWPinCode(""));
      });
  } else {
    dispatch(setOriginalWWWPinCode(""));
  }
};

// Build 3.6.4.1 (Issue #32668)
export const setOriginalWWWPinCode = (wwwPinCode) => {
  return { type: actions.LOAD_ORIGINAL_PINCODE, wwwpincode: wwwPinCode };
};

export const toggleUpdateCustomerModal = () => {
  return { type: actions.TOGGLE_UPDATE_CUSTOMER_MODAL };
};

export const shouldClearCustomer = (flag) => {
  return { type: actions.SHOULD_CLEAR_CUSTOMER, flag };
};

export const clearSubscriptions = () => {
  return { type: actions.CLEAR_SUBSCRIPTIONS };
};

export const loadSubscriptions = (subscriptions) => {
  return { type: actions.LOAD_SUBSCRIPTIONS, subscriptions };
};

export const loadRawSubscriptions = (subscriptions) => {
  return { type: actions.LOAD_RAW_SUBSCRIPTIONS, subscriptions };
};

export const selectSubscription = (subscription) => (dispatch) => {
  // Build 3.6.4.1 (Issue #33098)...
  // Build 3.6.4.2 (ERP #11589): bug fix
  if (subscription && subscription.campno && subscription.campno > 0) {
    dispatch(getSelectedSubsCampaignDetails(subscription.campno));
  } else {
    dispatch(setSelectedSubsCampaignDetails({}));
  }
  dispatch(setSelectSubscription(subscription));
  // return { type: actions.SELECT_SUBSCRIPTION, subscription };
  // ...Build 3.6.4.1 (Issue #33098)
};

// Build 3.6.4.1 (Issue #33098)
export const getSelectedSubsCampaignDetails = (campno) => (dispatch) => {
  if (!campno || campno === "undefined" || campno <= 0) {
    dispatch(setSelectedSubsCampaignDetails({}));
    return;
  }
  const url = apiUrl(`campaigns/${campno}`);

  return Fetcher.getJson(url)
    .then((campaignDetails) => {
      dispatch(setSelectedSubsCampaignDetails(campaignDetails));
    })
    .catch(() => {
      dispatch(setSelectedSubsCampaignDetails({}));
    });
};

// Build 3.6.4.1 (Issue #33098)
export const setSelectedSubsCampaignDetails = (campaignDetails) => {
  return {
    type: actions.SET_SELECTEDSUBS_CAMPAIGN_DETAILS,
    campaignDetails: campaignDetails,
  };
};

// Build 3.6.4.1 (Issue #33098)
export const setSelectSubscription = (subscription) => {
  return { type: actions.SELECT_SUBSCRIPTION, subscription };
};

// #30344, latest created subs should be selected after closing newsubscription modal
export const nextSelectSubscription = (subscription) => {
  return { type: actions.NEXT_SELECT_SUBSCRIPTION, subscription };
};

export const loadCustomerExtraProducts = (extraProducts) => {
  return { type: actions.LOAD_CUSTOMER_EXTRA_PRODUCTS, extraProducts };
};

export const selectCustomerExtraProduct = (extraProduct = []) => {
  return { type: actions.SELECT_CUSTOMER_EXTRA_PRODUCT, extraProduct };
};

export const selectCustomerExtraProductInstalment = (instalment = {}) => {
  return { type: actions.SELECT_CUSTOMER_EXTRA_PRODUCT_INSTALMENT, instalment };
};

export const clearCustomerExtraProducts = () => {
  return { type: actions.CLEAR_CUSTOMER_EXTRA_PRODUCTS };
};

export const getCurrentBasicAddress = () => (dispatch) => {
  const cusno = store.getState().customer.selectedCustomer.cusno;
  const url = apiUrl(`customers/${cusno}/address`);

  return Fetcher.getJson(url)
    .then((address) => {
      dispatch(loadCurrentBasicAddress(address));
    })
    .catch(() => {
      dispatch(loadCurrentBasicAddress({}));
    });
};

export const loadCurrentBasicAddress = (address) => {
  return { type: actions.LOAD_CURRENT_BASIC_ADDRESS, address };
};

export const getPendingAddressChange = (cusno, currentAddress) => (dispatch) => {
  // Build 3.6.4.2 (ERP #11199): add currentAddress to select current address after reload
  const url = apiUrl(`customers/${cusno}/pending-address-changes`);

  return Fetcher.getJson(url).then((addresses) => {
    if (addresses.length > 0) {
      // Build 3.6.4.2 (ERP #11199)...
      // // Depending if pending address change is relevat -> load it to state
      // const today = moment();
      // const startdate = moment(addresses[0].startdate);
      // const pendingAddress = addresses[0];

      // // Check that startdate is not too old (using day as accuracy when comparing)
      // if (moment(startdate).isAfter(today, "day")) {
      //   // Customer has pending address in future
      //   dispatch(loadPendingAddressChange(pendingAddress));
      // } else {
      //   // Customer's pending address is too old
      //   dispatch(loadPendingAddressChange(undefined));
      // }
      const formattedAddresses = [];
      let matchAddress;
      addresses.forEach((address) => {
        const today = parseString(momentToDateString(moment()));
        if (parseString(momentToDateString(parseString(address.startdate))).isAfter(today, "day")) {
          const startDate = formatDate(parseString(address.startdate));
          const endDate = formatDate(parseString(address.enddate));
          const addressState = tempAddressStateFormatter(address.addrstate);
          const street1 = address.street1 || "";
          const street2 = address.street2 ? address.street2 + ", " : "";
          const tempname1 = address.tempname1 ? address.tempname1 + ", " : "";
          const tempname2 = address.tempname2 ? address.tempname2 + ", " : "";
          const zipcode = address.zipcode || "";
          const postname = address.postname || "";

          formattedAddresses.push({
            columnId: generateKey(),
            columnValid: `${startDate} - ${endDate} (${addressState})`,
            columnAddress: `${street1}, ${street2}${tempname1}${tempname2}${zipcode} ${postname}`,
            ...address,
          });

          if (
            currentAddress &&
            momentToDateString(currentAddress.startdate) ===
              momentToDateString(parseString(address.startdate))
          ) {
            matchAddress = formattedAddresses[formattedAddresses.length - 1];
          }
        }
      });
      const sortedAddress = sortObjects(formattedAddresses, "startdate", false, false);

      dispatch(
        loadPendingAddressesChange(
          sortedAddress,
          matchAddress && matchAddress !== null
            ? matchAddress
            : formattedAddresses.length > 0
            ? formattedAddresses[0]
            : undefined
        )
      );
      // ...Build 3.6.4.2 (ERP #11199)
    } else {
      // Build 3.6.4.2 (ERP #11199)...
      // No pending address changes
      // dispatch(loadPendingAddressChange(undefined));
      dispatch(loadPendingAddressesChange([], undefined));
      // ...Build 3.6.4.2 (ERP #11199)
    }
  });
};

export const loadPendingAddressChange = (address) => {
  return { type: actions.LOAD_PENDING_ADDRESS_CHANGE, pendingAddress: address };
};

// Build 3.6.4.2 (ERP #11199)
export const loadPendingAddressesChange = (addresses, currentAddress) => {
  return {
    type: actions.LOAD_PENDING_ADDRESSES_CHANGE,
    pendingAddresses: addresses,
    pendingAddress: currentAddress,
  };
};

export const getSubsTempAddresses = () => (dispatch) => {
  const cusno = store.getState().customer.selectedCustomer.cusno;
  const subsno = store.getState().customer.subscription.selectedSubscription.order_subsno;
  const url = apiUrl(`customers/${cusno}/subscriptions/${subsno}/temp-addresses`);

  Fetcher.getJson(url).then((addresses) => {
    dispatch(loadSubsTempAddresses(addresses));
  });
};

export const loadSubsTempAddresses = (addresses) => {
  // For available addresses, construct array that will be used to display addresses in a table
  const formattedAddresses = [];
  if (addresses.length > 0) {
    addresses.forEach((address) => {
      if (address.changetype === "Temporary" || address.changetype === "TemporaryCurrent") {
        const startDate = formatDate(parseString(address.startdate));
        const endDate = formatDate(parseString(address.enddate));
        const addressState = tempAddressStateFormatter(address.addrstate);
        const street1 = address.street1 || "";
        const street2 = address.street2 ? address.street2 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
        const tempname1 = address.tempname1 ? address.tempname1 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
        const tempname2 = address.tempname2 ? address.tempname2 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
        const zipcode = address.zipcode || "";
        const postname = address.postname || "";

        // Build 3.6.4.2 (ERP #11199): add street2, tempname1, tempname2 if exists
        formattedAddresses.push({
          columnId: generateKey(),
          columnValid: `${startDate} - ${endDate} (${addressState})`,
          columnAddress: `${street1}, ${street2}${tempname1}${tempname2}${zipcode} ${postname}`,
          ...address,
        });
      }
    });
  }

  return { type: actions.LOAD_SUBS_TEMP_ADDRESSES, subsTempAddresses: formattedAddresses };
};

export const getAllTempAddresses = () => (dispatch) => {
  const cusno = store.getState().customer.selectedCustomer.cusno;
  const url = apiUrl(`customers/${cusno}/temp-addresses`);

  Fetcher.getJson(url).then((addresses) => {
    const availableAddresses = [];
    addresses.forEach((address) => {
      const street1 = address.street1 || "";
      const street2 = address.street2 ? address.street2 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
      const tempname1 = address.tempname1 ? address.tempname1 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
      const tempname2 = address.tempname2 ? address.tempname2 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
      const zipcode = address.zipcode || "";
      const postname = address.postname || "";
      // Build 3.6.4.2 (ERP #11199): add street2, tempname1, tempname2 if exists
      availableAddresses.push({
        id: address.addrno,
        name: `${street1}, ${street2}${tempname1}${tempname2}${zipcode} ${postname}`,
        ...address,
      });
    });

    dispatch(loadAllTempAddresses(availableAddresses));
  });
};

export const loadAllTempAddresses = (addresses) => {
  return { type: actions.LOAD_ALL_TEMP_ADDRESSES, allTempAddresses: addresses };
};

export const resetHolidayAddress = (flag) => {
  return { type: actions.RESET_HOLIDAY_ADDRESS, flag: flag };
};

export const getSubsSplitAddresses = () => (dispatch) => {
  const cusno = store.getState().customer.selectedCustomer.cusno;
  const subsno = store.getState().customer.subscription.selectedSubscription.order_subsno;
  const url = apiUrl(`customers/${cusno}/subscriptions/${subsno}/split-addresses`);

  return Fetcher.getJson(url).then((addresses) => {
    // Construct split address combos from all addresses. This way it is easy to
    // render & populate split address form later. Each combo consist of two addresses.
    const subsSplitAddresses = [];
    if (addresses.length > 0) {
      for (let i = 0; i < addresses.length; i++) {
        const startDate = formatDate(parseString(addresses[i].startdate));
        const endDate = formatDate(parseString(addresses[i].enddate));
        const addressState = tempAddressStateFormatter(addresses[i].addrstate);
        // const streetname = addresses[i].streetname || "";
        const zipcode = addresses[i].zipcode || "";
        const postname = addresses[i].postname || "";
        // const streetnameTwo = addresses[i + 1].streetname || "";
        const zipcodeTwo = addresses[i + 1].zipcode || "";
        const postnameTwo = addresses[i + 1].postname || "";
        const street1 = addresses[i].street1 || ""; // Build 3.6.4.2 (ERP #11199)
        const street2 = addresses[i].street2 ? addresses[i].street2 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
        const tempname1 = addresses[i].tempname1 ? addresses[i].tempname1 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
        const tempname2 = addresses[i].tempname2 ? addresses[i].tempname2 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
        const street1Two = addresses[i + 1].street1 || ""; // Build 3.6.4.2 (ERP #11199)
        const street2Two = addresses[i + 1].street2 ? addresses[i + 1].street2 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
        const tempname1Two = addresses[i + 1].tempname1 ? addresses[i + 1].tempname1 + ", " : ""; // Build 3.6.4.2 (ERP #11199)
        const tempname2Two = addresses[i + 1].tempname2 ? addresses[i + 1].tempname2 + ", " : ""; // Build 3.6.4.2 (ERP #11199)

        const addressCombo = {
          // Split address table columns
          columnId: generateKey(),
          columnValid: `${startDate} - ${endDate} (${addressState})`,
          // Build 3.6.4.2 (ERP #11199): use street1 instead of streetname, add street2, tempname1, tempname2
          // columnAddress: `${streetname}, ${zipcode} ${postname} / ${streetnameTwo}, ${zipcodeTwo} ${postnameTwo}`,
          columnAddress: `${street1}, ${street2}${tempname1}${tempname2}${zipcode} ${postname} / ${street1Two}, ${street2Two}${tempname1Two}${tempname2Two}${zipcodeTwo} ${postnameTwo}`,
          // Split address 1
          startdate: addresses[i].startdate,
          enddate: addresses[i].enddate,
          addrstate: addresses[i].addrstate,
          streetname: addresses[i].streetname,
          houseno: addresses[i].houseno,
          staircase: addresses[i].staircase,
          apartment: addresses[i].apartment,
          street2: addresses[i].street2,
          street3: addresses[i].street3,
          countrycode: addresses[i].countrycode,
          zipcode: addresses[i].zipcode,
          postname: addresses[i].postname,
          addrno: addresses[i].addrno,
          subsno: addresses[i].subsno,
          monday: addresses[i].days.substring(0, 1),
          tuesday: addresses[i].days.substring(1, 2),
          wednesday: addresses[i].days.substring(2, 3),
          thursday: addresses[i].days.substring(3, 4),
          friday: addresses[i].days.substring(4, 5),
          saturday: addresses[i].days.substring(5, 6),
          sunday: addresses[i].days.substring(6, 7),
          tempname1: addresses[i].tempname1, // Build 3.6.4.2 (ERP #11199)
          tempname2: addresses[i].tempname2, // Build 3.6.4.2 (ERP #11199)
          // Split address 2
          startdateTwo: addresses[i + 1].startdate,
          enddateTwo: addresses[i + 1].enddate,
          addrstateTwo: addresses[i + 1].addrstate,
          streetnameTwo: addresses[i + 1].streetname,
          housenoTwo: addresses[i + 1].houseno,
          staircaseTwo: addresses[i + 1].staircase,
          apartmentTwo: addresses[i + 1].apartment,
          street2Two: addresses[i + 1].street2,
          street3Two: addresses[i + 1].street3,
          countrycodeTwo: addresses[i + 1].countrycode,
          zipcodeTwo: addresses[i + 1].zipcode,
          postnameTwo: addresses[i + 1].postname,
          addrnoTwo: addresses[i + 1].addrno,
          subsnoTwo: addresses[i + 1].subsno,
          mondayTwo: addresses[i + 1].days.substring(0, 1),
          tuesdayTwo: addresses[i + 1].days.substring(1, 2),
          wednesdayTwo: addresses[i + 1].days.substring(2, 3),
          thursdayTwo: addresses[i + 1].days.substring(3, 4),
          fridayTwo: addresses[i + 1].days.substring(4, 5),
          saturdayTwo: addresses[i + 1].days.substring(5, 6),
          sundayTwo: addresses[i + 1].days.substring(6, 7),
          tempname1Two: addresses[i + 1].tempname1, // Build 3.6.4.2 (ERP #11199)
          tempname2Two: addresses[i + 1].tempname2, // Build 3.6.4.2 (ERP #11199)
        };

        // Add address combo to subsSplitAddresses array
        subsSplitAddresses.push(addressCombo);

        // Finally increment i so next loop will skip already added address
        i++;
      }
    }

    dispatch(loadSubsSplitAddresses(subsSplitAddresses));
  });
};

export const loadSubsSplitAddresses = (addresses) => {
  return { type: actions.LOAD_SUBS_SPLIT_ADDRESSES, subsSplitAddresses: addresses };
};

export const resetSplitAddress = (flag) => {
  return { type: actions.RESET_SPLIT_ADDRESS, flag: flag };
};

export const getCurrentInvoiceAddress = () => (dispatch) => {
  const cusno = store.getState().customer.selectedCustomer.cusno;
  const subsno = store.getState().customer.subscription.selectedSubscription.order_subsno;
  const extno = store.getState().customer.subscription.selectedSubscription.order_extno;
  const url = apiUrl(
    `customers/${cusno}/subscriptions/${subsno}/${extno}/current-invoicing-address`
  );

  return Fetcher.getJson(url)
    .then((address) => {
      dispatch(loadCurrentInvoiceAddress(address));
    })
    .catch(() => {
      dispatch(loadCurrentInvoiceAddress({}));
    });
};

export const loadCurrentInvoiceAddress = (address) => {
  return { type: actions.LOAD_CURRENT_INVOICE_ADDRESS, currentInvoiceAddress: address };
};

export const getPendingInvoiceAddress = () => (dispatch) => {
  const cusno = store.getState().customer.selectedCustomer.cusno;
  const subsno = store.getState().customer.subscription.selectedSubscription.order_subsno;
  const url = apiUrl(`customers/${cusno}/subscriptions/${subsno}/waiting-invoicing-address`);

  return Fetcher.getJson(url)
    .then((address) => {
      dispatch(loadPendingInvoiceAddress(address));
    })
    .catch(() => {
      loadPendingInvoiceAddress({});
    });
};

export const loadPendingInvoiceAddress = (address) => {
  return { type: actions.LOAD_PENDING_INVOICE_ADDRESS, pendingInvoiceAddress: address };
};

export const getPapers = () => (dispatch) => {
  const currentPapers = store.getState().customer.subscription.allMainPapers;

  if (currentPapers.length === 0) {
    const url = apiUrl("subscriptions/papers");

    fetchWithRetry("getJson", [url]).then((papers) => {
      dispatch(loadPapers(papers));
    });
  }
};

export const loadPapers = (papers) => {
  // From received papers, construct list of papers where one papercode can be found only once
  const papercodes = {};
  let allMainPapers = papers.filter((paper) => {
    if (papercodes[paper.papercode]) {
      return false;
    } else {
      papercodes[paper.papercode] = true;
      return true;
    }
  });

  // Build 3.6.4.2 (Issue #33221)
  allMainPapers = sortObjects(allMainPapers, "papercode", false, false);

  // Build 3.6.4.2 (Issue #12471)
  let mainPaper = "";
  const mainPapers = [];

  const allPapers = [];
  papers.forEach((paper) => {
    // Build 3.6.4.2 (Issue #12471)
    if (paper.mainpaper === "Y" && !mainPapers.includes(paper.papercode)) {
      mainPaper = paper.papercode;
      mainPapers.push(paper.papercode);
      store.dispatch(fetchPostmachRowLength(mainPaper));
    }

    allPapers.push({
      ...paper,
      id: paper.packageid,
      name: paper.packageid + " - " + paper.packagename,
    });
  });

  const namedAllMainPapers = [];
  allMainPapers.forEach((paper) => {
    namedAllMainPapers.push({
      ...paper,
      id: paper.papercode,
      name: paper.papercode + " - " + paper.papername,
    });
  });

  const allMainPapersWithPaperAll = [
    {
      id: "ALL",
      name: tr("all"),
      papercode: "ALL",
    },
    ...namedAllMainPapers,
  ];

  return {
    type: actions.LOAD_PAPERS,
    mainPaper: mainPaper, // Build 3.6.4.2 (Issue #12471)
    allPapers: allPapers,
    allMainPapers: namedAllMainPapers,
    allMainPapersWithPaperAll: allMainPapersWithPaperAll,
  };
};

// Retrieve extra products which can be added to a subscription (or ordered separately)
export const getAvailableExtraProducts = (papercode) => (dispatch) => {
  let url;
  if (papercode !== undefined) {
    url = apiUrl(`subscriptions/extra-products/${papercode}`);
  } else {
    url = apiUrl("subscriptions/extra-products");
  }

  return Fetcher.getJson(url)
    .then((extraProducts) => {
      dispatch(loadAvailableExtraProducts(extraProducts));
    })
    .catch(() => {
      dispatch(loadAvailableExtraProducts([]));
    });
};

// Load available extra products
export const loadAvailableExtraProducts = (extraProducts) => {
  const namedExtraProducts = [];
  extraProducts.forEach((product) => {
    namedExtraProducts.push({
      ...product,
      id: product.productcode,
      name: product.productcode + " - " + product.productname,
    });
  });

  return {
    type: actions.LOAD_AVAILABLE_EXTRA_PRODUCTS,
    extraProducts: namedExtraProducts,
  };
};

export const clearSubscriptionExtraProducts = () => {
  // From available subscription products, select only product that is paper and save that array
  const subscriptionProducts = store.getState().customer.subscription.subscriptionProducts;
  // eslint-disable-next-line array-callback-return
  const clearedSubscriptionProducts = subscriptionProducts.filter((product) => {
    if (product.isPaper) {
      return product;
    }
  });

  return {
    type: actions.CLEAR_SUBSCRIPTION_EXTRA_PRODUCTS,
    subscriptionProducts: clearedSubscriptionProducts,
  };
};

export const setSelectedPaper = (paper) => {
  return { type: actions.SET_SELECTED_PAPER, selectedPaper: paper };
};

export const setProductPackage = (productPackage) => {
  return { type: actions.SET_PRODUCTPACKAGE, productPackage: productPackage };
};

export const setSelectedExtraProduct = (extraProduct) => {
  return {
    type: actions.SET_SELECTED_EXTRA_PRODUCT,
    extraProduct: extraProduct,
  };
};

export const resetSelectedPaper = () => {
  return { type: actions.RESET_SELECTED_PAPER };
};

export const resetSelectedExtraProduct = () => {
  return { type: actions.RESET_SELECTED_EXTRA_PRODUCT };
};

export const getCampaigns = (cusno, papercode, packageId) => (dispatch) => {
  const requestBody = {
    cusno: cusno,
    paper_code: papercode || "",
    package_id: packageId || "",
  };
  const url = apiUrl("campaigns");

  return Fetcher.postJson(url, requestBody)
    .then((campaigns) => {
      dispatch(loadCampaigns(campaigns));
    })
    .catch(() => {
      dispatch(loadCampaigns([]));
    });
};

export const getCampaignsByCampId = (campId) => (dispatch) => {
  const body = { camp_id: campId };
  const url = apiUrl("campaigns-by-camp-or-group-id");

  return Fetcher.postJson(url, body)
    .then((campaigns) => {
      dispatch(loadApiFilteredCampaigns(campaigns));
    })
    .catch(() => {
      dispatch(resetFilteredCampaigns());
    });
};

export const loadApiFilteredCampaigns = (campaigns) => {
  return {
    type: actions.LOAD_API_FILTERED_CAMPAIGNS,
    filteredCampaigns: campaigns,
  };
};

export const loadCampaigns = (campaigns) => {
  return {
    type: actions.LOAD_CAMPAIGNS,
    campaigns: campaigns,
    filteredCampaigns: campaigns,
  };
};

export const loadFilteredCampaigns = (query) => {
  let filteredCampaigns = [];
  if (query !== "") {
    // Put all campaigns to same temp array before filtering
    const campaigns = store.getState().customer.subscription.campaigns;
    const temp = [];

    campaigns.forEach((group) => {
      group.forEach((campaign) => {
        temp.push(campaign);
      });
    });

    // Filter temp using query
    let filteredTemp = [];
    filteredTemp = temp.filter(
      (campaign) => campaign.campid.toLowerCase().indexOf(query.toLowerCase()) > -1
    );

    // Finally separate campaigns to own arrays based on campgroup
    let innerGroup = [];
    const noGroup = [];
    const flags = [];

    for (let i = 0; i < filteredTemp.length; i++) {
      if (filteredTemp[i].campgroupid && flags.indexOf(filteredTemp[i].campgroupid) === -1) {
        for (let j = 0; j < filteredTemp.length; j++) {
          if (filteredTemp[j].campgroupid === filteredTemp[i].campgroupid) {
            innerGroup.push(filteredTemp[j]);
          }
        }
        filteredCampaigns.push(innerGroup);
        innerGroup = []; // Reset innerGroup for future use
        flags.push(filteredTemp[i].campgroupid); // Add flag not to run this campgroupid again
      } else if (filteredTemp[i].campgroupid === undefined) {
        noGroup.push(filteredTemp[i]);
      }
    }

    // Finally add noGroup to filteredCampaigns if it is not empty
    if (noGroup.length > 0) {
      filteredCampaigns.push(noGroup);
    }
  } else {
    filteredCampaigns = store.getState().customer.subscription.campaigns;
  }

  return {
    type: actions.LOAD_FILTERED_CAMPAIGNS,
    filteredCampaigns: filteredCampaigns,
  };
};

export const resetFilteredCampaigns = () => {
  return { type: actions.RESET_FILTERED_CAMPAIGNS };
};

export const setSelectedCampaign = (campaign) => {
  return {
    type: actions.SET_SELECTED_CAMPAIGN,
    selectedCampaign: campaign,
  };
};

export const resetSelectedCampaign = () => {
  return { type: actions.RESET_SELECTED_CAMPAIGN };
};

export const getCampaignCoProducts = (campno, papercode) => (dispatch) => {
  const url = apiUrl(`campaigns/${campno}/co-products/${papercode}`);

  return Fetcher.getJson(url)
    .then((coProducts) => {
      dispatch(loadCampaignCoProducts(coProducts));
    })
    .catch(() => {
      dispatch(loadCampaignCoProducts([]));
    });
};

export const loadCampaignCoProducts = (coProducts) => {
  return { type: actions.LOAD_CAMPAIGN_CO_PRODUCTS, coProducts };
};

export const resetProductPackage = () => {
  return { type: actions.RESET_PRODUCT_PACKAGE };
};

/**
 * Provide either packageid string or product package object that should be stored to redux
 * @param {string|object} productPackage
 */
export const setSelectedProductPackage = (productPackage) => {
  let selectedProductPackage;
  if (productPackage instanceof Object) {
    selectedProductPackage = productPackage;
  } else {
    // From available products, find selected product package
    const allPapers = store.getState().customer.subscription.allPapers;

    // eslint-disable-next-line array-callback-return
    selectedProductPackage = allPapers.find((currentPackage) => {
      if (currentPackage.packageid === productPackage && currentPackage.mainproduct === "Y") {
        return productPackage;
      }
    });
  }

  return {
    type: actions.SET_SELECTED_PRODUCT_PACKAGE,
    selectedProductPackage: selectedProductPackage,
  };
};

export const resetSelectedProductPackage = () => {
  return { type: actions.RESET_SELECTED_PRODUCT_PACKAGE };
};

export const addProductToSubscription = (product) => {
  return {
    type: actions.ADD_PRODUCT_TO_SUBSCRIPTION,
    product: product,
  };
};

export const removeProductFromSubscription = (product) => {
  // From subscriptionsProducts, get every product except the one user wanted to remove
  const subscriptionProducts = store.getState().customer.subscription.subscriptionProducts;
  const updatedSubscriptionProducts = subscriptionProducts.filter((listProduct) => {
    return listProduct.basketId !== product.basketId;
  });

  return {
    type: actions.REMOVE_PRODUCT_FROM_SUBSCRIPTION,
    subscriptionProducts: updatedSubscriptionProducts,
  };
};

export const reloadSubscriptionProducts = (subscriptions) => {
  return {
    type: actions.RELOAD_SUBSCRIPTION_PRODUCTS,
    subscriptionProducts: subscriptions,
  };
};

export const resetSubscriptionProducts = () => {
  return { type: actions.RESET_SUBSCRIPTION_PRODUCTS };
};

export const getCommuneCode = (countrycode, zipcode) => (dispatch) => {
  if (countrycode && zipcode) {
    const url = apiUrl(`subscriptions/commune-code/${countrycode}/${zipcode}`);

    return Fetcher.getJson(url)
      .then((response) => {
        dispatch(setCommuneCode(response.commune_code));
      })
      .catch(() => {
        dispatch(setCommuneCode());
      });
  }
};

export const setCommuneCode = (communeCode) => {
  return {
    type: actions.SET_COMMUNECODE,
    communeCode: communeCode,
  };
};

export const setShouldReloadSubscriptions = (flag) => {
  return {
    type: actions.SET_SHOULD_RELOAD_SUBSCRIPTIONS,
    shouldReloadSubscriptions: flag,
  };
};

export const getCancelReasons = () => (dispatch) => {
  const url = apiUrl("subscriptions/cancel-reasons");

  return Fetcher.getJson(url).then((response) => {
    const reasons = {};

    response.forEach((item) => {
      const { codeno, ...rest } = item;
      reasons[codeno] = rest;
    });

    dispatch(setCancelReasons(reasons));
  });
};

export const setCancelReasons = (reasons) => {
  return { type: actions.SET_CANCEL_REASONS, reasons };
};

export const setSubmitResult = (successfulSubmits, unsuccessfulSubmits) => {
  return {
    type: actions.SET_SUBMIT_RESULT,
    successfulSubmits: successfulSubmits,
    unsuccessfulSubmits: unsuccessfulSubmits,
  };
};

export const editProductInSubscription = (product) => {
  return {
    type: actions.EDIT_PRODUCT_IN_SUBSCRIPTION,
    product: product,
    basketId: product.basketId,
  };
};

export const selectSubscriptionProduct = (product) => {
  return {
    type: actions.SELECT_SUBSCRIPTION_PRODUCT,
    product: product,
  };
};

export const getAvailableDiscounts = (papercode) => (dispatch) => {
  const url = apiUrl(`subscriptions/types/${papercode}`);

  return Fetcher.getJson(url)
    .then((discounts) => {
      const availableDiscounts = [];
      discounts.forEach((discount) => {
        availableDiscounts.push({
          ...discount,
          id: discount.substype,
          name: discount.substype + " - " + discount.substype_name,
        });
      });
      dispatch(setAvailableDiscounts(availableDiscounts));
    })
    .catch(() => {
      dispatch(resetAvailableDiscounts());
    });
};

export const setAvailableDiscounts = (discounts) => {
  return {
    type: actions.SET_AVAILABLE_DISCOUNTS,
    discounts: discounts,
  };
};

export const resetAvailableDiscounts = () => {
  return { type: actions.RESET_AVAILABLE_DISCOUNTS };
};

export const getAllAvailableDiscounts = () => (dispatch) => {
  const currentAllAvailableDiscounts = store.getState().customer.subscription.allAvailableDiscounts;

  if (Object.keys(currentAllAvailableDiscounts).length === 0) {
    const url = apiUrl("subscriptions/types");

    return Fetcher.getJson(url)
      .then((allDiscounts) => {
        const allAvailableDiscounts = {};
        for (const key in allDiscounts) {
          const allPaperDiscounts = {};
          allDiscounts[key].forEach((discount) => {
            allPaperDiscounts[discount.substype] = {
              ...discount,
              id: discount.substype,
              name: discount.substype + " - " + discount.substype_name,
            };
          });
          allAvailableDiscounts[key] = allPaperDiscounts;
        }
        dispatch(setAllAvailableDiscounts(allAvailableDiscounts));
      })
      .catch(() => {
        dispatch(setAllAvailableDiscounts({}));
      });
  }
};

export const setAllAvailableDiscounts = (discounts) => {
  return {
    type: actions.SET_ALL_AVAILABLE_DISCOUNTS,
    discounts: discounts,
  };
};

export const getPriceGroups = (papercode) => (dispatch) => {
  // Build 3.6.4.2 (Issue #32834):
  // const url = apiUrl(`subscriptions/price-groups/${papercode}`);

  // return Fetcher.getJson(url)
  //   .then((priceGroups) => {
  //     dispatch(setPriceGroups(priceGroups));
  //   })
  //   .catch(() => {
  //     dispatch(resetPriceGroups());
  //   });
  dispatch(fetchPriceGroups(papercode, ""));
  dispatch(fetchPriceGroups(papercode, "03"));
};

// Build 3.6.4.2 (Issue #32834)
export const fetchPriceGroups = (papercode, subskind) => (dispatch) => {
  const url = apiUrl(`subscriptions/price-groups/${papercode}`);
  const requestBody = {
    subskind: subskind,
  };

  return Fetcher.postJson(url, requestBody)
    .then((priceGroups) => {
      dispatch(setPriceGroups(priceGroups, subskind));
    })
    .catch(() => {
      dispatch(resetPriceGroups(subskind));
    });
};

// Build 3.6.4.2 (Issue #32834)
export const setPriceGroups = (priceGroups, subskind) => {
  const namedPriceGroups = [];
  priceGroups.forEach((priceGroup) => {
    namedPriceGroups.push({
      ...priceGroup,
      pricegroup: priceGroup.codeno,
      pricegroupname: priceGroup.codevalue,
      id: priceGroup.codeno,
      name: priceGroup.codeno + " - " + priceGroup.codevalue,
    });
  });

  return {
    type: subskind === "03" ? actions.SET_SINGLECOPY_PRICE_GROUPS : actions.SET_PRICE_GROUPS,
    priceGroups: namedPriceGroups,
  };
};

export const resetPriceGroups = (subskind) => {
  // Build 3.6.4.2 (Issue #32834): add subskind
  return {
    type: subskind === "03" ? actions.RESET_SINGLECOPY_PRICE_GROUPS : actions.RESET_PRICE_GROUPS,
  };
};

export const getAvailableMonthLengths = (papercode) => (dispatch) => {
  const url = apiUrl(`parameters/PRICELIST/${papercode}/ST_CYCLES`);

  Fetcher.getJson(url)
    .then((months) => {
      const monthsArray = months.codevalue.split(",");
      dispatch(setAvailableMonthLengths(monthsArray));
    })
    .catch(() => {
      dispatch(resetAvailableMonthLengths());
    });
};

export const setAvailableMonthLengths = (months) => {
  return {
    type: actions.SET_AVAILABLE_MONTH_LENGTHS,
    months: months,
  };
};

export const resetAvailableMonthLengths = () => {
  return { type: actions.RESET_AVAILABLE_MONTH_LENGTHS };
};

export const getCampaignDetails = (campno) => (dispatch) => {
  // #20110
  if (campno <= 0) {
    dispatch(setCampaignDetails({}));
    return;
  }

  const url = apiUrl(`campaigns/${campno}`);

  return Fetcher.getJson(url)
    .then((campaignDetails) => {
      dispatch(setCampaignDetails(campaignDetails));
    })
    .catch(() => {
      dispatch(setCampaignDetails({}));
    });
};

export const setCampaignDetails = (campaignDetails) => {
  return {
    type: actions.SET_CAMPAIGN_DETAILS,
    campaignDetails: campaignDetails,
  };
};

export const setSubscriptionInstalments = (updatedInstalments, originalInstalments) => {
  return {
    type: actions.SET_SUBSCRIPTION_INSTALMENTS,
    updatedInstalments: updatedInstalments,
    originalInstalments: originalInstalments,
  };
};

export const setSelectedSubsInstalment = (instalment) => {
  return {
    type: actions.SET_SELECTED_SUBS_INSTALMENT,
    selectedSubsInstalment: instalment,
  };
};

export const setSubscriptionPayer = (payer) => {
  return {
    type: actions.SET_SUBSCRIPTION_PAYER,
    payer: payer,
  };
};

export const setSubscriptionRecommender = (recommender) => {
  return {
    type: actions.SET_SUBSCRIPTION_RECOMMENDER,
    recommender: recommender,
  };
};

export const setSubscriptionSalesman = (salesman) => {
  return {
    type: actions.SET_SUBSCRIPTION_SALESMAN,
    salesman: salesman,
  };
};

export const getCustomerInvoiceModes = (cusno) => (dispatch) => {
  const url = apiUrl(`customers/${cusno}/invoice-modes`);

  return Fetcher.getJson(url)
    .then((invoiceModes) => {
      dispatch(setCustomerInvoiceModes(invoiceModes));
    })
    .catch(() => {
      dispatch(setCustomerInvoiceModes([]));
    });
};

export const setCustomerInvoiceModes = (customerInvModes) => {
  // Get names for invoice modes from system parameters
  const invoiceModeNames = store.getState().parameter.INVMODE;
  const invoiceModes = [];
  let defaultInvoiceMode = {};

  // Construct invoiceModes that will be stored to redux store
  customerInvModes.forEach((mode) => {
    if (mode.invoice_mode in invoiceModeNames.ALL) {
      const invModeObj = {
        ...mode,
        value: invoiceModeNames.ALL[mode.invoice_mode],
        invmodeid: mode.invoice_mode + "/" + mode.paper_code, // Build 3.6.2.2 (Issue #31348)
      };

      invoiceModes.push(invModeObj);
    }

    if (mode.default === true) {
      defaultInvoiceMode = mode;
    }
  });

  return {
    type: actions.SET_CUSTOMER_INVOICE_MODES,
    invoiceModes: invoiceModes,
    defaultInvoiceMode: defaultInvoiceMode,
  };
};

export const resetInvoiceModes = () => {
  return { type: actions.RESET_INVOICE_MODES };
};

export const setVatToExtraProduct = (vat) => {
  return {
    type: actions.SET_VAT_TO_EXTRA_PRODUCT,
    vat: vat,
  };
};

export const setPcsToExtraProduct = (pcs) => {
  return {
    type: actions.SET_PCS_TO_EXTRA_PRODUCT,
    pcs: pcs,
  };
};

export const setModifiedPriceToExtraProduct = (price) => {
  return {
    type: actions.SET_MODIFIED_PRICE_TO_EXTRA_PRODUCT,
    modifiedUnitPrice: price,
  };
};

export const setDeliveryIssues = (issues) => {
  return { type: actions.SET_DELIVERY_ISSUE, issues };
};

export const clearDeliveryIssues = () => {
  return { type: actions.CLEAR_DELIVERY_ISSUES };
};

export const getFreeSubsGroup = () => (dispatch) => {
  const url = apiUrl("subscriptions/free-subs-group");

  return Fetcher.getJson(url)
    .then((freeSubsGroup) => {
      dispatch(loadFreeSubsGroup(freeSubsGroup));
    })
    .catch(() => {
      dispatch(loadFreeSubsGroup([]));
    });
};

export const loadFreeSubsGroup = (freeSubsGroup) => {
  return {
    type: actions.LOAD_FREE_SUBS_GROUP,
    freeSubsGroup: freeSubsGroup,
  };
};

export const setSameAddressSubscriptions = (subscriptions) => {
  return { type: actions.SET_SAME_ADDRESS_SUBSCRIPTIONS, subscriptions };
};

export const clearSameAddressSubscriptions = () => {
  return { type: actions.CLEAR_SAME_ADDRESS_SUBSCRIPTIONS };
};

export const toggleSameAddressModal = () => {
  return { type: actions.TOGGLE_SAME_ADDRESS_MODAL };
};

export const setCustomerEvents = (id, events) => {
  return { type: actions.SET_CUSTOMER_EVENTS, id, events };
};

// Build 3.6.3.4 (Issue #32668)
export const setCustomerEventsChanged = (changed) => {
  return { type: actions.SET_CUSTOMER_EVENTS_CHANGED, changed };
};

export const selectCustomerEvent = (customerEvent) => {
  return { type: actions.SELECT_CUSTOMER_EVENT, customerEvent };
};

export const clearCustomerEvents = () => {
  return { type: actions.CLEAR_CUSTOMER_EVENTS };
};

// #30467
export const getCustomerEvents = (cusno) => (dispatch) => {
  const url = apiUrl(`customers/${cusno}/events`);
  Fetcher.getJson(url)
    .then((response) => {
      dispatch(setCustomerEvents(cusno, response));
    })
    .catch(() => {});
};

export const getSubscriptionSleeps = () => (dispatch) => {
  dispatch(loadSubscriptionSleeps([])); // Build 3.6.4.2 (Issue #33399)
  const subsno = store.getState().customer.subscription.selectedSubscription.order_subsno;
  const extno = store.getState().customer.subscription.selectedSubscription.order_extno;
  const url = apiUrl(`sleeps/${subsno}/${extno}`);

  return Fetcher.getJson(url)
    .then((sleeps) => {
      dispatch(loadSubscriptionSleeps(sleeps));
    })
    .catch(() => {
      dispatch(loadSubscriptionSleeps([]));
    });
};

export const loadSubscriptionSleeps = (sleeps) => {
  const sleepsWithKeys = [];
  // Add rowId for each sleep, also format money properly if creditType is set to money
  sleeps.forEach((sleep) => {
    let creditAmountFormatted = sleep.credit_amount;
    if (sleep.credit_type === "02") {
      creditAmountFormatted = formatCurrency(sleep.credit_amount);
    }

    sleepsWithKeys.push({
      ...sleep,
      rowId: generateKey(),
      creditAmountFormatted: creditAmountFormatted,
    });
  });

  return { type: actions.LOAD_SUBSCRIPTION_SLEEPS, sleeps: sleepsWithKeys };
};

export const setSelectedSleep = (sleep) => {
  return { type: actions.SET_SELECTED_SLEEP, sleep: sleep };
};

export const selectCancelSubscriptionItem = (item) => {
  return { type: actions.SELECT_CANCEL_SUBS_ITEM, item };
};

export const loadPaidAndOpenItems = (paidItems, openItems) => {
  return { type: actions.LOAD_PAID_AND_OPEN_ITEMS, paidItems, openItems };
};

// For now supports only subscription gift modal
export const toggleExtraProductsModal = () => {
  return { type: actions.TOGGLE_SUBSCRIPTION_GIFT_MODAL };
};

export const setItemExpenses = (itemExpenseWithoutVat, itemExpenseWithVat) => {
  return {
    type: actions.SET_ITEM_EXPENSES,
    itemExpenseWithoutVat,
    itemExpenseWithVat,
  };
};

export const setGrossPrices = (grossExclVat, grossInclVat) => {
  return {
    type: actions.SET_GROSS_PRICES,
    grossExclVat,
    grossInclVat,
  };
};

export const setSubscriptionPaperProduct = (product) => {
  return { type: actions.SET_SUBSCRIPTION_PAPER_PRODUCT, product };
};

export const setAvailableItemLengths = (standItemQty, perItemQty) => {
  const standingItemQty = formatItemQty(standItemQty);
  const periodicItemQty = formatItemQty(perItemQty);

  return {
    type: actions.SET_AVAILABLE_ITEM_LENGTHS,
    standItemQty: standingItemQty,
    perItemQty: periodicItemQty,
  };
};

export const getPaperGroups = () => (dispatch) => {
  const currentPaperGroups = store.getState().customer.subscription.paperGroups;

  if (currentPaperGroups.length === 0) {
    const url = apiUrl("subscriptions/paper-groups");

    fetchWithRetry("getJson", [url]).then((paperGroups) => {
      dispatch(loadPaperGroups(paperGroups));
    });
  }
};

const loadPaperGroups = (paperGroups) => {
  return { type: actions.SET_PAPER_GROUPS, paperGroups };
};

export const getGroupPapers = () => (dispatch) => {
  const currentGroupPapers = store.getState().customer.subscription.groupPapers;

  if (Object.keys(currentGroupPapers).length === 0) {
    const url = apiUrl("subscriptions/group-papers");

    fetchWithRetry("getJson", [url]).then((groupPapers) => {
      dispatch(loadGroupPapers(groupPapers));
    });
  }
};

const loadGroupPapers = (groupPapers) => {
  return { type: actions.SET_GROUP_PAPERS, groupPapers };
};

export const getSalesGroupPapers = () => (dispatch) => {
  const currentSalesGroupPapers = store.getState().customer.salesman.salesGroupPapers;

  if (currentSalesGroupPapers.length === 0) {
    const url = apiUrl("salesman/papers");

    // Build 3.4.6.2 (Issue #33446): change from getJson to postJson
    fetchWithRetry("postJson", [url]).then((salesGroupPapers) => {
      // Add id & name for each paper
      const updatedSalesGroupPapers = [];
      salesGroupPapers.forEach((paper) => {
        updatedSalesGroupPapers.push({
          ...paper,
          id: paper.paper_code,
          name: paper.paper_code + " - " + paper.paper_name,
        });
      });
      dispatch(loadSalesGroupPapers(updatedSalesGroupPapers));
    });
  }
};

const loadSalesGroupPapers = (salesGroupPapers) => {
  return { type: actions.LOAD_SALES_GROUP_PAPERS, salesGroupPapers };
};

export const getSalesGroups = () => (dispatch) => {
  const currentSalesGroups = store.getState().customer.salesman.salesGroups;

  if (Object.keys(currentSalesGroups).length === 0) {
    const url = apiUrl("salesman/groups");

    fetchWithRetry("getJson", [url]).then((salesGroups) => {
      dispatch(loadSalesGroups(salesGroups));
    });
  }
};

const loadSalesGroups = (salesGroups) => {
  return { type: actions.LOAD_SALES_GROUPS, salesGroups };
};

export const getSalesFeeGroups = () => (dispatch) => {
  const currentSalesFeeGroups = store.getState().customer.salesman.salesFeeGroups;

  if (Object.keys(currentSalesFeeGroups).length === 0) {
    const url = apiUrl("salesman/fee-groups");

    fetchWithRetry("getJson", [url]).then((salesFeeGroups) => {
      dispatch(loadSalesFeeGroups(salesFeeGroups));
    });
  }
};

const loadSalesFeeGroups = (salesFeeGroups) => {
  return { type: actions.LOAD_SALES_FEE_GROUPS, salesFeeGroups };
};

export const getSalesPrizes = () => (dispatch) => {
  const currentSalesPrizes = store.getState().customer.salesman.salesPrizes;

  if (Object.keys(currentSalesPrizes).length === 0) {
    const url = apiUrl("salesman/prizes");

    fetchWithRetry("getJson", [url]).then((salesPrizes) => {
      dispatch(loadSalesPrizes(salesPrizes));
    });
  }
};

const loadSalesPrizes = (salesPrizes) => {
  return { type: actions.LOAD_SALES_PRIZES, salesPrizes };
};

export const getCustomerMarketingPermissions = (cusno) => (dispatch) => {
  // #30373, show the first marketingPermission with some fields denied, get all marketings
  // const url = apiUrl(`customers/${cusno}/marketing-permission/ALL`);
  const url = apiUrl(`customers/${cusno}/marketing-permission`);

  return Fetcher.getJson(url)
    .then((marketingPermissions) => {
      dispatch(loadCustomerMarketingPermissions(marketingPermissions));
    })
    .catch(() => {
      dispatch(loadCustomerMarketingPermissions({}));
    });
};

export const loadCustomerMarketingPermissions = (marketingPermissions) => {
  return { type: actions.LOAD_MARKETING_PERMISSIONS, marketingPermissions };
};

export const clearMarketingPermissions = () => {
  return { type: actions.CLEAR_MARKETING_PERMISSIONS };
};

export const highlightSubscriptionProduct = (rowId) => (dispatch) => {
  dispatch(addHighlightToSubsproduct(rowId));

  setTimeout(() => {
    dispatch(clearHighlightFromSubsproduct());
  }, 2000);
};

const addHighlightToSubsproduct = (rowId) => {
  return { type: actions.ADD_HIGHLIGHT_TO_SUBSPRODUCT, rowId };
};

const clearHighlightFromSubsproduct = () => {
  return { type: actions.CLEAR_HIGHLIGHT_FROM_SUBSPRODUCT };
};

export const removeInvnoFromSubsInstalments = () => {
  return { type: actions.REMOVE_INVNO_FROM_SUBS_INSTALMENTS };
};

export const updateInstalmentsWithNewDueDate = (dueDates) => {
  return { type: actions.UPDATE_INSTALMENTS_WITH_NEW_DUE_DATE, dueDates };
};

export const updateSingleExtraProductInstalments = (extraProducts) => {
  return { type: actions.UPDATE_SINGLE_EXTRA_PRODUCT_INSTALMENTS, extraProducts };
};

export const updateAllExtraProductInstalments = (extraProducts) => {
  return { type: actions.UPDATE_ALL_EXTRA_PRODUCT_INSTALMENTS, extraProducts };
};

export const setShouldAskCreatingNewSubs = (flag) => {
  return { type: actions.SET_SHOULD_ASK_CREATING_NEW_SUBS, flag };
};

// set autogiro
export const setAutogiro = (autogiro = []) => {
  return { type: actions.SET_AUTOGIRO, autogiro: autogiro };
};

// #27610
export const shouldDisplayCustomerConfirmModal = (flag) => {
  return { type: actions.SHOULD_DISPLAY_CUSTOMER_CONFIRM_MODAL, flag };
};

// Build 3.6.2.2 (Issue #31348)
export const setChangingCusProperties = (flag) => {
  return { type: actions.CHANGING_CUSTOMER_PROPERTIES, flag };
};

// Build 3.6.2.2 (Issue #31348)
export const loadPreferInvmodes = () => {
  const allPreferInvmodes = store.getState().parameter.PREFER_INVMODE;
  let preferInvmodes = [];

  // Check if offices exists for given papercode, else use ALL as papercode
  if (allPreferInvmodes.ALL) {
    preferInvmodes = allPreferInvmodes.ALL.codevalue.split(";");
  }
  return { type: actions.SET_PREFER_INVMODE, preferInvmodes: preferInvmodes };
};

// Build 3.6.2.2 (Issue #31348)
export const loadInvmodePaperList = () => (dispatch) => {
  // Build 4.1.0.0 (ERP #11674), get papercode list for all invmodes once to optimize customermodal
  const url = apiUrl("customers/ALL/invmode-paper-list");

  const invmodepaperlist = [];
  Fetcher.getJson(url)
    .then((papers) => {
      if (Array.isArray(papers) && papers.length > 0) {
        if (Array.isArray(papers[0].invmode_paper)) {
          papers[0].invmode_paper.forEach((paper) => {
            invmodepaperlist.push({
              invmode: paper.invmode,
              id: paper.papercode,
              papercode: paper.papercode,
              papername: paper.papername,
              name: paper.papercode + " - " + paper.papername,
            });
          });
        } else {
          invmodepaperlist.push({
            invmode: papers[0].invmode_paper.invmode,
            id: papers[0].invmode_paper.papercode,
            papercode: papers[0].invmode_paper.papercode,
            papername: papers[0].invmode_paper.papername,
            name: papers[0].invmode_paper.papercode + " - " + papers[0].invmode_paper.papername,
          });
        }

        dispatch(setInvmodePaperList(invmodepaperlist));
      }
    })
    .catch(() => {
    });
};

// Build 4.1.0.0 (ERP #11674), get papercode list for all invmodes once to optimize customermodal
export const setInvmodePaperList = (invmodePaperList) => {
  return { type: actions.SET_INVMODE_PAPERLIST, paperlist: invmodePaperList };
};

// Build 3.6.4.2 (Issue #32834)
export const fetchSingleSubsPeriods = (subsno) => (dispatch) => {
  if (subsno) {
    const url = apiUrl(`subscriptions/${subsno}/singlecopyperiods`);
    return Fetcher.getJson(url)
      .then((response) => {
        dispatch(setSelectedSingleSubsPeriods(response));
      })
      .catch(() => {
        dispatch(setSelectedSingleSubsPeriods([]));
      });
  } else {
    dispatch(setSelectedSingleSubsPeriods([]));
  }
};

// Build 3.6.4.2 (Issue #32834)
export const setSelectedSingleSubsPeriods = (singleSubsPeriods) => {
  return {
    type: actions.SET_SELECTED_SINGLESUBS_PERIODS,
    singleSubsPeriods: singleSubsPeriods,
  };
};

// Build 3.6.4.2 (Issue #33221)
export const fetchCampaignDetails = (campno) => {
  if (campno <= 0) {
    return {};
  }

  const url = apiUrl(`campaigns/${campno}`);

  return Fetcher.getJson(url)
    .then((campaignDetails) => {
      return campaignDetails;
    })
    .catch(() => {
      return {};
    });
};

// Build 3.4.6.2 (Issue #33350)
export const getProcessedValues = (values) => {
  const newValues = { ...values };
  delete newValues.searchType;

  if (newValues.quicksearch) {
    return getQuickSearchValues(newValues);
  } else if (newValues.subsno_YJ) {
    return getSubsnoYJValues(newValues);
  }
  return newValues;
};

const getSubsnoYJValues = (value) => {
  return { subsno: value.subsno_YJ, source: "YJ" };
};

const getQuickSearchValues = (value) => {
  const sParam = store.getState().parameter.QUICKSEARCH.ALL.codevalue;
  const params = sParam.split(",");

  let iNameLength = 0;
  let iStreetLength = 0;
  let iHouseLength = 0;
  let iZipcodeLength = 0;
  params.forEach((item) => {
    if (item.includes("N")) {
      iNameLength = parseInt(item.replace("N", ""));
    } else if (item.includes("S")) {
      iStreetLength = parseInt(item.replace("S", ""));
    } else if (item.includes("H")) {
      iHouseLength = parseInt(item.replace("H", ""));
    } else if (item.includes("Z")) {
      iZipcodeLength = parseInt(item.replace("Z", ""));
    }
  });

  const newValues = {};
  let sNewValue = value.quicksearch || "";
  if (iNameLength > 0) {
    let sName = "";
    if (sNewValue.length > iNameLength) {
      sName = sNewValue.substr(0, iNameLength);
      sNewValue = sNewValue.substr(iNameLength);
    } else {
      sName = sNewValue;
      sNewValue = "";
    }
    if (sName !== "") newValues.name1 = sName;
  }

  if (iStreetLength > 0) {
    let sStreet = "";
    if (sNewValue.length > iStreetLength) {
      sStreet = sNewValue.substr(0, iStreetLength);
      sNewValue = sNewValue.substr(iStreetLength);
    } else {
      sStreet = sNewValue;
      sNewValue = "";
    }
    if (sStreet !== "") newValues.street = sStreet;
  }

  if (iHouseLength > 0) {
    let sHouse = "";
    if (sNewValue.length > iHouseLength) {
      sHouse = sNewValue.substr(0, iHouseLength);
      sNewValue = sNewValue.substr(iHouseLength);
    } else {
      sHouse = sNewValue;
      sNewValue = "";
    }
    if (sHouse !== "") newValues.houseno = sHouse;
  }

  if (iZipcodeLength > 0) {
    let sZipcode = "";
    if (sNewValue.length > iZipcodeLength) {
      sZipcode = sNewValue.substr(0, iZipcodeLength);
      sNewValue = sNewValue.substr(iZipcodeLength);
    } else {
      sZipcode = sNewValue;
      sNewValue = "";
    }
    if (sZipcode !== "") newValues.zipcode = sZipcode;
  }

  return newValues;
};

// Build 3.4.6.2 (Issue #33350)
export const getAllSearchTypes = () => {
  return [
    {
      id: "01",
      name: tr("name"),
      fieldName: "name1",
      type: "text",
      isInt: false,
      placeHolder: `${tr("lastname")} ${tr("firstname")}`,
    },
    {
      id: "02",
      name: tr("customerNumber"),
      fieldName: "cusno",
      type: "text", // Build 3.4.6.2 (Issue #33409): change to text
      isInt: false, // Build 3.4.6.2 (Issue #33409): change to false
      placeHolder: tr("customerNumber"),
    },
    {
      id: "03",
      name: tr("subscriptionNumber"),
      fieldName: "subsno",
      type: "text",
      isInt: false,
      placeHolder: tr("subscription"),
    },
    {
      id: "04",
      name: tr("invoiceNo"),
      fieldName: "invno",
      type: "text",
      isInt: false,
      placeHolder: tr("invoiceNo"),
    },
    {
      id: "05",
      name: tr("quickSearch"),
      fieldName: "quicksearch",
      type: "text",
      isInt: false,
      placeHolder: tr("quickSearch"),
    },
    {
      id: "06",
      name: tr("phone"),
      fieldName: "phone",
      type: "text",
      isInt: false,
      placeHolder: tr("phone"),
    },
    {
      id: "07",
      name: tr("account"),
      fieldName: "account",
      type: "text",
      isInt: false,
      placeHolder: tr("account"),
    },
    {
      id: "08",
      name: tr("socialSecNo"),
      fieldName: "socialsecno",
      type: "text",
      isInt: false,
      placeHolder: tr("socialSecNo"),
    },
    {
      id: "09",
      name: tr("email"),
      fieldName: "email",
      type: "text",
      isInt: false,
      placeHolder: tr("email"),
    },
    {
      id: "10",
      name: tr("referenceNo"),
      fieldName: "referenceno",
      type: "text",
      isInt: false,
      placeHolder: tr("referenceNo"),
    },
    {
      id: "11",
      name: tr("otherSubsNo"),
      fieldName: "othersubsno",
      type: "text",
      isInt: false,
      placeHolder: tr("otherSubsNo"),
    },
    {
      id: "12",
      name: tr("otherCusNo"),
      fieldName: "othercusno",
      type: "text",
      isInt: false,
      placeHolder: tr("otherCusNo"),
    },
    {
      id: "13",
      name: tr("streetName1"),
      fieldName: "street",
      type: "text",
      isInt: false,
      placeHolder: tr("streetName1"),
    },
    {
      id: "14",
      name: tr("orderId"),
      fieldName: "orderid",
      type: "text",
      isInt: false,
      placeHolder: tr("orderId"),
    },
    {
      id: "15",
      name: `${tr("subscriptionNumber")} ${tr("YJ")}`,
      fieldName: "subsno_YJ",
      type: "text",
      isInt: false,
      placeHolder: `${tr("subscriptionNumber")} ${tr("YJ")}`,
    },
    {
      id: "16",
      name: tr("organizationNo"),
      fieldName: "companyid",
      type: "text",
      isInt: false,
      placeHolder: tr("organizationNo"),
    },
  ];
};

// Build 3.4.6.2 (Issue #33409)
export const getAllSearchType = (id) => {
  return getAllSearchTypes().find((typeItem) => typeItem.id === id);
};

// Build 3.6.4.2 (Issue #33372)
export const getMarketingMaterial = (cusno) => (dispatch) => {
  if (cusno) {
    const url = apiUrl(`/customers/${cusno}/marketing-material`);
    return Fetcher.getJson(url)
      .then((response) => {
        dispatch(setMarketingMaterial(response.material));
      })
      .catch(() => {
        dispatch(setMarketingMaterial([]));
      });
  } else {
    dispatch(setMarketingMaterial([]));
  }
};

export const setMarketingMaterial = (materials) => {
  return { type: actions.SET_MARKETING_MATERIAL, materials };
};

// Build 3.4.6.2 (Issue #33409)
export const setHistorySelection = (cusno) => {
  return { type: actions.SET_HISTORY_SELECTION, cusno };
};

// Build 3.6.4.2 (Issue #26462)
export const getWinsaleTypes = () => (dispatch) => {
  const url = apiUrl("subscriptions/winsale-types");
  return Fetcher.getJson(url)
    .then((types) => {
      const items = [];
      items.push({ id: "", name: "" });
      types.forEach((item) => {
        items.push({
          id: item.winsaletypeid,
          name: item.winsaletypeid + " - " + item.winsaletype,
          ...item,
        });
      });
      dispatch(loadWinsaleTypes(items));
    })
    .catch(() => {
      dispatch(loadWinsaleTypes([]));
    });
};

// Build 3.6.4.2 (Issue #26462)
export const loadWinsaleTypes = (winsaleTypes) => {
  return {
    type: actions.LOAD_WINSALE_TYPES,
    winsaleTypes,
  };
};

// Build 3.6.4.2 (Issue #12471)
export const fetchPostmachRowLength = (mainPaper) => (dispatch) => {
  if (store.getState().customer.rowLength >= 0) {
    return;
  }

  let defaultPMach = "";
  if (store.getState().parameter.DEFPMACH && store.getState().parameter.DEFPMACH[mainPaper]) {
    defaultPMach = store.getState().parameter.DEFPMACH[mainPaper].codevalue;
  }
  if (!defaultPMach || defaultPMach === "") {
    dispatch(loadPostMachines(40));
    return;
  }

  const url = apiUrl("/addresses/labels/machines");

  return Fetcher.getJson(url)
    .then((machines) => {
      const machine = machines.find((item) => {
        if (item.machine_id === defaultPMach) {
          return item;
        }
      });
      let rowLength = 40;
      if (machine) {
        rowLength = machine.row_length;
      }
      dispatch(loadPostMachines(rowLength));
    })
    .catch((error) => {
      loadPostMachines(-1);
      throw new SubmissionError(error);
    });
};

// Build 3.6.4.2 (Issue #12471)
export const loadPostMachines = (rowLength) => {
  return { type: actions.LOAD_POST_MACHINES, rowLength };
};

// Build 3.6.4.2 (ERP #11080)
export const checkSocialSecNo = (socialSecNo, cusType) => {
  const url = apiUrl("customers/social-secno/validate");
  const body = {
    socialsecno: socialSecNo,
    custype: cusType,
  };

  return Fetcher.postJson(url, body)
    .then((response) => {
      return response.valid;
    })
    .catch(() => {
      return false;
    });
};

// Build 3.6.4.2 (ERP #11110)
export const checkAddressMissing = (cusno) => {
  const url = apiUrl(`customers/${cusno}/address`);
  return Fetcher.getJson(url)
    .then((basicaddress) => {
      if (basicaddress && Object.keys(basicaddress).length > 0) {
        const streetname = basicaddress.streetname.trim() || "";
        const street2 = basicaddress.street2.trim() || "";
        if (streetname === "" && street2 === "") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .catch(() => {
      return true;
    });
};

// Build 3.6.4.2 (ERP #11310)
export const customerName = (customer) => {
  if (!customer || customer === null || customer === undefined) {
    return "";
  }

  if (customer.cusstate === "04") {
    return tr("mergedHidden");
  }

  let sName = "";
  const rowtext1 = customer.rowtext1 || "";
  if (store.getState().parameter.MIXEDCASENAME.ALL.codevalue === "Y") {
    const sFirstName = customer.firstname || "";
    const sLastName = customer.lastname || "";
    const sCompanyName = customer.companyname || "";

    const sConcat = sLastName + " " + sFirstName;

    const bUseFirstnameLastname =
      sConcat.toUpperCase() === rowtext1.toUpperCase() && sConcat !== rowtext1;
    const bUseCompanyName =
      sCompanyName.toUpperCase() === rowtext1.toUpperCase() && sCompanyName !== rowtext1;

    if (bUseFirstnameLastname) {
      sName = sConcat;
    } else {
      if (bUseCompanyName) {
        sName = sCompanyName;
      } else {
        sName = rowtext1;
      }
    }
  } else {
    sName = capitalize(rowtext1);
  }

  return sName;
};

// Build 3.6.4.2 (ERP #11828) ...
export const getContactInfos = (cusno) => (dispatch) => {
  const url = apiUrl(`customers/${cusno}/contact-details`);

  return Fetcher.getJson(url)
    .then((contactinfos) => {
      dispatch(loadContactInfos(contactinfos));
    })
    .catch(() => {
      dispatch(loadContactInfos([]));
    });
};

export const loadContactInfos = (contactinfos) => (dispatch) => {
  dispatch(setContactInfos(contactinfos));
};

export const setContactInfos = (contactInfos) => {
  return { type: actions.SET_CONTACT_INFOS, contactinfos: contactInfos };
};
// ... Build 3.6.4.2 (ERP #11828)