import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import Modal from "../../components/Modal.jsx";
import { ContentContainer } from "../../components/common/layout.jsx";
import { tr } from "../../utils/translation.js";
import { SubmitButton } from "../../components/common/buttons.jsx";
import ReportNavigation from "./ReportNavigation.jsx";
import ReportSheet from "../../components/report/ReportSheet.jsx";

/**
 * New Report modal can be used to run a report
 */
class NewReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {},
    };
    this.setReport = this.setReport.bind(this);
  }

  // Pass this to ReportNavigation
  // onclick in ReportNavigation call this and change state here
  // state.repno will then propagate to ReportSheet
  setReport(newreport) {
    if (newreport === undefined) newreport = {};
    this.setState({
      report: newreport,
    });
  }

  render() {
    const title = this.props.title;
    const discardText = this.props.discardText || tr("close");
    const { submitting = false } = this.props;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title={title}
        className="modal-xlg single-tab-modal"
      >
        <ContentContainer className="modal-content-container">
          <div id="main-container">
            <ReportNavigation setReport={this.setReport} />
            <ReportSheet report={this.state.report} />
          </div>
          <SubmitButton color="primary" text={"Run report"} submitting={submitting} />
        </ContentContainer>
        <div className="modal-footer">
          <Button color="default" onClick={this.props.toggle}>
            {discardText}
          </Button>
        </div>
      </Modal>
    );
  }
}

NewReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  discardText: PropTypes.string,
  submitting: PropTypes.bool,
};

export default NewReportModal;
