/* eslint-disable react/prop-types */
// Issue 28110
import React from "react";
import { Nav, NavItem, NavLink, Collapse } from "reactstrap";

import { ArrowDown, ArrowUp } from "../common/arrows.jsx";
// import { tr } from '../../utils/translation.js';

const _getIcon = (icon) => {
  if (icon !== "") {
    const className = `icon ${icon}`;
    return <span className={className}></span>;
  }
  return "";
};

// Spacer item for adding padding to sub menu's text. Used for visually
// differentiating nested menus with additional padding.
const Spacer = ({ level }) => {
  if (level > 2) {
    const width = (level - 2) * 15;
    return <div className="spacer" style={{ width: `${width}px` }} />;
  }
  return null;
};

const Menu = ({ state, items, clickHandler, className = "" }) => (
  <Nav vertical className={className}>
    {items.map((item) =>
      item.type === "menu" ? (
        <MenuAccordion state={state} item={item} clickHandler={clickHandler} key={item.id} />
      ) : (
        <MenuLink item={item} key={item.id} clickHandler={clickHandler} />
      )
    )}
  </Nav>
);

const MenuAccordion = ({ state, item, clickHandler }) => {
  const arrowStyle = { float: "right" };

  return (
    <NavItem className={item.className}>
      <NavLink href={item.link} id={item.id} onClick={() => clickHandler(item.id)}>
        <Spacer level={item.level} />
        {_getIcon(item.icon)}
        {item.title}
        {state[item.id].open ? <ArrowDown style={arrowStyle} /> : <ArrowUp style={arrowStyle} />}
      </NavLink>
      <Collapse isOpen={state[item.id].open}>
        <Menu state={state} items={item.items} clickHandler={clickHandler} />
      </Collapse>
    </NavItem>
  );
};

const MenuLink = ({ item, clickHandler }) => {
  return (
    <NavItem className={item.className}>
      <NavLink
        repno={item.repno}
        href={item.link}
        className="nav-link"
        onClick={() => clickHandler(item.id)}
        {...item.options}
      >
        <Spacer level={item.level} />
        {_getIcon(item.icon)}
        {item.title}
      </NavLink>
    </NavItem>
  );
};

const ReportMenu = (props) => {
  return (
    <Menu
      state={props.state}
      items={props.items}
      clickHandler={props.clickHandler}
      className="main-menu"
    />
  );
};

export default ReportMenu;
export { MenuLink, MenuAccordion, Menu, Spacer };
