import * as actions from "../actions/actionTypes.js";

const defaultState = {
  instalments: [],
  extraCharges: [],
  invoiceInstalments: [],
  invoiceExtraCharges: [],
  selectedInvoiceInstalments: [],
  selectedInvoiceExtraCharges: [],
  selectedInstalment: {},
  arguments: [],
  priceListNumber: 0,
  priceList: {},
  standingPriceListRows: [],
  periodicPriceListRows: [],
  selectedCreditInvoiceRows: [],
  selectedCreditInvoiceRowItem: {},
  invoice: {},
  invoiceText: "",
  invoiceDetails: {},
  invoiceHistoryDates: {},
  offices: [],
  ledger: [],
  shouldReloadLedger: false,
  selectedLog: {},
  selectedLogSubscription: {},
  payments: [],
  selectedPayment: {},
  invoiceModeFields: [],
  shouldCheckCreateCreditInvoice: false,
};

const invoiceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.LOAD_INSTALMENTS: {
      return { ...state, instalments: action.instalments };
    }

    case actions.LOAD_EXTRA_CHARGE_INSTALMENTS: {
      return { ...state, extraCharges: action.extraCharges };
    }

    case actions.SET_SELECTED_INSTALMENT: {
      return { ...state, selectedInstalment: action.selectedInstalment };
    }

    case actions.LOAD_ALL_ARGUMENTS: {
      return { ...state, arguments: action.arguments };
    }

    case actions.LOAD_PRICELIST_NUMBER: {
      return { ...state, priceListNumber: action.priceListNumber };
    }

    case actions.LOAD_PRICELIST: {
      return { ...state, priceList: action.priceList };
    }

    case actions.LOAD_STANDING_PRICELIST_ROWS: {
      return { ...state, standingPriceListRows: action.priceRows };
    }

    case actions.LOAD_PERIODIC_PRICELIST_ROWS: {
      return { ...state, periodicPriceListRows: action.priceRows };
    }

    case actions.CLEAR_INVOICE_ARGUMENTS: {
      const invoiceModeFields = state.invoiceModeFields;
      return { ...defaultState, invoiceModeFields };
    }

    case actions.SET_IMMEDIATE_INVOICE_ROWS: {
      return {
        ...state,
        invoiceInstalments: action.instalments,
        invoiceExtraCharges: action.extraCharges,
      };
    }

    case actions.SET_SELECTED_INVOICE_INSTALMENTS: {
      return { ...state, selectedInvoiceInstalments: action.instalments };
    }

    case actions.SET_SELECTED_INVOICE_EXTRA_CHARGES: {
      return { ...state, selectedInvoiceExtraCharges: action.extraCharges };
    }

    case actions.SELECT_INVOICE_INSTALMENT: {
      return {
        ...state,
        selectedInvoiceInstalments: [...state.selectedInvoiceInstalments, action.instalment],
      };
    }

    case actions.SELECT_INVOICE_EXTRA_CHARGE: {
      return {
        ...state,
        selectedInvoiceExtraCharges: [...state.selectedInvoiceExtraCharges, action.extraCharge],
      };
    }

    case actions.UNSELECT_INVOICE_INSTALMENT: {
      // eslint-disable-next-line array-callback-return
      const selectedInstalments = state.selectedInvoiceInstalments.filter((instalment) => {
        if (instalment !== action.instalment) {
          return instalment;
        }
      });
      return { ...state, selectedInvoiceInstalments: selectedInstalments };
    }

    case actions.UNSELECT_INVOICE_EXTRA_CHARGE: {
      // eslint-disable-next-line array-callback-return
      const selectedExtraCharges = state.selectedInvoiceExtraCharges.filter((extraCharge) => {
        if (extraCharge !== action.extraCharge) {
          return extraCharge;
        }
      });
      return { ...state, selectedInvoiceExtraCharges: selectedExtraCharges };
    }

    case actions.SET_SELECTED_CREDIT_INVOICE_ROWS: {
      return { ...state, selectedCreditInvoiceRows: action.rows };
    }

    case actions.SET_SELECTED_CREDIT_INVOICE_ROW_ITEM: {
      return { ...state, selectedCreditInvoiceRowItem: action.item };
    }

    case actions.LOAD_INVOICE: {
      return { ...state, invoice: action.invoice };
    }

    case actions.LOAD_INVOICE_TEXT: {
      return { ...state, invoiceText: action.text };
    }

    case actions.LOAD_INVOICE_DETAILS: {
      return { ...state, invoiceDetails: action.invoiceDetails };
    }

    case actions.LOAD_INVOICE_HISTORY_DATES: {
      return { ...state, invoiceHistoryDates: action.invoiceHistoryDates };
    }

    case actions.SET_AVAILABLE_OFFICES: {
      return { ...state, offices: action.offices };
    }

    case actions.LOAD_LEDGER: {
      return { ...state, ledger: action.ledger };
    }

    case actions.SET_SHOULD_RELOAD_LEDGER: {
      return {
        ...state,
        shouldReloadLedger: action.flag,
      };
    }

    case actions.SELECT_LEDGER_LOG: {
      return { ...state, selectedLog: action.log };
    }

    case actions.SELECT_SUB_FOR_LEDGER_LOG: {
      return { ...state, selectedLogSubscription: action.subscription };
    }

    case actions.LOAD_PAYMENTS: {
      return { ...state, payments: action.payments };
    }

    case actions.SET_SELECTED_PAYMENT: {
      return { ...state, selectedPayment: action.payment };
    }

    case actions.LOAD_INVOICE_MODE_FIELDS: {
      return { ...state, invoiceModeFields: action.invoiceModeFields };
    }

    case actions.SET_CREDIT_INVOICE_CHECK: {
      return { ...state, shouldCheckCreateCreditInvoice: action.isChecked };
    }

    case actions.RESET_INSTALMENTS: {
      return {
        ...state,
        instalments: defaultState.instalments,
        arguments: defaultState.arguments,
      };
    }

    default:
      return state;
  }
};

export default invoiceReducer;
