import * as actions from "./actionTypes.js";

const newEmail = (recipients = [], subject = "", body = "") => {
  return { type: actions.NEW_EMAIL, recipients, subject, body };
};

const clearEmail = () => {
  return { type: actions.CLEAR_EMAIL };
};

const closeEmail = () => {
  return { type: actions.CLOSE_EMAIL };
};

export { newEmail, closeEmail, clearEmail };
