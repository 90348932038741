import * as actions from "../actions/actionTypes.js";

const defaultState = {
  active: false,
  recipients: [],
  subject: "",
  body: [],
};

const emailReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.NEW_EMAIL: {
      const recipients = Array.isArray(action.recipients) ? action.recipients : [action.recipients];
      const body = Array.isArray(action.body) ? action.body : [action.body];

      return { ...state, active: true, subject: action.subject, recipients, body };
    }

    case actions.CLEAR_EMAIL:
      return { ...defaultState };

    case actions.CLOSE_EMAIL:
      return { ...state, active: false };

    default:
      return { ...state };
  }
};

export default emailReducer;
