import React from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "../../assets/styles/notification.scss";

/*
 * Animated global notification component.
 */

// Icons for notification boxes
const icons = {
  success: "ion-md-checkmark-circle",
  warning: "ion-md-warning",
  error: "ion-md-alert",
};
// Duration for the enter and exit animations
const animationDuration = 350;

// eslint-disable-next-line react/prop-types
const Animate = ({ children, ...props }) => (
  <CSSTransition {...props} timeout={animationDuration} classNames="slide" unmountOnExit={true}>
    {children}
  </CSSTransition>
);

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      in: true,
    };
    this.timerId = null;

    // Duration for how long the notification is visible before exiting
    // #27456, Make duration of notification shorter
    // const stayDuration = 5000;
  }

  componentDidMount() {
    this.timerId = window.setTimeout(() => {
      this._disappear();
      // #31651, use parrow to control duration, default defined as 3000ms in parameterReducer
    }, this.props.stayDuration.ALL.codevalue);
  }

  componentWillUnmount() {
    window.clearTimeout(this.timerId);
  }

  _disappear() {
    this.setState({ in: false });
  }

  _handleExit() {
    this.props.onDismiss(this.props.index);
  }

  render() {
    return (
      <Animate in={this.state.in} onExited={this._handleExit.bind(this)}>
        <div className={`notification ${this.props.type}`}>
          <span className={`notification-icon ${icons[this.props.type]}`}></span>
          <div className="notification-message">{this.props.message}</div>
          <span
            className="notification-button ion-ios-close"
            onClick={this._disappear.bind(this)}
          ></span>
        </div>
      </Animate>
    );
  }
}

Notification.propTypes = {
  onDismiss: PropTypes.func.isRequired, // Function to call when unmounting a notification
  index: PropTypes.number.isRequired, // Array index for the parent to know which notification to unmount
  type: PropTypes.string.isRequired, // Type of the notification (affects visual style)
  message: PropTypes.string.isRequired, // Message to display inside a notification
  stayDuration: PropTypes.object.isRequired, // #31651, message showing time
};

// #31651
const mapStateToProps = (state) => {
  return {
    // #31651, message showing time
    stayDuration: state.parameter.MESSAGE_DELAY,
  };
};

export default connect(mapStateToProps)(Notification);
