/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Col, Row } from "reactstrap";
import zxcvbn from "zxcvbn";

import { SubmitButton } from "../common/buttons.jsx";
import { tr } from "../../utils/translation.js";
import { minLength, validatePasswordCharacters } from "../../utils/validate.js";
import { FormInput } from "../form/inputs.jsx";
import PasswordStrength from "./PasswordStrength.jsx";

// Validate form and return possible errors
export const validate = (values) => {
  const errors = {};
  const minLength8 = minLength(8);

  // old password
  if (!values.oldPassword) {
    errors.oldPassword = tr("required");
  }

  // new password
  if (!values.newPassword) {
    errors.newPassword = tr("required");
  } else if (!minLength8(values.newPassword)) {
    errors.newPassword = tr("passwordMinLengthWarning");
  } else if (!validatePasswordCharacters(values.newPassword)) {
    errors.newPassword = tr("passwordInfo");
  }

  // new password again
  if (!values.newPasswordAgain) {
    errors.newPasswordAgain = tr("required");
  } else if (!minLength8(values.newPasswordAgain)) {
    errors.newPasswordAgain = tr("passwordMinLengthWarning");
  } else if (values.newPassword !== values.newPasswordAgain) {
    errors.newPasswordAgain = tr("passwordNotSameAsInOriginalField");
  } else if (!validatePasswordCharacters(values.newPasswordAgain)) {
    errors.newPasswordAgain = tr("passwordInfo");
  }

  return errors;
};

/**
 * Form for collecting new password information. Value from newPassword field is
 * used to estimate the strength of the new password and based on estimation score,
 * PasswordStrength component receives appropriate prop to display visual presentation
 * about password strength.
 */
class ChangePasswordForm extends React.Component {
  render() {
    const { handleSubmit, submitting, newPassword, resetForm = false } = this.props;
    let passwordStrength;

    // If newPassword field has password, evaluate that password and set strength score
    if (
      newPassword !== undefined &&
      newPassword.values !== undefined &&
      newPassword.values.newPassword !== undefined
    ) {
      // zxcvbn returns object from which score property can be used to determine password strength
      const result = zxcvbn(newPassword.values.newPassword);
      if (result.score < 3) {
        passwordStrength = -1;
      } else if (result.score >= 3 && result.score < 4) {
        passwordStrength = 0;
      } else if (result.score >= 4) {
        passwordStrength = 1;
      }
    }

    if (resetForm) {
      return (
        // #32937, disable auto complete
        <form onSubmit={handleSubmit} className="mb-2" autoComplete="off">
          <Field
            name="newPassword"
            component={FormInput}
            type="password"
            placeholder={tr("newPassword")}
          />
          <Field
            name="newPasswordAgain"
            component={FormInput}
            type="password"
            placeholder={tr("newPasswordAgain")}
          />
          <PasswordStrength strength={passwordStrength} />
          <div>
            <SubmitButton submitting={submitting} text={tr("resetPassword")} />
          </div>
        </form>
      );
    }

    return (
      // #32937, disable auto complete
      <form onSubmit={handleSubmit} autoComplete="off">
        <h2>{tr("newPassword")}</h2>
        <Row>
          <Col>
            <Field
              name="oldPassword"
              component={FormInput}
              type="password"
              label={tr("oldPassword")}
            />
            <Field
              name="newPassword"
              component={FormInput}
              type="password"
              label={tr("newPassword")}
            />
            <Field
              name="newPasswordAgain"
              component={FormInput}
              type="password"
              label={tr("passwordAgain")}
            />
            <Row className="justify-content-end">
              <Col sm={7}>
                <PasswordStrength strength={passwordStrength} />
              </Col>
            </Row>
            <div className="float-right">
              <SubmitButton submitting={submitting} text={tr("replace")} />
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}

ChangePasswordForm.propTypes = {
  resetForm: PropTypes.bool, // If true, component is used to reset new password
};

const mapStateToProps = (state) => ({
  newPassword: state.form.changePasswordForm,
});

// eslint-disable-next-line no-class-assign
ChangePasswordForm = connect(mapStateToProps)(ChangePasswordForm);

export default reduxForm({
  form: "changePasswordForm",
  validate,
})(ChangePasswordForm);
