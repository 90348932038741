import React from "react";
import PropTypes from "prop-types";
import { Button, ModalFooter } from "reactstrap";

import Modal from "./Modal.jsx";
import { SubmitButton } from "./common/buttons.jsx";
import { tr } from "../utils/translation.js";

/**
 * Reusable confirmation modal component. Works similar to modal component,
 * except confirmation modal has places for question text (or props.children), and discard &
 * confirm buttons. If user makes confirm selection by pressing confirm button,
 * toggle callback function receives string 'confirmed' which can be used
 * to check whether user confirmed the modal or not.
 */
const ConfirmationModal = (props) => {
  // #30631, add hideDiscardButton prop too
  // Build 3.6.2.2 (Issue #31348): add hideDisconfirmButton
  const {
    submitting = false,
    hideConfirmButton = false,
    hideDiscardButton = false,
    hideDisconfirmButton = true,
    messageExtraText = "", // #33324
  } = props;

  // Build 3.6.3.4 (Issue #31348)
  let question = props.question;
  let listItems;
  if (props.messages !== undefined) {
    if (props.messages.length > 1) {
      listItems = props.messages.map((message) => <li key={message.toString()}>{message}</li>);
    } else {
      question = props.messages[0];
    }
  }
  // ...Build 3.6.3.4 (Issue #31348)
  return (
    <div className="confirmation-modal-container">
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        title={props.title}
        className="confirmation-modal"
      >
        {props.children === undefined ? (
          // Build 3.6.3.4 (Issue #31348)...
          // #33324, list of message may need extra info ...
          props.messages === undefined || props.messages.length <= 1 ? (
            messageExtraText === "" ? (
              <p>{question}</p>
            ) : (
              // ((<p>{messageExtraText}</p>), (<p>{question}</p>))
              <div>
                <p>{messageExtraText}</p>
                <p>{question}</p>
              </div>
            )
          ) : messageExtraText === "" ? (
            <ul>{listItems}</ul>
          ) : (
            // ((<p>{messageExtraText}</p>), (<ul>{listItems}</ul>))
            <div>
              <p>{messageExtraText}</p>
              <ul>{listItems}</ul>
            </div>
          )
        ) : (
          // ... #33324, list of message may need extra info
          // ...Build 3.6.3.4 (Issue #31348)
          props.children
        )}
        <ModalFooter>
          {
            // #30631, add hideDiscardButton prop too
            hideDiscardButton ? null : (
              <Button
                color="default"
                className={submitting ? "mr-2" : ""}
                onClick={() => props.toggle("discarded")}
              >
                {/* Build 3.6.4.1 (Issue #33098): default no */}
                {props.discardButtonText ? props.discardButtonText : tr("no")}
              </Button>
            )
          }
          {hideConfirmButton ? null : (
            <SubmitButton
              onClick={() => props.toggle("confirmed")}
              submitting={submitting}
              // Build 3.6.4.1 (Issue #33098): default yes
              text={props.confirmButtonText ? props.confirmButtonText : tr("yes")}
            />
          )}
          {hideDisconfirmButton ? null : (
            <SubmitButton
              onClick={() => props.toggle("disconfirmed")}
              submitting={submitting}
              text={props.disconfirmButtonText}
            />
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool, // Flag to determine is modal open or not
  toggle: PropTypes.func, // Callback function to toggle modals visibility
  title: PropTypes.string, // Modal title text that is displayed,
  question: PropTypes.string, // Question or information to which user has to react somehow
  confirmButtonText: PropTypes.string, // Text displayed in the confirm button
  discardButtonText: PropTypes.string, // Text displayed in the discard button
  disconfirmButtonText: PropTypes.string, // Text displayed in the disconfirm button
  submitting: PropTypes.bool, // Optionally provide submitting status for async confirmations
  hideConfirmButton: PropTypes.bool, // Optionally hide confirm button
  hideDiscardButton: PropTypes.bool,
  hideDisconfirmButton: PropTypes.bool,
  children: PropTypes.node,
  messages: PropTypes.array, // Build 3.6.3.4 (Issue #31348): show multiple messages
  messageExtraText: PropTypes.string, // #33324, text for message
};

export default ConfirmationModal;
