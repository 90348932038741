/* Chunk array into chunks
Examples:

chunkArray([1,2,3,4,5,6],2);
// returns [[1,2,3],[4,5,6]]

chunkArray([1,2,3,4,5,6,7],2);
// returns [[1,2,3],[4,5,6,7]]

chunkArray([1,2,3,4,5,6],3);
// returns [[1,2],[3,4],[5,6]]

chunkArray([1,2,3,4,5,6,7,8],3);
// returns [[1,2],[3,4,5],[6,7,8]]

chunkArray([1,2,3,4,5,6,7,8],42);//over chunk
// returns [[1],[2],[3],[4],[5],[6],[7],[8]]
*/

const chunkArray = (arr, n) => {
  const chunkLength = Math.max(arr.length / n, 1);
  const chunks = [];
  for (let i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length)
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
  }
  return chunks;
};

const SUBSKIND_ALL = [ "01", "02", "03", "04", "05", "06" ];

export { chunkArray, SUBSKIND_ALL };
