// Flexible report sheet which contents are dynamically constructed by report
// Issue 28110
import React from "react";
import PropTypes from "prop-types";
import { Container, Col, Label, Row, Input, Button } from "reactstrap";
// import { reduxForm, Form  } from 'redux-form';
import apiUrl from "../../utils/url.js";
import { Fetcher } from "../../utils/fetcher.js";

// import { tr } from '../../utils/translation.js';
const Spacer = () => {
  return <div className="spacer" style={{ width: "10px", height: "10px" }} />;
};

class ReportSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = { repname: "Select", inputs: [], checkboxes: [], radios: [], fields: [] };
  }

  _getIcon = (icon) => {
    if (icon !== "") {
      const className = `icon ${icon}`;
      return <span className={className}></span>;
    }
    return "";
  };

  _fetchReprequests() {
    const requestBody = null;
    const url = apiUrl("reports/findreprequest");
    if (this.state.loading) return null;

    const state = this.state;
    state.loading = true;
    state.reprequests = null;
    this.setState(state);

    return Fetcher.postJson(url, requestBody)
      .then((reprequests) => {
        if (this.state.reprequests !== null) {
          // When reports has value, return it
          return reprequests;
        }

        const state = this.state;
        state.loading = false;
        state.reprequests = reprequests;
        this.setState(state);
      })
      .catch(() => {
        // this.setState({ loading: false, reports: this.state.reprequests });
      });
  }

  // Put props to state
  componentDidUpdate(prevProps) {
    if (
      this.props.report === prevProps.report ||
      this.props.report === undefined ||
      this.props.report.repno === undefined
    )
      return;
    const report = this.props.report;
    // Pardef1
    const pardef1Split = report.pardef1 ? report.pardef1.split(";") : [];
    const pardef2Split = report.pardef2 ? report.pardef2.split(";") : [];
    const pardef3Split = report.pardef3 ? report.pardef3.split(";") : [];
    const inputs = [];
    const checkboxes = [];
    const radios = [];
    // Name type parameters
    if (pardef1Split) {
      pardef1Split.forEach(function (value, i) {
        const eleSplit = value.split(",");
        if (eleSplit.length < 2) return;
        const type = eleSplit[1];
        let realtype = "text";
        let maxlen = 0;
        // Map Kayak types into Field tag types
        if (type.startsWith("CHAR")) {
          realtype = "text";
          maxlen = type.substr(4);
        }
        if (type.startsWith("NUMBER")) {
          realtype = "number"; // parse={value => !value ? null : Number(value)
          maxlen = type.substr(6);
        }
        if (type.startsWith("DATE")) {
          realtype = "datepicker";
          maxlen = 20;
        }
        inputs[i] = { name: eleSplit[0], type: realtype, maxlen: maxlen };
      });
    }
    // Checkboxes
    if (pardef2Split) {
      pardef2Split.forEach(function (value, i) {
        checkboxes[i] = value;
      });
    }
    // Radios
    if (pardef3Split) {
      pardef3Split.forEach(function (value, i) {
        radios[i] = value;
      });
    }

    this.setState({
      repno: report.repno,
      repname: report.repname,
      inputs: inputs,
      checkboxes: checkboxes,
      radios: radios,
      fields: [],
    });

    /*    if (this.state.reprequests === undefined || this.state.reprequests === null)
    {
      this._fetchReprequests();
    } */
  }

  handleChange(event) {
    const fields = this.state.fields || [];
    if (event.target.type === "number") {
      // fix: maxlength does not work with numbers
      const val = event.target.value;
      const maxLength = event.target.maxLength;
      const newVal = parseInt(val.toString().substring(0, maxLength));
      event.target.value = newVal;
    }
    // Make state variable from eleName
    fields[event.target.name] = event.target.value;

    this.setState({ fields: fields });
  }

  _createInputs(inputs) {
    const table = [];

    let i = 0;
    let children = [];
    inputs.forEach((element) => {
      if (i % 2 === 0) children = [];
      // Inner loop to create children
      // children.push(<Col>{ element.name }</Col>);
      children.push(
        <Col sm={5}>
          <Row style={{ paddingRight: "10px" }}>
            <Label style={{ marginBottom: 0 }}>{element.name}</Label>
            <Input
              type={element.type}
              value={this.state.fields[element.name] || ""}
              name={element.name}
              onChange={this.handleChange.bind(this)}
              maxLength={element.maxlen}
            />
          </Row>
          <Spacer />
        </Col>
      );
      // Create the parent and add the children
      if (i % 2 === 1) table.push(<Row>{children}</Row>);
      i++;
    });
    // Last "odd" row
    if (children.length === 1) table.push(<Row>{children}</Row>);
    return table;
  }

  _createCheckboxes(chks) {
    const table = [];

    // Outer loop to create parent
    chks.forEach((element) => {
      const children = [];
      // Inner loop to create children
      // Emulate text input like handleChange in checkbox
      children.push(
        <Row>
          <Input
            type="checkbox"
            checked={this.state.fields[element] || ""}
            name={element}
            onChange={(e) => {
              this.handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked,
                },
              });
            }}
          />
          <Spacer />
          <Label>{element}</Label>
        </Row>
      );
      // Create the parent and add the children
      table.push(<Row>{children}</Row>);
    });
    return table;
  }

  _createRadios(radios) {
    const table = [];

    // Outer loop to create parent
    radios.forEach((element) => {
      const children = [];
      // Inner loop to create children
      children.push(
        <Row>
          <Input
            type="radio"
            checked={this.state.fields.radio === element}
            name="radio"
            value={element}
            onChange={this.handleChange.bind(this)}
            maxLength={element.maxlen}
          />
          <Spacer />
          <Label>{element}</Label>
        </Row>
      );
      // Create the parent and add the children
      table.push(<Row>{children}</Row>);
    });
    return table;
  }

  handleSubmit(event) {
    alert("Who did submit? " + event);
    event.preventDefault();
  }

  handleClick(e) {
    e.preventDefault();
    let text = "";
    let repreq1 = "";
    let repreq2 = "";
    let repreq3 = "";
    this.state.inputs.forEach((element) => {
      text += element.name + "=" + this.state.fields[element.name] + " ";
      repreq1 += this.state.fields[element.name] + ";";
      text += "\r\n";
    });
    this.state.checkboxes.forEach((element) => {
      if (this.state[element]) {
        repreq2 += "Y;";
        text += element + "=[X] ";
        text += "\r\n";
      } else repreq2 += "N;";
    });
    let radiondx = 0;
    this.state.radios.forEach((element) => {
      radiondx++;
      if (this.state.fields.radio === element) {
        text += this.state.fields.radio + "(o)";
        text += "\r\n";
        if (radiondx < 10) repreq3 += "0" + radiondx;
        else repreq3 += radiondx;
      }
    });
    text += repreq1 + "\r\n";
    text += repreq2 + "\r\n";
    text += repreq3 + "\r\n";
    const fields = this.state.fields;
    fields.debugref = text;
    this.setState({ fields: fields });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <h2>{this.state.repname}</h2>
          </Col>
        </Row>
        <Row>
          <Spacer />
          <Spacer />
          <Col sm={6}>{this._createInputs(this.state.inputs)}</Col>
          <Col sm={2}>{this._createCheckboxes(this.state.checkboxes)}</Col>
          <Col sm={2}>{this._createRadios(this.state.radios)}</Col>
        </Row>
        <Spacer />
        <Row>
          <Col>
            <h2>Ajo ja Raporttipyynnöt</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Label>Käynnistysaika</Label>
          </Col>
          <Col sm={3}>
            <Input type="datepicker" name="start-date" />
          </Col>
        </Row>
        <Spacer />
        <Row>
          <Col>
            <h2>Raporttipyynnön uudistaminen</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={"form-inline"}>
            <Spacer />
            <Input
              type="radio"
              checked={this.state.fields["rep-renew"] === "1"}
              value="1"
              name="rep-renew"
              onChange={this.handleChange.bind(this)}
            />
            <Spacer />
            <Label>Uudistetaan päivän</Label>
          </Col>
          <Col sm={2}>
            <Input
              type={"number"}
              name="rep-renew-units"
              value={this.state.fields["rep-renew-units"]}
              onChange={this.handleChange.bind(this)}
              maxLength={2}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={"form-inline"}>
            <Spacer />
            <Input
              type="radio"
              checked={this.state.fields["rep-renew"] === "2"}
              value="2"
              name="rep-renew"
              onChange={this.handleChange.bind(this)}
            />
            <Spacer />
            <Label>Uudistetaan kk</Label>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={"form-inline"}>
            <Spacer />
            <Input
              type="radio"
              checked={this.state.fields["rep-renew"] === "3"}
              value="3"
              name="rep-renew"
              onChange={this.handleChange.bind(this)}
            />
            <Spacer />
            <Label>Käynnistä minuuttien päästä</Label>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={"form-inline"}>
            <Spacer />
            <Input
              type="checkbox"
              checked={this.state.fields["rep-renew-movedays"] || ""}
              name="rep-renew-movedays"
              onChange={(e) => {
                this.handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
            />
            <Spacer />
            <Label>Siirrä raportin pvm-rajaukset</Label>
          </Col>
        </Row>
        <Row>
          <Col xl={4}>
            <textarea
              rows={10}
              id="debugtxt"
              onChange={this.handleChange.bind(this)}
              name="debugref"
              value={this.state.fields.debugref}
            ></textarea>
          </Col>
        </Row>
        <Row>
          <Col className={"form-inline"} style={{ justifyContent: "flex-end" }}>
            <Button
              className={"submit-button"}
              color="secondary"
              onClick={this.handleClick.bind(this)}
            >
              Käynnistä myöhemmin
            </Button>
            <Spacer />
            <Button
              className={"submit-button"}
              color="primary"
              onClick={this.handleClick.bind(this)}
            >
              Käynnistä heti
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

ReportSheet.propTypes = {
  report: PropTypes.object,
};

export default ReportSheet;
