import React from "react";
import TransitionGroup from "react-transition-group/TransitionGroup";
import PropTypes from "prop-types";

import Notification from "./Notification.jsx";

import "../../assets/styles/notification.scss";

/*
 * Container for notifications which is always located at the top of the page.
 * Renders individual notifications which are given as a prop.
 */

const NotificationContainer = (props) => {
  const _renderNotifications = () => {
    return props.notifications.map((notification, index) => {
      return (
        <Notification
          key={notification.id}
          index={index}
          message={notification.message}
          type={notification.type}
          onDismiss={props.handleRemove.bind(this)}
        />
      );
    });
  };

  if (props.notifications.length === 0) {
    return null;
  }

  return (
    <div className="notification-container">
      <TransitionGroup>{_renderNotifications()}</TransitionGroup>
    </div>
  );
};

NotificationContainer.propTypes = {
  notifications: PropTypes.array.isRequired, // Notification objects in an array
  handleRemove: PropTypes.func.isRequired, // Function to call when unmounting a notification
};

export default NotificationContainer;
