import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import App from "./containers/App.jsx";
import { store, history } from "./store.js";
import Rollbar from "rollbar";

const rollbarAccessToken = "%ROLLBAR_ACCESS_TOKEN%";
const rollbarEnv = "%ROLLBAR_ENV%";
const rollbarCodeVersion = "%ROLLBAR_CODE_VERSION%";

window.Rollbar = new Rollbar({
  accessToken: rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: rollbarEnv,
    code_version: rollbarCodeVersion,
  },
});

if (rollbarAccessToken.charAt(0) === "%" || rollbarEnv.charAt(0) === "%") {
  window.Rollbar.configure({ enabled: false });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.querySelector("#app")
);
