/*
 * General functions which do something random
 */

const _sub = () => {
  return Math.floor(Math.random() * 0x10000).toString(16);
};

// Generate random uid to be used in React Components as keys.
// NOTE: Not based on any specification and randomness cannot be guaranteed.
// Should only be used in non-critical places.
const generateKey = () => {
  return `${_sub()}-${_sub()}-${_sub()}-${_sub()}`;
};

// Generate a random integer between 0 and MAX_SAFE_INTEGER (i.e. 9007199254740991)
const randomInt = () => {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
};

// fake async requests, for quick testing & development
const fakeRespond = async (duration = 3000) => {
  await new Promise((resolve) => setTimeout(resolve, duration));
};

export { generateKey, randomInt, fakeRespond };
