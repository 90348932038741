import * as actions from "../actions/actionTypes.js";
import { generateKey } from "../utils/random.js";

const notificationReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case actions.NEW_NOTIFICATION: {
      const items = state.items.slice();
      items.push({ message: action.message, type: action.messageType, id: generateKey() });

      return { ...state, items: items };
    }

    case actions.DISMISS_NOTIFICATION: {
      const newItems = state.items.slice();
      newItems.splice(action.index, 1);

      return { ...state, items: newItems };
    }

    default:
      return { ...state };
  }
};

export default notificationReducer;
