import * as actions from "./actionTypes.js";

const setTranslation = (langCode, translations) => {
  return { type: actions.SET_TRANSLATION, langCode: langCode, values: translations };
};

const switchTranslation = (langCode) => {
  return { type: actions.SWITCH_TRANSLATION, value: langCode };
};

const setAvailableTranslations = (items) => {
  return { type: actions.SET_AVAILABLE_TRANSLATIONS, value: items };
};

const setProcessing = (value) => {
  return { type: actions.PROCESSING, value: value };
};

const setTranslationDebugMode = (debugMode) => {
  return { type: actions.SET_TRANSLATION_DEBUG_MODE, value: debugMode };
};
export {
  setProcessing,
  setTranslation,
  switchTranslation,
  setAvailableTranslations,
  setTranslationDebugMode,
};
