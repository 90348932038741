import * as actions from "./actionTypes.js";

import apiUrl from "../utils/url.js";
import { fetchWithRetry } from "../utils/fetcher.js";
import { notify } from "../actions/notification.js";
import { tr } from "../utils/translation.js";

const setAuth = (data) => {
  return {
    type: actions.AUTHENTICATED,
    authenticated: data.authenticated,
    DENIED_MODULE_NAMES: data.denied_module_names,
    DENIED_PAPER_GROUPS: data.denied_paper_groups,
    DENIED_MODULES: data.denied_modules, // Build 3.6.4.1 (Issue #32668)
  };
};

const setUserInfo = (values) => {
  return { type: actions.SET_USER_INFO, properties: { ...values } };
};

const setUserSettings = (settings) => {
  return { type: actions.SET_USER_SETTINGS, settings };
};

const changePublisher = (publisher) => {
  return { type: actions.CHANGE_PUBLISHER, publisher };
};

const setPublishers = (publishers, current) => {
  return { type: actions.SET_PUBLISHERS, publishers, current };
};

const setTableColumnVisibility = (tableName, hiddenColumns) => {
  return { type: actions.SET_TABLE_COLUMN_VISIBILITY, tableName, hiddenColumns };
};

const getUserDefaults = () => (dispatch) => {
  const url = apiUrl("/defaults");

  fetchWithRetry("getJson", [url])
    .then((defaults = {}) => {
      dispatch(setUserDefaults(defaults));
    })
    .catch(() => {
      dispatch(notify(tr("dbConnectFailure"), "error"));
    });
};

const setUserDefaults = (defaults) => {
  return { type: actions.SET_USER_DEFAULTS, defaults };
};

// Build 3.6.2.2 (Issue #31348)
const getParrowUserSettings = (user) => (dispatch) => {
  const url = apiUrl("/parameters/USER_SETTINGS/ALL");
  fetchWithRetry("getJson", [url])
    .then((response) => {
      dispatch(setParrowUserSettings(response, user));
    })
    .catch(() => {
      dispatch(notify(tr("dbConnectFailure"), "error"));
      dispatch(setParrowUserSettings(undefined, user));
    });
};

// Build 3.6.2.2 (Issue #31348)
const setParrowUserSettings = (data, user) => {
  const parrowUserSettings = { invoiceLimit: 60 };
  if (data) {
    data.forEach((item) => {
      if (item.codeno === user) {
        const temp = item.codevalue.split(";");
        if (temp.length >= 3) parrowUserSettings.invoiceLimit = parseInt(temp[2]);
      }
    });
  }
  return { type: actions.SET_PARROW_USER_SETTINGS, parrowUserSettings };
};

export {
  setAuth,
  setUserInfo,
  setUserSettings,
  changePublisher,
  setPublishers,
  setTableColumnVisibility,
  getUserDefaults,
  getParrowUserSettings,
  setParrowUserSettings,
};
