import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as translation from "../utils/translation.js";

import Dropdown from "../components/Dropdown.jsx";

/*
 * LanguageSelection renders a dropdown with available translations as items.
 * It uses Redux store and translation utilities to get available translation and
 * to initiate language change event.
 *
 * Usage:
 * <LanguageSelection />
 */
class LanguageSelection extends React.Component {
  _languageChange(language) {
    const code = this._languageCode(language);
    if (this.props.current !== code) {
      translation.switchTranslation(code);
    }
  }

  _languageCode(language) {
    for (const code in this.props.translations) {
      if (this.props.translations[code] === language) {
        return code;
      }
    }
  }

  render() {
    return (
      <Dropdown
        className="language-selection"
        sendSelection={this._languageChange.bind(this)}
        items={this.props.localized}
        placeholder={this.props.translations[this.props.current]}
      />
    );
  }
}

LanguageSelection.propTypes = {
  current: PropTypes.string.isRequired, // Currently selected language code
  translations: PropTypes.object.isRequired, // Object containing languace codes and localized names
  localized: PropTypes.array.isRequired, // Array containing localized names
};

const mapStateToProps = (state) => {
  const current = state.translation.current;
  const translations = state.translation.available;
  const localized = Object.keys(translations)
    .sort()
    .map((langCode) => {
      return translations[langCode];
    });

  return { translations, localized, current };
};

export default connect(mapStateToProps)(LanguageSelection);
