import * as actions from "../actions/actionTypes.js";

const defaultState = {
  locales: [],
  current: "",
};

const localeReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_AVAILABLE_LOCALES:
      return { ...state, locales: action.locales };

    case actions.SET_LOCALE:
      return { ...state, current: action.current };

    default:
      return { ...state };
  }
};

export default localeReducer;
