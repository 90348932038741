import XRegExp from "xregexp";
import apiUrl from "./url.js";
import { Fetcher } from "./fetcher.js";
import { store } from "../store.js";

/*
 * This is a best-effort email address validation and is NOT FOOLPROOF.
 * There are multiple RFCs describing email address syntax and limitations which
 * are quite complex. This doesn't try to follow any of them properly. It checks
 * that the address 'looks' ok enough and further validations are left to the
 * email sending logic on the server side.
 */
const emailPattern = "(\\p{Ll}|[0-9.!#$%&*+-/=?^_`{|}~<])+@(\\p{Ll}|[0-9.-])+[.]\\p{Ll}+";
export const emailRe = XRegExp(emailPattern, "i");
export const emailReEnclosed = XRegExp(`(<${emailPattern}>|(^${emailPattern}$))`, "i");

export const looksLikeEmail = (input) => {
  return emailRe.test(input);
};

/**
 * Check if input is valid number. NaN is considered not to be valid number.
 * Input can have exactly one (1) decimal separator, and decimal separator
 * can either be dot (.) or comma (,). Also empty input ('') is considered valid
 * to allow user to remove previously entered input.
 * @param {(string|number)} input - input can be either string or number
 */
export const isValidNumber = (input) => {
  return /^$|^(?=.*\d)\d*[.,]?\d*$/g.test(input);
};

/**
 * Check that the given value is a valid integer, i.e. only character 0-9 allowed.
 * Considers empty string, i.e. '', as a valid integer.
 */
export const isValidInt = (input) => {
  return /^\d*$/g.test(input);
};

/**
 * Removes all dots or commas from the string. Useful for
 * integer number sanitation
 * @param {string} input
 */
export const removeDotsAndCommas = (input) => {
  return input.replace(/\.|,/g, "");
};

/**
 * Changes decimal comma ',' to decimal dot '.'.
 * @param {string} input
 */
export const useDecimalDot = (input) => {
  return input.replace(/,/, ".");
};

/**
 * Check that input meets specified minimum length
 * @param {number} min - minimum number of characters
 */
export const minLength = (min) => (input) => {
  // Base pattern where x will be replaced with min
  const baseRegex = "^(.){x,}$".replace(/x/, min);

  // Actual pattern to test length
  const regex = new RegExp(baseRegex, "g");

  return regex.test(input) || input === undefined;
};

/**
 * Check that input meets specified maximum length
 * @param {number} max - maximum number of characters
 */
export const maxLength = (max) => (input) => {
  // Base pattern where x will be replaced with max
  const baseRegex = "^$|^.{0,x}$".replace(/x/, max);

  // Actual pattern to test length
  const regex = new RegExp(baseRegex, "g");

  return regex.test(input) || input === undefined;
};

/**
 * Checks that password meets certain character criteria before it is considered valid.
 * Criteria includes:
 * 1) Min 1 uppercase letter, whitelist: any uppercase letter that has a lowercase variant
 * 2) Min 1 lowercase letter, whitelist: any lowercase letter that has an uppercase variant
 * 3) Min 1 special character, whitelist: ! # $ % & * + , - . : ; = ? @ ^ _ ~
 * @param {string} password
 */
export const validatePasswordCharacters = (password) => {
  // building regex with XRegExp
  const regex = new XRegExp(
    "^(?=.*[\\p{Ll}])(?=.*[\\p{Lu}])(?=.*[!#$%&*+,\\-.:;=?@^_~])[\\p{Ll}\\p{Lu}\\d!#$%&*+,\\-.:;=?@^_~]{0,}$",
    "g"
  );

  return regex.test(password);
};

/**
 * Checks that given time input is in correct format (hh:mm).
 * @param {string} time
 */
export const isValidTime = (time) => {
  return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g.test(time);
};

/**
 * Formats currency to user friendly format:
 * 1) Adds two 00 after decimal point
 * 2) Updates decimal point to decimal comma
 * @param {string} rawCurrency
 */
export const formatCurrency = (rawCurrency) => {
  // Added isNaN Issue #29126
  if (rawCurrency === "" || rawCurrency === undefined) {
    // || isNaN(rawCurrency)){
    return "0,00";
  } else {
    const currencyString = parseFloat(rawCurrency).toFixed(2);
    const formattedCurrency = currencyString.replace(/\./g, ",");

    return formattedCurrency;
  }
};

/** #31655
 * Formats currency to user friendly format:
 * 1) Adds two 00 after decimal point
 * 2) Show empty if value is 0
 * @param {string} rawCurrency
 */
export const formatCurrencyWithZeroEmpty = (rawCurrency) => {
  if (rawCurrency === "" || rawCurrency === undefined || parseFloat(rawCurrency) === 0) {
    // || isNaN(rawCurrency)){
    return "";
  } else {
    // price string should be converted to float first since it may contain ,
    const currencyString = apiCurrency(rawCurrency).toFixed(2);
    const formattedCurrency = currencyString.replace(/\./g, ",");

    return formattedCurrency;
  }
};

/**
 * Formats currency to API friendly format:
 * 1) Update decimal comma to decimal point
 * 2) Return decimal number
 * @param {(string|number)} currency
 */
export const apiCurrency = (currency) => {
  if (currency === "" || currency === undefined) {
    return 0;
  } else {
    const currencyWithPoint = currency.toString().replace(/,/g, ".");
    const apiCurrency = parseFloat(currencyWithPoint);

    return apiCurrency;
  }
};

export const apiCurrencyFixed = (currency, float = 2) => {
  // #20110
  if (currency === "" || currency === undefined) {
    return 0;
  } else {
    const currencyWithPoint = currency.toString().replace(/,/g, ".");
    const apiCurrency = parseFloat(parseFloat(currencyWithPoint).toFixed(float));

    return apiCurrency;
  }
};

export const parseIntWithEmpty = (num) => {
  // #20110
  if (num === "" || num == null || num === undefined) {
    return 0;
  } else {
    return parseInt(num);
  }
};

export const parseFloatWithEmpty = (num) => {
  // #21979
  if (num === "" || num == null || num === undefined) {
    return 0;
  } else {
    return parseFloat(num);
  }
};

/**
 * Async iban validator that should be given to redux form.
 * @param {string} iban
 * @param {object} error - error note displayed in the form e.g. { field: 'error message' }
 */
export const asyncValidateIban = (iban, error) => {
  // If iban is empty consider it to be valid
  if (iban === "") {
    return new Promise((resolve) => {
      resolve();
    });
  }

  const url = apiUrl(`/customers/iban/validate?iban=${iban}`);
  return Fetcher.post(url)
    .then(() => {
      // Async validation ok
    })
    .catch(() => {
      throw error;
    });
};

/**
 * Async social security number validator that should be given to redux form.
 * @param {string} socialsecno
 * @param {string} custype
 * @param {object} error  - error note displayed in the form e.g. { field: 'error message' }
 */
export const asyncValidateSocialSecno = (socialsecno, custype, error) => {
  // If socialsecno is empty consider it to be valid
  if (socialsecno === "" || !socialsecno) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  // SOCIALSECNO parameter controls whether any validation should be used
  const SOCIALSECNO = store.getState().parameter.SOCIALSECNO;

  if (SOCIALSECNO.ALL.codevalue === "Y") {
    const url = apiUrl("customers/social-secno/validate");
    const body = {
      socialsecno: socialsecno,
      custype: custype,
    };

    return Fetcher.postJson(url, body)
      .then((response) => {
        if (!response.valid) {
          throw error;
        }
      })
      .catch(() => {
        throw error;
      });
  } else {
    // When validation is skipped, just return promise which redux form expects
    return new Promise((resolve) => {
      resolve();
    });
  }
};

/**
 * Sorts array of objects
 * @param {array} objects - array of objects to be sorted
 * @param {string} key - key that is used for sorting
 * @param {boolean} reverse - optionally sort in reverse
 * @param {boolean} sortAsNumbers - optionally sort values as numbers
 */
export const sortObjects = (objects, key, reverse = false, sortAsNumbers = false) => {
  // Create new array
  const sortedObjects = [].concat(objects);

  // Check if objects should be sorted as numbers
  if (sortAsNumbers) {
    // Sort new array using sort() method
    sortedObjects.sort((a, b) => {
      let result;
      if (parseFloat(a[key]) < parseFloat(b[key])) {
        result = -1;
      } else if (parseFloat(a[key]) > parseFloat(b[key])) {
        result = 1;
      } else {
        result = 0;
      }

      if (reverse) {
        return result * -1;
      }
      return result;
    });
  } else {
    // Sort new array using sort() method
    sortedObjects.sort((a, b) => {
      let result;
      if (a[key] < b[key]) {
        result = -1;
      } else if (a[key] > b[key]) {
        result = 1;
      } else {
        result = 0;
      }

      if (reverse) {
        return result * -1;
      }
      return result;
    });
  }

  // Return sorted objects
  return sortedObjects;
};

/**
 * Optional sort function that can be given to table columns as props
 */
export const sortNumbers = (a, b) => {
  if (a.length === b.length) {
    return a > b ? 1 : -1;
  }
  return a.length > b.length ? 1 : -1;
};
