import { fetchWithRetry } from "../utils/fetcher.js";
import apiUrl from "../utils/url.js";
import { store } from "../store.js";
import * as actions from "./actionTypes.js";
import { SUBSKIND_ALL } from "../utils/arrays.js";

export const getSystemParameters = () => (dispatch) => {
  const url = apiUrl("parameters");

  fetchWithRetry("getJson", [url]).then((response) => {
    dispatch(setSystemParameters(response));
  });
};

export const setSystemParameters = (data) => {
  return { type: actions.SET_SYSTEM_PARAMETERS, data };
};

export const getSourceGroups = () => (dispatch) => {
  const currentSourceGroups = store.getState().parameter.SOURCE_GROUPS;

  if (Object.keys(currentSourceGroups).length === 0) {
    const url = apiUrl("parameters/SOURCEGROUPS/ALL");

    fetchWithRetry("getJson", [url]).then((sourceGroups) => {
      // Construct object where 'codeno' is key and 'codevalue' is value for each sourcegroup
      const groups = {};
      sourceGroups.forEach((group) => {
        groups[group.codeno] = group.codeno + " - " + group.codevalue;
      });
      dispatch(setSourceGroups(groups));
    });
  }
};

const setSourceGroups = (sourceGroups) => {
  return { type: actions.SET_SOURCE_GROUPS, sourceGroups };
};

export const getContactTypes = () => (dispatch) => {
  const currentContactTypes = store.getState().parameter.CONTACT_TYPES;

  if (Object.keys(currentContactTypes).length === 0) {
    const url = apiUrl("parameters/CONTACTTYPE/ALL");

    fetchWithRetry("getJson", [url]).then((contactTypes) => {
      const types = {};
      contactTypes.forEach((type) => {
        types[type.codeno] = type.codeno + " - " + type.codevalue;
      });
      dispatch(setContactTypes(types));
    });
  }
};

const setContactTypes = (contactTypes) => {
  return { type: actions.SET_CONTACT_TYPES, contactTypes };
};

export const getPhoneCheckSources = () => (dispatch) => {
  const currentPhoneCheckSources = store.getState().parameter.PHONECHECK_SOURCES;

  if (Object.keys(currentPhoneCheckSources).length === 0) {
    const url = apiUrl("parameters/PHONECHECKSOURCES/ALL");

    fetchWithRetry("getJson", [url]).then((phoneCheckSources) => {
      const sources = {};
      phoneCheckSources.forEach((source) => {
        sources[source.codeno] = source.codeno + " - " + source.codevalue;
      });
      dispatch(setPhoneCheckSources(sources));
    });
  }
};

export const getPhoneStatus = () => (dispatch) => {
  //    dispatch(setPhoneStatus({}));
  //    return;
  const currentPhoneStatus = store.getState().parameter.PHONE_STATUS;

  if (Object.keys(currentPhoneStatus).length === 0) {
    const url = apiUrl("parameters/CONTACT_STATUS/ALL");

    fetchWithRetry("getJson", [url]).then((phoneStatus) => {
      const sources = {};
      phoneStatus.forEach((source) => {
        sources[source.codeno] = source.codeno + " - " + source.codevalue;
      });
      dispatch(setPhoneStatus(sources));
    });
  }
};

const setPhoneCheckSources = (phoneCheckSources) => {
  return { type: actions.SET_PHONECHECK_SOURCES, phoneCheckSources };
};

const setPhoneStatus = (phoneStatus) => {
  return { type: actions.SET_PHONE_STATUS, phoneStatus };
};

export const getCustomerLanguages = () => (dispatch) => {
  const currentCustomerLanguages = store.getState().parameter.CUSTOMER_LANGUAGES;

  if (Object.keys(currentCustomerLanguages).length === 0) {
    const url = apiUrl("parameters/LANGUAGES/ALL");

    fetchWithRetry("getJson", [url]).then((availableLanguages) => {
      const languages = {};
      availableLanguages.forEach((language) => {
        languages[language.codeno] = language.codeno + " - " + language.codevalue;
      });
      dispatch(setCustomerLanguages(languages));
    });
  }
};

const setCustomerLanguages = (languages) => {
  return { type: actions.SET_CUSTOMER_LANGUAGES, languages };
};

export const getReturnChannels = () => (dispatch) => {
  const currentReturnChannels = store.getState().parameter.RETCHANNEL;

  if (Object.keys(currentReturnChannels).length === 0) {
    const url = apiUrl("parameters/RETCHANNEL/ALL");

    fetchWithRetry("getJson", [url]).then((availableReturnChannels) => {
      const returnChannels = {};
      availableReturnChannels.forEach((returnChannel) => {
        returnChannels[returnChannel.codeno] =
          returnChannel.codeno + " - " + returnChannel.codevalue;
      });
      dispatch(setReturnChannels(returnChannels));
    });
  }
};

const setReturnChannels = (returnChannels) => {
  return { type: actions.SET_RETURNCHANNELS, returnChannels };
};

export const getCaiParameters = () => (dispatch) => {
  const currentCaiParameters = store.getState().parameter.CAI;

  if (Object.keys(currentCaiParameters).length === 0) {
    const url = apiUrl("parameters/CAI/ALL");

    fetchWithRetry("getJson", [url]).then((caiParameters) => {
      // Since CAI group includes mix of four different 'sub groups',
      // parse them and assign them to same object based on codeno
      let CAI = {};
      for (const key of Object.keys(caiParameters)) {
        const newKey = caiParameters[key].codeno.substr(0, 6); // key for each dropdown CAI_KL, CAI_MK etc.
        const values = caiParameters[key].codevalue.split(/,/, 2); // parse id & name from codevalue
        const id = values[0];
        const name = values[1];

        // Finally add newly parsed parameter to same parameter object
        CAI = {
          ...CAI,
          [newKey]: {
            ...CAI[newKey],
            [id]: {
              id: id,
              name: id + " - " + name,
            },
          },
        };
      }
      dispatch(setCaiParameters(CAI));
    });
  }
};

const setCaiParameters = (CAI) => {
  return { type: actions.SET_CAI_PARAMETERS, CAI };
};

export const getSourceMaterials = () => (dispatch) => {
  const currentSourceMaterials = store.getState().parameter.SOURCEMATERIALS;

  if (Object.keys(currentSourceMaterials).length === 0) {
    const url = apiUrl("parameters/SOURCEMATERIALS/ALL");

    fetchWithRetry("getJson", [url]).then((sourceMaterialsArray) => {
      const sourceMaterialsObject = {};
      sourceMaterialsArray.forEach((sourceMaterial) => {
        sourceMaterialsObject[sourceMaterial.codeno] = { ...sourceMaterial };
      });
      dispatch(setSourceMaterials(sourceMaterialsObject));
    });
  }
};

const setSourceMaterials = (SOURCEMATERIALS) => {
  return { type: actions.SET_SOURCEMATERIALS, SOURCEMATERIALS };
};

export const getCommitmentPeriods = () => (dispatch) => {
  const currentCommitmentPeriods = store.getState().parameter.COMMITMENTPERIODS;

  if (Object.keys(currentCommitmentPeriods).length === 0) {
    const url = apiUrl("parameters/COMMITMENTPERIOD/ALL");

    fetchWithRetry("getJson", [url]).then((commitmentPeriodsArray) => {
      // WS returns array of objects. Construct object where codeno is key for every entry
      const commitmentPeriods = {};
      if (commitmentPeriodsArray.length > 0) {
        commitmentPeriodsArray.forEach((period) => {
          commitmentPeriods[period.codeno] = { ...period };
        });
      }
      dispatch(setCommitmentPeriods(commitmentPeriods));
    });
  }
};

const setCommitmentPeriods = (commitmentPeriods) => {
  return { type: actions.SET_COMMITMENTPERIODS, commitmentPeriods };
};

export const getPaymentCodes = () => (dispatch) => {
  const currentPaymentCodes = store.getState().parameter.PAYMCODE;

  if (Object.keys(currentPaymentCodes).length === 0) {
    const url = apiUrl("parameters/PAYMCODE/ALL");

    fetchWithRetry("getJson", [url]).then((paymentCodes) => {
      const PAYMCODE = {};
      if (paymentCodes.length > 0) {
        paymentCodes.forEach((code) => {
          PAYMCODE[code.papercode] = { ...PAYMCODE[code.papercode], [code.codeno]: code.codevalue };
        });
      }
      dispatch(setPaymCode(PAYMCODE));
    });
  }
};

const setPaymCode = (PAYMCODE) => {
  return { type: actions.SET_PAYMCODE, PAYMCODE };
};

// #32663, use parrow for rettype radiobox ...
export const getRetTypes = () => (dispatch) => {
  const currentReturnRetTypes = store.getState().parameter.RETTYPE;

  if (Object.keys(currentReturnRetTypes).length === 0) {
    const url = apiUrl("parameters/RETTYPE/ALL");

    fetchWithRetry("getJson", [url]).then((rettypes) => {
      const returnRetTypes = {};
      rettypes.forEach((rettype) => {
        returnRetTypes[rettype.codeno] = rettype.codevalue;
      });
      dispatch(setRetTypes(returnRetTypes));
    });
  }
};

const setRetTypes = (returnRetTypes) => {
  return { type: actions.SET_RETTYPE, returnRetTypes };
};
// ... #32663, use parrow for rettype radiobox

// Build 3.6.4.2 (ERP #11897) ...
export const getSubscriptionTypes = () => (dispatch) => {
  const subsTypesCustomer = store.getState().customer.subscriptionTypes;
  const subsTypesSubs = store.getState().customer.subscription.subscriptionTypes;
  const subsTypes = store.getState().parameter.SUBSKIND.ALL;

  if ((subsTypesCustomer.length <= 0 || subsTypesSubs.length <= 0) &&
      Object.keys(subsTypes).length > 0) {
    const subskinds = [];
    Object.keys(subsTypes).forEach((key) => {
      if (SUBSKIND_ALL.includes(key)) {
        const subskind = {
          codeno: key,
          codevalue: subsTypes[key],
        }
        subskinds.push(subskind);
      }
    });
    dispatch(loadSubscriptionTypes(subskinds));
  }
};

const loadSubscriptionTypes = (payload) => {
  return {
    type: actions.LOAD_SUBSCRIPTION_TYPES,
    payload,
  };
};
// ... Build 3.6.4.2 (ERP #11897)