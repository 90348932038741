import React from "react";
import { connect } from "react-redux";
import { reduxForm, change, SubmissionError } from "redux-form";
import PropTypes from "prop-types";

import { Fetcher } from "../utils/fetcher.js";
import apiUrl from "../utils/url.js";
import { tr } from "../utils/translation.js";
import { looksLikeEmail, emailReEnclosed } from "../utils/validate.js";
import { clearEmail } from "../actions/email.js";
import { notify } from "../actions/notification.js";

import Email from "../components/Email.jsx";

const validateEmails = (string) => {
  for (const part of string.split(",")) {
    if (!emailReEnclosed.test(part.trim())) {
      return false;
    }
  }
  return true;
};

const validate = (values) => {
  const errors = {};

  ["recipients", "subject", "body"].forEach((key) => {
    if (!values[key]) {
      errors[key] = tr("required");
    }
  });

  if (values.recipients && !validateEmails(values.recipients)) {
    errors.recipients = tr("invalidEmailAddress");
  }

  return errors;
};

// reduxForm wrapper for Email form component
const EmailForm = reduxForm({
  form: "email",
  validate,
})((props) => <Email {...props} />);

/*
 * Generic Email modal view with custom file upload functionality.
 *
 * Email modal can be activated with email action 'newEmail'. The action can
 * be given recipients, subject and a body. Explanation of variables below:
 *
 * recipients - a string or an array of strings. Userid or email address accepted.
 * subject - a string (email's title)
 * body - a string or an array of strings (email's body). Array value will be joined together.
 */
class EmailContainer extends React.Component {
  _sendEmail(values) {
    const url = apiUrl("mail/generic", false);

    const formData = new FormData();
    this._parseRecipients(values.recipients).forEach((recipient) => {
      formData.append("recipients[]", recipient);
    });
    formData.append("subject", values.subject);
    formData.append("body", values.body);
    for (const fl of this.state.files) {
      formData.append("attachments[]", fl, fl.name);
    }

    return Fetcher.postFormData(url, formData)
      .then(() => {
        this.props.notify(tr("emailSentSuccessfully"), "success");
        this.props.clearEmail();
      })
      .catch((error) => {
        this.props.notify(tr("failedToSendEmail"), "error");
        throw new SubmissionError(error);
      });
  }

  _parseRecipients(string) {
    return string.split(",").map((part) => {
      return part.trim();
    });
  }

  // Clear email related data and close the modal
  _closeRequest() {
    this.setState({ loading: false, files: [] });
    this.props.clearEmail();
    this._clearFileUploadElement();
  }

  // Clear file input field value because it's state can hinder
  // the customized file upload functionality.
  _clearFileUploadElement() {
    this.fileUploadElement.value = "";
  }

  // Save a reference to the file upload input element. This input is hidden.
  _fileUploadRef(elem) {
    this.fileUploadElement = elem;
  }

  // Normal button with onClick listener. Triggers the hidden file upload input.
  _fileUploadButtonClick() {
    this.fileUploadElement.click();
  }

  _fileRemove(ev) {
    if (this.state.files.length === 0) {
      return;
    }

    const index = ev.target.getAttribute("data-id");
    const newFiles = Array.from(this.state.files);
    newFiles.splice(index, 1);
    this.setState({ files: newFiles });

    this._clearFileUploadElement();
  }

  // File upload input is closed. Handle selected files.
  _fileUpload(ev) {
    const newFiles = Array.from(this.state.files);
    for (const file of ev.target.files) {
      newFiles.push(file);
    }
    this.setState({ files: newFiles });

    this._clearFileUploadElement();
  }

  _getRecipients(recipients) {
    const addresses = [];
    let total = recipients.length;
    const progress = () => {
      total -= 1;
      if (total <= 0) {
        const formatted = addresses.join(", ");
        this.props.change("email", "recipients", formatted);
        this.setState({ loading: false });
      }
    };

    if (recipients.length === 0) {
      this.setState({ loading: false });
      return;
    }

    for (const recipient of recipients) {
      if (looksLikeEmail(recipient)) {
        addresses.push(recipient);
        progress();
        continue;
      }

      const url = apiUrl(`users/properties/${recipient}`, false);
      Fetcher.getJson(url)
        .then((response) => {
          addresses.push(`${response.username} <${response.emailaddress}>`);
          progress();
        })
        .catch((error) => {
          if (error.status === 404) {
            progress();
          }
        });
    }
  }

  constructor(props) {
    super(props);

    this._sendEmail = this._sendEmail.bind(this);
    this._fileUpload = this._fileUpload.bind(this);
    this._fileUploadRef = this._fileUploadRef.bind(this);
    this._fileUploadButtonClick = this._fileUploadButtonClick.bind(this);
    this._fileRemove = this._fileRemove.bind(this);
    this._closeRequest = this._closeRequest.bind(this);

    this.fileUploadElement = {};
    this.state = {
      loading: false,
      files: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.active && !prevProps.active) {
      this.props.change("email", "subject", this.props.subject);
      this.props.change("email", "body", this.props.body);
      this.setState({ loading: true });
      this._getRecipients(this.props.recipients);
    }
  }

  render() {
    return (
      <EmailForm
        isOpen={this.props.active}
        loading={this.state.loading}
        onSubmit={this._sendEmail}
        fileList={this.state.files}
        onFileUpload={this._fileUpload}
        onFileUploadButtonClick={this._fileUploadButtonClick}
        fileUploadRef={this._fileUploadRef}
        onFileRemove={this._fileRemove}
        closeRequest={this._closeRequest}
      />
    );
  }
}

EmailContainer.propTypes = {
  // state
  active: PropTypes.bool.isRequired,
  recipients: PropTypes.array.isRequired, // Array of userIds
  subject: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired, // Array of strings which make up a body
  // actions
  change: PropTypes.func,
  notify: PropTypes.func,
  clearEmail: PropTypes.func,
};

const mapStateToProps = (state) => {
  const email = state.email;

  return {
    active: email.active,
    recipients: email.recipients,
    subject: email.subject,
    body: email.body,
  };
};

const actionCreators = {
  change,
  notify,
  clearEmail,
};

export default connect(mapStateToProps, actionCreators)(EmailContainer);
