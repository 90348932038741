import { connect } from "react-redux";

import { dismiss } from "../actions/notification.js";

import NotificationContainer from "../components/notification/NotificationContainer.jsx";

/*
 * Global notification container. Listens to Redux store notification events and
 * displays notification at the top of the page.
 *
 * A notification has a content string and a type which affects the notification's
 * visual style.
 *
 * Notification format:
 *   message: 'message content string'
 *   type: a string from ['warning', 'error', 'success']
 *
 * Showing a notification is done by using the 'notify' action:
 *   store.dispatch(notify(message, type));
 *
 * Component usage (should only be used once in App.jsx):
 *   <GlobalNotification />
 */

const mapStateToProps = (state) => {
  return { notifications: state.notification.items || [] };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleRemove: (i) => {
      dispatch(dismiss(i));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
