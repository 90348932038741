import * as actions from "../actions/actionTypes.js";

// SLEEPREASON should not be retrieved from the system parameters. It's deprecated,
// use the ws method GetSleepReasons instead.

const defaultState = Object.freeze({
  RCVTYPE: {}, // Receive type used in feedbacks
  RECLAIMPAPER: {}, // Compensation copy options used in feedbacks
  STDMESG: {}, // Standard message
  INVMODE: {}, // Invoice modes (e-bill, direct debit etc.)
  GRANTER: {}, // Free subscription granter
  UNIT: {}, // Free subscription unit
  DEPT: {}, // Free subscription department
  INVTYPE: {}, // Possible invoice types
  INVSTATE: {}, // Possible invoice states
  INVCLASS: {}, // Different invoice classifications
  PAYMCODE: {}, // Different payment types (in ledger)
  OFFICES: {}, // Available bank offices
  DEFRECLITEM: {}, // Default feedback hierarchy selection
  RECLITEMDELIV: {},
  DENCHR: {}, // Forbidden characters in feedback message,
  DEFAULT_LANGUAGE: {}, // System's default language
  CURRENCY: {}, // System's default currency
  CURRENCIES: {}, // Currency selection in new customer
  TITLES: {}, // Title names given to customers e.g. 'Doctor'
  TITLE_GREETING: {}, // Greeting for each title
  SCND_TITLES: {}, // Other title that can be given to customer
  SOURCE_GROUPS: {}, // Source groups tell where certain data has come
  CONTACT_TYPES: {}, // Possible contact types for customer (phone, email etc.)
  PHONECHECK_SOURCES: {}, // Source where certain CONTACT_TYPE came (customer service etc.)
  PAYTERM: {}, // Payment terms (length til due date)
  CUSTOMER_LANGUAGES: {}, // Available languages that can be given to customer
  RETCHANNEL: {}, // Available return channels (Postgiro, Bankgiro etc.)
  CUSCATEGORY: {}, // Different categories/classifications that can be given to customer
  CUS_XTRAFIELDS: {}, // Labels for xtra01, xtra02 & xtra03 fields in CustomerModal
  CAI: {}, // Different dropdown values for singlesubs customer
  SUBS_XTRAFIELDS: {}, // Different labels for fields found in subscription modal
  OVT_OPERATOR: {}, // Different options for SWIFT field
  SOURCEMATERIALS: {}, // Different source address materials displayed in New subscription
  CAMPAIGNCHNL: {}, // Options for campaign channels found in New subscription
  INVFEE_FOR_CANCELLED: {}, // Optional fees given to customer when cancelling subscription
  COMMITMENTPERIODS: {}, // Available commitment period lengths used in subscription modal
  ALLOW_WEBPAPER: { ALL: { codevalue: "N" } }, // Automatically selectes checkbox in sleep modal
  ASKPRINTINV: { ALL: { codevalue: "N" } }, // Asks about printing immediate invoice once created
  CREDITANDCANCEL: { ALL: { codevalue: "N" } }, // Enable credit + cancel functionality
  DOORNOTES: { ALL: { codevalue: "Y" } }, // Enable doornotes in week calendar
  DEFCOLLINV: { ALL: { codevalue: "N" } }, // If customer has collinv by default
  EMAILMANDATORY: { ALL: { codevalue: "N" } }, // If email is required for new customer
  CANCEL_RECEIVETYPE: { ALL: { codevalue: "N" } }, // If receive channel is visible in cancel subs
  CANCELREASONGRP: { ALL: { codevalue: "N" } }, // If cancel reason group is visible in cancel subs
  EARLIEST_INVDATE: { ALL: { codevalue: "N" } }, // Display earliest invdate field in customer modal
  CAMPAIGNCHNL_MANDATORY: { ALL: { codevalue: "N" } }, // If campaign channel is required in new subs
  DISPLAYNOTES: { ALL: { codevalue: "Y" } }, // Display customer related notes box in customer summary
  NAME3_VISIBLE: { ALL: { codevalue: "Y" } }, // Display name 3 field in customer modal
  INVMODE_ADD_TO_ALL_SUBS: { ALL: { codevalue: "N" } }, // Allow updating invmode to existing subs when adding new mode
  NEWSUBS_PRICE_GAR: { ALL: { codevalue: "N" } }, // Sets price guarantee when creating new subs
  RECOM_SUBSTYPE_TO_SUBS: { ALL: { codevalue: "N" } }, // Recommender's discount is set to new subs
  CLASS1_KEEP: { ALL: { codevalue: "N" } }, // Criter 1 populated from current subs in Renewal
  CLASS2_KEEP: { ALL: { codevalue: "N" } }, // Criter 2 populated from current subs in Renewal
  CLASS3_KEEP: { ALL: { codevalue: "N" } }, // Criter 3 populated from current subs in Renewal
  CLASS4_KEEP: { ALL: { codevalue: "N" } }, // Criter 4 populated from current subs in Renewal
  CLASS5_KEEP: { ALL: { codevalue: "N" } }, // Criter 5 populated from current subs in Renewal
  CLASS6_KEEP: { ALL: { codevalue: "N" } }, // Criter 6 populated from current subs in Renewal
  REMPRINTMARK_LINK: { ALL: { codevalue: "Y" } }, // Link to remove printing mark in Ledger
  INHERITSALESNO: { ALL: { codevalue: "N" } }, // Inherits salesno from old subs when creating renewal
  GIFTSUBSCRIPTION: { ALL: { codevalue: "N" } }, // Enables 'gift subscription' checkbox in new subs
  SLEEPENDRESTRICTED: { ALL: { codevalue: "N" } }, // Disable 'sleep end date undefined' checkbox
  FREE_DEPT: { ALL: { codevalue: "N" } }, // If department is required for free subs
  FREE_GRANT: { ALL: { codevalue: "N" } }, // If granter is required for free subs
  FREE_GROUP: { ALL: { codevalue: "N" } }, // If group is required for free subs
  FREE_UNIT: { ALL: { codevalue: "N" } }, // If unit is required for free subs
  ADDRMATCODE_TO_CRITER1: { ALL: { codevalue: "N" } }, // Updates address material to criter 1 in renewal
  ALLOWPAYER: { ALL: { codevalue: "N" } }, // Allow customer to be payer even when he/she has unpaid payments
  DOMESTIC_COUNTRY: { ALL: { codevalue: "" } }, // Country code displayed in address forms
  EVENT_WITH_SUBSNO: { ALL: { codevalue: "N" } }, // Display subsno & extno combo by default in event modal
  RECEIVETYPEINCHANGES: { ALL: { codevalue: "" } }, // Display receive channel dropdown in address & sleep modals
  CHECK_UNPAIDSUBS: { ALL: { codevalue: "N" } }, // Checks unpaid subs for customer's address
  SHOW_INVINTERVAL: { ALL: { codevalue: "N" } }, // Show/hide invoice interval in new subscription modal
  TARG_GROUP: { ALL: { codevalue: "N" } }, // Make target group dropdown as required when campaign selected in new subs
  FORCEIESEP: { ALL: { codevalue: "N" } }, // For extra product order set 'separate invoice' as true by default
  ORDERID: { ALL: { codevalue: "N" } }, // Makes orderid parameter required in new subscription
  COMDELSUBS_FORM_ENABLED: { ALL: { codevalue: "Y" } }, // Enables co-distribution form
  COMDELSUBS_STATUSFIX: { ALL: { codevalue: "Y" } }, // Enables ability to update status of co-distribution
  OTHERSUBSNO_UCASE: { ALL: { codevalue: "N" } }, // Makes other subsno uppercase by default
  ALLOWADDNEWPOST: { ALL: { codevalue: "Y" } }, // Allows user to add/update post office
  RETREASON_REQUIRED: { ALL: { codevalue: "Y" } }, // Controls whether return reason is mandatory
  ASKSALDATE: { ALL: { codevalue: "N" } }, // Asks whether original sales date should be used in renewal
  CONTACT_LETTER: { ALL: { codevalue: "N" } }, // Enable/disable customers letters form
  SOCIALSECNO: { ALL: { codevalue: "Y" } }, // Optionally validate social security number
  INVMODE_01_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_03_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_07_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_08_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_12_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_13_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_15_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_18_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_19_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_20_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_21_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_22_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_23_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_24_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_25_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  INVMODE_26_BY_PAPER: { ALL: { codevalue: "N" } }, // Invoice mode paper specific (e.g. SI) or not (ALL)
  SHOW_DISCAMOUNT_INCLVAT: { ALL: { codevalue: "Y" } }, // Shows disc amount including vat in new subscription inv args
  RECEIVETYPE_MANDATORY: { ALL: { codevalue: "N" } }, // Whether receivetype shuold be mandatory or not
  PAPERGRPINMARKETING: { ALL: { codevalue: "N" } }, // Whether use paper groups in marketing allowancies
  PAPERGRPINMARKETINGALL: { ALL: { codevalue: "N" } }, // Allow editing paper group 'ALL' in marketing allowancies
  COMMITMENTPERIOD: { ALL: { codevalue: "N" } }, // Displays commitment period fields in new subs
  PERIODICCANCEL: { ALL: { codevalue: "Y" } }, // Option to add cancel reason when creating periodic subs
  CREDITINVALLOWED: { ALL: { codevalue: "Y" } }, // Is credit invoice using allowed
  DISCOUNTWARNING: { ALL: { codevalue: "N" } }, // Warning when new subscription includes discount
  EMAIL_FOR_DIGISUBS: { ALL: { codevalue: "Y" } }, // Warning when customer has no email defined for digi subs
  CHECK_POTENTIALSUBS: { ALL: { codevalue: "Y" } }, // Warning about existing subs when creating new subs
  REMTRANSFERMARK_LINK: { ALL: { codevalue: "Y" } }, // Whether transfer link is displayed in Ledger
  ADD_HOLIDAY_ADDR_ALL: { ALL: { codevalue: "Y" } }, // Display checkbox for updating holiday address to all subs
  OVERLAPHOLIDAYADDR: { ALL: { codevalue: "N" } }, // If overlapping holiday address is allowed
  SETIMMEDIATEINVPRINTED: { ALL: { codevalue: "N" } }, // Sets invoice printed immediately once created
  CRE_CREDITINV_ALWAYS: { ALL: { codevalue: "N" } }, // Sets create credit invoice checkbox to true by default
  CAMPID_TEXT_GENERAL: { ALL: { codevalue: "Y" } }, // How campaign search works in CampaignBox in new subs
  CURRENCY_TEXT: { ALL: { codevalue: "€" } }, // Curreny symbol that is used in the UI
  PHONE_CUSRESERV: { ALL: { codevalue: "N" } }, // Ability to set customer's phone number to start on later date
  CHECK_FREE_OR_CHEAP: { ALL: { codevalue: "N" } }, // Checks if address has had free/cheap subs in last 6 months
  CHECK_SAMEADDRESS: { ALL: { codevalue: "N" } }, // Checks if address already has active/unpaid subs
  CHECK_SUBSCOUNT: { ALL: { codevalue: "N" } }, // Checks if address has had subs in last 6 months
  CREDCOLLINVDEFAULT: { ALL: { codevalue: "Y" } }, // If WS should be used to determine default value for credit invoice
  PHONE_STATUS: {},
  SYS_OPTION: { ALL: { codevalue: "S" } }, // #27423, sys_option is widely used
  MIXEDCASENAME: { ALL: { codevalue: "Y" } }, // #27423, to decide if text is mixed case or not for name fields
  MIXEDCASEADDRESS: { ALL: { codevalue: "Y" } }, // #27423, to decide if text is mixed case or not for address fields
  USE_CITY_DISTRICT: { ALL: { codevalue: "Y" } }, // #27423, check if address has district field
  HOLIDAYSTOP_DEFLEN: { ALL: { codevalue: "MONTH" } }, // #29641, parameter for sleependdate
  DONATION: { ALL: { codevalue: "N" } }, // #29641, show or hide sleeptype Donation
  SENDDIRECTINVOICE: { LICENCE: { codevalue: "N" } }, // #30272, use the same paramter as CS
  BOOKINGDATE: { ALL: { codevalue: "N" } }, // #30524, bookingdate controlled by parrow
  DOORNO_EMPTY: { ALL: { codevalue: "0" } }, // Build 3.6.2.2 (Issue #31524)
  PREFER_INVMODE: { ALL: { codevalue: "" } }, // Build 3.6.2.2 (Issue #31348)
  DELCUST_REMAINS: { ALL: { codevalue: "N" } }, // Build 3.6.2.2 (Issue #31348)
  LEDGER_ACTIVE_MONTH: { ALL: { codevalue: "3" } }, // Build 3.6.2.2 (Issue #31348)
  INVMODE_RENEWAL_11: { ALL: { codevalue: "11" } }, // #25386, check if invoice fee needed
  MESSAGE_DELAY: { ALL: { codevalue: "3000" } }, // #31651, use parrow to control the time that shows error message
  INVOICEFORMAT: { ALL: { codevalue: "" } }, // Build 3.6.2.2 (Issue #31348)
  SINGLESUBS_INVMODE: { ALL: { codevalue: "01" } }, // #27996, invmode could be 01 or 06 for single subscription
  INHERIT_INVTEXT: { ALL: { codevalue: "N" } }, // #29873, parrow control renew invarg.invtext
  EVEN_PERIODS: { ALL: { codevalue: "N" } }, // #31027,
  PACKAGE_AS_AUTOGIRO: { ALL: { codevalue: "" } }, // #31027
  RETTYPE: {}, // #32663, use parrow for rettype radiobox
  CREDTONEXTINVOICE: { ALL: { codevalue: "N" } }, // #32663, control if CollectInvoice show or not in refund
  CUSTOMER_RECLAIM: { ALL: { codevalue: "Y" } }, // Build 3.6.3.4 (Issue #32717)
  STACK: { ALL: { codevalue: "Y" } }, // Build 3.6.3.4 (Issue #32717)
  REASONFOROTHERCREDIT: { ALL: { codevalue: "03" } }, // Build 3.6.3.4 (Issue #32717)
  CHECK_RECLAIM_FORSAMEDATE: { ALL: { codevalue: "ANY" } }, // Build 3.6.3.4 (Issue #32717)
  NOPHONE: { ALL: { codevalue: "" } }, // Build 3.6.3.4 (Issue #32924)
  NOTKNOWN: { ALL: { codevalue: "" } }, // Build 3.6.3.4 (Issue #32924)
  SECRET: { ALL: { codevalue: "" } }, // Build 3.6.3.4 (Issue #32924)
  CAMPAIGNCHNLALLOW: { LICENCE: { codevalue: "N" } }, // #18832
  SINGLE_ENDDATE: { ALL: { codevalue: "" } }, // #18896, set subsenddate to 20781231 for single subs
  SINGLELEN: { ALL: { codevalue: "12" } }, // #18896, single subscription months
  ALLOW_UID_PWD_EQ: { ALL: { codevalue: "N" } }, // Build 3.6.4.1 (Issue #32668)
  PWDLENGTH: { ALL: { codevalue: "6" } }, // Build 3.6.4.1 (Issue #32668)
  ILLEGLPWDCHARS: { ALL: { codevalue: "" } }, // Build 3.6.4.1 (Issue #32668)
  WWWPINCODE_VISIBLE: { ALL: { codevalue: "N" } }, // Build 3.6.4.1 (Issue #32668)
  MINSUBSSLEEPENDDATE: { ALL: { codevalue: "" } }, // Build 3.6.4.1 (Issue #32668)
  PRECISION: { ALL: { codevalue: "0.01" } }, // Build 3.6.4.1 (Issue #32668)
  REPAYMENT_NAME2: { ALL: { codevalue: "Y" } }, // Build 3.6.4.1 (Issue #32668)
  REPAYMENT_ADDRESS2: { ALL: { codevalue: "Y" } }, // Build 3.6.4.1 (Issue #32668)
  REPAYMENT_NAME_LENGTH: { ALL: { codevalue: "0" } }, // Build 3.6.4.1 (Issue #32668)
  ALLOW_BANKREFUND_WITHOUT_IBAN: { ALL: { codevalue: "Y" } }, // Build 3.6.4.1 (Issue #32668)
  APARTMENT_PREFIX: { ALL: { codevalue: "" } }, // Build 3.6.4.1 (Issue #32668)
  CNCLREASON_CREDIT_FINALINVOICE: { ALL: { codevalue: "" } }, // Build 3.6.4.1 (Issue #32668)
  TARGETGROUP_MANDATORY: { ALL: { codevalue: "N" } }, // Build 3.6.4.1 (Issue #32668)
  LETTER_CHANNEL: {}, // Build 3.6.4.1 (Issue #32668)
  AUTOGIRO_PAPER: {}, // Build 3.6.4.1 (Issue #32668)
  EFAKTURA_PAPER: {}, // Build 3.6.4.1 (Issue #32668)
  DIGI_IMMEDIATE_START: { LICENCE: { codevalue: "N" } }, // #20810
  CHECKSTREET: { ALL: { codevalue: "" } }, // Build 3.6.4.1 (Issue #33098)
  INVOICEFEE: {}, // Build 3.6.4.1 (Issue #33098)
  DIRECTINVOICEFEE: { ALL: { codevalue: "N" } }, // Build 3.6.4.1 (Issue #33098)
  FEEMINAMOUNT: {}, // Build 3.6.4.1 (Issue #33098)
  REFERENCE: {}, // Build 3.6.4.1 (Issue #33108)
  SOCIALSEC_DUPLICATE: { ALL: { codevalue: "NONE" } }, // Build 3.6.4.1 (Issue #33117)
  MINSTARTSLEEPOTHER: { ALL: { codevalue: "12" } }, // Build 3.6.4.1 (Issue #33117)
  OCREDIT_MAX_MONTHS: {}, // Build 3.6.4.1 (Issue #33117)
  ROWTEXT1_EMPTY: { ALL: { codevalue: "" } }, // Build 3.6.4.2 (Issue #33117)
  DELIVERYTYPE_EXTRA: { ALL: { codevalue: "Y" } }, // Build 3.6.4.2 (Issue #33117)
  CREDITING_WEBPAPER: {}, // Build 3.6.4.2 (Issue #33117)
  HOLIDAYADDR_DEFLEN: { ALL: { codevalue: "MONTH" } }, // Build 3.6.4.2 (Issue #33117)
  SUBSCRIBER_DEVIATION: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #33117)
  INHERIT_RECOMCUSNO: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #33221)
  INHERIT_RECOM_CUSNO_MODE: { ALL: { codevalue: "00" } }, // Build 3.6.4.2 (Issue #33221)
  INHERIT_CAMPID: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #33221)
  INHERIT_SUBSSTARTDATE: { ALL: { codevalue: "" } }, // Build 3.6.4.2 (Issue #33221)
  SEAMLESS_SUBS_DATES: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #33221)
  STANDINGLEN: { ALL: { codevalue: "12" } }, // Build 3.6.4.2 (Issue #33221)
  INHERIT_SUBSLEN: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #33221)
  INHERIT_SUBSLEN_AG: { ALL: { codevalue: "Y" } }, // Build 3.6.4.2 (Issue #33221)
  DIAPI: {}, // #33092
  DELTROUBLE_DAYS: { ALL: { codevalue: "1" } }, // #33092
  STR_AUTOCOM_START: { ALL: { codevalue: "0" } }, // #33092
  STR_AUTOCOM_START_Z: { ALL: { codevalue: "0" } }, // #33092
  CREDINV_IF_ORDERID_CHG: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #33300)
  QUICKSEARCH: { ALL: { codevalue: "N2,S2,H2,Z2" } }, // Build 3.6.4.2 (Issue #33350)
  CREDIT_INVOICE_TRANSFERRED: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #33322)
  RECL_USELATESTPUBLDATE: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #33373)
  RECLAIMDATE_MODE: { ALL: { codevalue: "00" } }, // Build 3.6.4.2 (Issue #33373)
  ADDRESS_SPECIFIER: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #33372)
  ACCOUNT_HYPHEN: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #33443)
  MIXEDCASECONTROLTEXTS: { ALL: { codevalue: "N" } }, // #33445
  AUTOGIRO_UTAN_MEDG: {}, // Build 3.6.4.2 (Issue #33344)
  COMPENSATE_AMOUNT: {}, // Build 3.6.4.2 (Issue #32870)
  NEWSUBS: {}, // Build 3.6.4.2 (Issue #6661)
  NEWSUBS_ASK: {}, // Build 3.6.4.2 (Issue #6661)
  CS_WINSALETYPEID: { LICENCE: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #26462)
  RECLAIM_PUBLICATION_ONLY: { ALL: { codevalue: "N" } }, // Build 3.6.4.2(Issue #33496)
  DEF_CREDIT_AG: { ALL: { codevalue: "01" } }, // Build 3.6.4.2(Issue #33511)
  DEFPMACH: {}, // Build 3.6.4.2 (Issue #12471)
  EXTRAPRODUCT_AGENT: { ALL: { codevalue: "Y" } }, // Build 3.6.4.2(Issue #17752)
  COMPANY_REVERSE: { ALL: { codevalue: "0" } }, // Build 3.6.4.2(Issue #33595)
  MEMBERSHIPDISCOUNT: { LICENCE: { codevalue: "N" } }, // Build 3.6.4.2(Issue #29435)
  MEMBERSHIP_DISCOUNT_PACKAGE: { ALL: { codevalue: "N" } }, // Build 3.6.4.2(Issue #29435)
  MEMBERSHIP_DISCOUNT_NEW: {}, // Build 3.6.4.2(Issue #29435)
  SOCIALSECNO_MISSING: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (ERP #11080)
  ADDRESS_MISSING: { ALL: { codevalue: "N" } }, // Build 3.6.4.2 (ERP #11110)
  UTANMEDGIVANDEBREV: {}, // Build 3.6.4.2 (ERP #11110)
  SENDER_DISPNAME: { ALL: { codevalue: "" } }, // Build 3.6.4.2 (ERP #11828)
  LETTER_MODEL_FORMAT: { LICENCE: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #11828)
  CONTACTCATEGORY: { LICENCE: { codevalue: "N" } }, // Build 3.6.4.2 (Issue #11828)
  SUBSKIND: {}, // Build 3.6.4.2 (Issue #11897)
  TEMPADDR_STOP_DISTRIBORG: { ALL: { codevalue: "" } }, // Build 3.6.4.2 (ERP #12382)
});

// SUBSIX is a 'special' codegroup. It includes numerous different parameters all
// mixed into the same group, which requires separate parsing logic.
const subsixParams = [
  "OFFICES",
  "DEFRECLITEM",
  "RECLITEMDELIV",
  "DENCHR",
  "INVFEE_FOR_CANCELLED",
  "ALLOW_WEBPAPER",
  "ASKPRINTINV",
  "CREDITANDCANCEL",
  "DOORNOTES",
  "DEFCOLLINV",
  "EMAILMANDATORY",
  "CANCEL_RECEIVETYPE",
  "CANCELREASONGRP",
  "EARLIEST_INVDATE",
  "CAMPAIGNCHNL_MANDATORY",
  "DISPLAYNOTES",
  "NAME3_VISIBLE",
  "INVMODE_ADD_TO_ALL_SUBS",
  "NEWSUBS_PRICE_GAR",
  "RECOM_SUBSTYPE_TO_SUBS",
  "CLASS1_KEEP",
  "CLASS2_KEEP",
  "CLASS3_KEEP",
  "CLASS4_KEEP",
  "CLASS5_KEEP",
  "CLASS6_KEEP",
  "REMPRINTMARK_LINK",
  "INHERITSALESNO",
  "GIFTSUBSCRIPTION",
  "SLEEPENDRESTRICTED",
  "FREE_DEPT",
  "FREE_GRANT",
  "FREE_GROUP",
  "FREE_UNIT",
  "ADDRMATCODE_TO_CRITER1",
  "ALLOWPAYER",
  "EVENT_WITH_SUBSNO",
  "RECEIVETYPEINCHANGES",
  "CHECK_UNPAIDSUBS",
  "SHOW_INVINTERVAL",
  "TARG_GROUP",
  "FORCEIESEP",
  "ORDERID",
  "COMDELSUBS_FORM_ENABLED",
  "COMDELSUBS_STATUSFIX",
  "OTHERSUBSNO_UCASE",
  "ALLOWADDNEWPOST",
  "RETREASON_REQUIRED",
  "ASKSALDATE",
  "CONTACT_LETTER",
  "SOCIALSECNO",
  "INVMODE_01_BY_PAPER",
  "INVMODE_03_BY_PAPER",
  "INVMODE_07_BY_PAPER",
  "INVMODE_08_BY_PAPER",
  "INVMODE_12_BY_PAPER",
  "INVMODE_13_BY_PAPER",
  "INVMODE_15_BY_PAPER",
  "INVMODE_18_BY_PAPER",
  "INVMODE_19_BY_PAPER",
  "INVMODE_20_BY_PAPER",
  "INVMODE_21_BY_PAPER",
  "INVMODE_22_BY_PAPER",
  "INVMODE_23_BY_PAPER",
  "INVMODE_24_BY_PAPER",
  "INVMODE_25_BY_PAPER",
  "INVMODE_26_BY_PAPER",
  "SHOW_DISCAMOUNT_INCLVAT",
  "RECEIVETYPE_MANDATORY",
  "PAPERGRPINMARKETING",
  "PAPERGRPINMARKETINGALL",
  "COMMITMENTPERIOD",
  "PERIODICCANCEL",
  "CREDITINVALLOWED",
  "DISCOUNTWARNING",
  "EMAIL_FOR_DIGISUBS",
  "CHECK_POTENTIALSUBS",
  "REMTRANSFERMARK_LINK",
  "ADD_HOLIDAY_ADDR_ALL",
  "OVERLAPHOLIDAYADDR",
  "SETIMMEDIATEINVPRINTED",
  "CRE_CREDITINV_ALWAYS",
  "CAMPID_TEXT_GENERAL",
  "PHONE_CUSRESERV",
  "CHECK_FREE_OR_CHEAP",
  "CHECK_SAMEADDRESS",
  "CHECK_SUBSCOUNT",
  "CREDCOLLINVDEFAULT",
  "HOLIDAYSTOP_DEFLEN", // #29641, parameter for sleependdate
  "BOOKINGDATE", // #30524, bookingdate controlled by parrow
  "DOORNO_EMPTY", // Build 3.6.2.2 (Issue #31524)
  "PREFER_INVMODE", // Build 3.6.2.2 (Issue #31348)
  "DELCUST_REMAINS", // Build 3.6.2.2 (Issue #31348)
  "LEDGER_ACTIVE_MONTH", // Build 3.6.2.2 (Issue #31348)
  "INVOICEFORMAT", // Build 3.6.2.2 (Issue #31348)
  "SINGLESUBS_INVMODE", // #27996, invmode could be 01 or 06 for single subscription
  "INHERIT_INVTEXT", // #29873, parrow control renew invarg.invtext
  "CREDTONEXTINVOICE", // #32663, control if CollectInvoice show or not in refund
  "CUSTOMER_RECLAIM", // Build 3.6.3.4 (Issue #32717)
  "CHECK_RECLAIM_FORSAMEDATE", // Build 3.6.3.4 (Issue #32717)
  "SINGLE_ENDDATE", // #18896, set subsenddate to 20781231 for single subs
  "SINGLELEN", // #18896, single subscription months
  "WWWPINCODE_VISIBLE", // Build 3.6.4.1 (Issue #32668)
  "MINSUBSSLEEPENDDATE", // Build 3.6.4.1 (Issue #32668)
  "REPAYMENT_NAME2", // Build 3.6.4.1 (Issue #32668)
  "REPAYMENT_ADDRESS2", // Build 3.6.4.1 (Issue #32668)
  "REPAYMENT_NAME_LENGTH", // Build 3.6.4.1 (Issue #32668)
  "ALLOW_BANKREFUND_WITHOUT_IBAN", // Build 3.6.4.1 (Issue #32668)
  "APARTMENT_PREFIX", // Build 3.6.4.1 (Issue #32668)
  "CNCLREASON_CREDIT_FINALINVOICE", // Build 3.6.4.1 (Issue #32668)
  "TARGETGROUP_MANDATORY", // Build 3.6.4.1 (Issue #32668)
  "LETTER_CHANNEL", // Build 3.6.4.1 (Issue #32668)
  "DIRECTINVOICEFEE", // Build 3.6.4.1 (Issue #33098)
  "FEEMINAMOUNT", // Build 3.6.4.1 (Issue #33098)
  "SOCIALSEC_DUPLICATE", // Build 3.6.4.1 (Issue #33117)
  "ROWTEXT1_EMPTY", // Build 3.6.4.2 (Issue #33117)
  "CREDITING_WEBPAPER", // Build 3.6.4.2 (Issue #33117)
  "HOLIDAYADDR_DEFLEN", // Build 3.6.4.2 (Issue #33117)
  "STANDINGLEN", // Build 3.6.4.2 (Issue #33221)
  "DELTROUBLE_DAYS", // #33092
  "CREDINV_IF_ORDERID_CHG", // Build 3.6.4.2 (Issue #33300)
  "QUICKSEARCH", // Build 3.6.4.2 (Issue #33350)
  "CREDIT_INVOICE_TRANSFERRED", // Build 3.6.4.2 (Issue #33322)
  "RECL_USELATESTPUBLDATE", // Build 3.6.4.2 (Issue #33373)
  "RECLAIMDATE_MODE", // Build 3.6.4.2 (Issue #33373)
  "COMPENSATE_AMOUNT", // Build 3.6.4.2 (Issue #32870)
  "RECLAIM_PUBLICATION_ONLY", // Build 3.6.4.2(Issue #33496)
  "DEF_CREDIT_AG", // Build 3.6.4.2(Issue #33511)
  "EXTRAPRODUCT_AGENT", // Build 3.6.4.2(Issue #17752)
  "COMPANY_REVERSE", // Build 3.6.4.2(Issue #33595)
  "MEMBERSHIP_DISCOUNT_NEW", // Build 3.6.4.2(Issue #29435)
  "SOCIALSECNO_MISSING", // Build 3.6.4.2 (ERP #11080)
  "ADDRESS_MISSING", // Build 3.6.4.2 (ERP #11110)
  "MINSTARTSLEEPOTHER", // Build 3.6.4.2 (ERP #11784)
  "OCREDIT_MAX_MONTHS", // Build 3.6.4.2 (ERP #11784)
  "TEMPADDR_STOP_DISTRIBORG", // Build 3.6.4.2 (ERP #12382)
];

// SYSTEM, like SUBSIX, is a 'special codegroup. It at least includes necessary
// system default parameters.
const systemParams = [
  "CURRENCY_TEXT", // System's currency symbol used in the UI
  "CURRENCY", // System's currency string
  "DEFAULT_LANGUAGE", // System's default language,
  "DOMESTIC_COUNTRY",
  "SYS_OPTION", // #27423
  "MIXEDCASENAME", // #27423
  "MIXEDCASEADDRESS", // #27423
  "USE_CITY_DISTRICT", // #27423
  "DONATION", // #29641, show or hide sleeptype Donation
  "STACK", // Build 3.6.3.4 (Issue #32717)
  "PRECISION", // Build 3.6.4.1 (Issue #32668)
  "CHECKSTREET", // Build 3.6.4.1 (Issue #33098}
  "DELIVERYTYPE_EXTRA", // Build 3.6.4.2 (Issue #33117)
  "STR_AUTOCOM_START", // #33092
  "STR_AUTOCOM_START_Z", // #33092
  "ADDRESS_SPECIFIER", // Build 3.6.4.2 (Issue #33372)
  "MIXEDCASECONTROLTEXTS", // #33445
  "MEMBERSHIP_DISCOUNT_PACKAGE", // Build 3.6.4.2(Issue #29435)
];

const licenceParams = [
  "SENDDIRECTINVOICE", // #30272, use the same paramter as CS
  "CAMPAIGNCHNLALLOW", // #18832
  "DIGI_IMMEDIATE_START", // #20810
  "CS_WINSALETYPEID", // Build 3.6.4.2 (Issue #26462)
  "MEMBERSHIPDISCOUNT", // Build 3.6.4.2(Issue #29435)
  "LETTER_MODEL_FORMAT", // Build 3.6.4.2(Issue #11828)
  "CONTACTCATEGORY", // Build 3.6.4.2(Issue #11828)
];

// #25386
const standingParams = [
  "INVMODE_RENEWAL_11", // #25386, check if invoice fee needed
  "INHERIT_RECOMCUSNO", // Build 3.6.4.2 (Issue #33221)
  "INHERIT_RECOM_CUSNO_MODE", // Build 3.6.4.2 (Issue #33221)
  "INHERIT_CAMPID", // Build 3.6.4.2 (Issue #33221)
  "INHERIT_SUBSSTARTDATE", // Build 3.6.4.2 (Issue #33221)
  "SEAMLESS_SUBS_DATES", // Build 3.6.4.2 (Issue #33221)
  "INHERIT_SUBSLEN", // Build 3.6.4.2 (Issue #33221)
  "INHERIT_SUBSLEN_AG", // Build 3.6.4.2 (Issue #33221)
];

// #31651
const webKayakParams = ["MESSAGE_DELAY"];

// #31027
const autoGiroParams = [
  "EVEN_PERIODS",
  "PACKAGE_AS_AUTOGIRO",
  "AUTOGIRO_PAPER", // Build 3.6.4.1 (Issue #32668)
  "AUTOGIRO_UTAN_MEDG", // Build 3.6.4.2 (Issue #33344)
];

// Build 3.6.3.4 (Issue #32717)
const sleepReasonRuleParams = ["REASONFOROTHERCREDIT"];

// Build 3.6.3.4 (Issue #32924)
const phoneTextParams = ["NOPHONE", "NOTKNOWN", "SECRET"];

// Build 3.6.4.1 (Issue #32668)
const webCirixParams = ["ALLOW_UID_PWD_EQ", "PWDLENGTH", "ILLEGLPWDCHARS"];

// Build 3.6.4.1 (Issue #32668)
const efakturaParams = ["EFAKTURA_PAPER"];

// Build 3.6.4.1 (Issue #33098)
const monetaryParams = ["INVOICEFEE"];

// Build 3.6.4.1 (Issue #33108)
const repaymentParams = [
  "REFERENCE",
  "ACCOUNT_HYPHEN", // Build 3.6.4.2 (Issue #33443)
];

// Build 3.6.4.2 (Issue #6661)
// Build 3.6.4.2 (ERP #11609)
const letterParams = ["NEWSUBS", "NEWSUBS_ASK", "UTANMEDGIVANDEBREV"];

// Build 3.6.4.2 (Issue #12471)
const postParams = ["DEFPMACH"];

const appendToState = (state, object) => {
  if (!(object.papercode in state[object.codegroupno])) {
    state[object.codegroupno][object.papercode] = {};
  }
  state[object.codegroupno][object.papercode][object.codeno] = object.codevalue;
};

// Go through all the parameters and save relevant values to the Redux store.
const parseParameters = (parameters) => {
  // #28018, deep copy defaultState to make state clean when changing publisher
  const state = JSON.parse(JSON.stringify(defaultState));
  parameters.forEach((object) => {
    if (object.codegroupno in defaultState) {
      appendToState(state, object);
    } else if (object.codegroupno === "SUBSIX") {
      if (subsixParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
        // Build 3.6.4.1 (Issue #32668)...
      } else if (object.codeno.startsWith("CANCELSUBS_WARNING_INVMODE_")) {
        state[object.codeno] = object;
      } else if (object.codeno.startsWith("SUBS_WARNING_INVMODE_")) {
        state[object.codeno] = object;
      } else if (object.codeno.startsWith("RECEIVETYPE_WARNING_")) {
        // Build 3.6.4.2 (ERP #11461)
        state[object.codeno] = object;
      }
      // ...Build 3.6.4.1 (Issue #32668)
    } else if (object.codegroupno === "SYSTEM") {
      if (systemParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
      }
    } else if (object.codegroupno === "LICENCE") {
      // #30272, for parameters with codegroup LICENCE
      // #18832, CAMPAIGNCHNL conflicts with codegroup CAMPAIGNCHNL
      if (object.codeno === "CAMPAIGNCHNL") {
        state.CAMPAIGNCHNLALLOW[object.papercode] = object;
      } else if (licenceParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
      }
    } else if (object.codegroupno === "STANDING") {
      // #25386
      if (standingParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
      }
    } else if (object.codegroupno === "WEBKAYAK") {
      // #31651
      if (webKayakParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
      }
    } else if (object.codegroupno === "AUTOGIRO") {
      // #31027
      // Build 3.6.4.1 (Issue #32668)...
      if (autoGiroParams.includes(object.codeno)) {
        if (object.codeno === "AUTOGIRO_PAPER") {
          state[object.codeno][object.papercode] = { codevalue: object.codevalue };
        } else {
          // ...Build 3.6.4.1 (Issue #32668)

          state[object.codeno][object.papercode] = object;
        }
      }
    } else if (object.codegroupno === "SLEEPREASONRULE") {
      // Build 3.6.3.4 (Issue #32717)
      if (sleepReasonRuleParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
      }
    } else if (object.codegroupno === "PHONETEXT") {
      // Build 3.6.3.4 (Issue #32924)
      if (phoneTextParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
      }
    } else if (object.codegroupno === "WEBCIRIX") {
      // Build 3.6.4.1 (Issue #32668)
      if (webCirixParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
      }
    } else if (object.codegroupno === "EFAKTURA") {
      // Build 3.6.4.1 (Issue #32668)
      if (efakturaParams.includes(object.codeno)) {
        if (object.codeno === "EFAKTURA_PAPER") {
          const list = object.codevalue.split(",");
          state[object.codeno][object.papercode] = { codevalue: list };
        }
      }
    } else if (object.codegroupno === "MONETARY") {
      // Build 3.6.4.1 (Issue #33098)
      if (monetaryParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
      }
    } else if (object.codegroupno === "REPAYMENT") {
      // Build 3.6.4.1 (Issue #33108)
      if (repaymentParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = { codevalue: object.codevalue };
      }
    } else if (object.codegroupno === "LETTER") {
      // Build 3.6.4.2 (Issue #6661)
      if (letterParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
      }
    } else if (object.codegroupno === "POST") {
      // Build 3.6.4.2 (Issue #12471)
      if (postParams.includes(object.codeno)) {
        state[object.codeno][object.papercode] = object;
      }
    }
  });

  return state;
};

const parameterReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_SYSTEM_PARAMETERS: {
      return { ...state, ...parseParameters(action.data) };
    }

    case actions.SET_SOURCE_GROUPS: {
      return { ...state, SOURCE_GROUPS: action.sourceGroups };
    }

    case actions.SET_CONTACT_TYPES: {
      return { ...state, CONTACT_TYPES: action.contactTypes };
    }

    case actions.SET_PHONECHECK_SOURCES: {
      return { ...state, PHONECHECK_SOURCES: action.phoneCheckSources };
    }

    case actions.SET_PHONE_STATUS: {
      return { ...state, PHONE_STATUS: action.phoneStatus };
    }

    case actions.SET_CUSTOMER_LANGUAGES: {
      return { ...state, CUSTOMER_LANGUAGES: action.languages };
    }

    case actions.SET_RETURNCHANNELS: {
      return { ...state, RETCHANNEL: action.returnChannels };
    }

    case actions.SET_CAI_PARAMETERS: {
      return { ...state, CAI: action.CAI };
    }

    case actions.SET_SOURCEMATERIALS: {
      return { ...state, SOURCEMATERIALS: action.SOURCEMATERIALS };
    }

    case actions.SET_COMMITMENTPERIODS: {
      return { ...state, COMMITMENTPERIODS: action.commitmentPeriods };
    }

    case actions.SET_PAYMCODE: {
      return { ...state, PAYMCODE: action.PAYMCODE };
    }

    // #32663, use parrow for rettype radiobox
    case actions.SET_RETTYPE: {
      return { ...state, RETTYPE: action.returnRetTypes };
    }

    default:
      return state;
  }
};

export default parameterReducer;
