import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory as createHistory } from "history";
// import createHistory from "history/createBrowserHistory";
import { createLogger } from "redux-logger";

import rootReducer from "./reducers/index.js";

// Create browser history
const history = createHistory();
// Middleware for intercepting and dispatching navigation actions
const middleware = [routerMiddleware(history)];
// Add redux-thunk to same middleware
middleware.push(thunk);

// Check for undefined because tests don't have environment variables
if (typeof process.env !== "undefined") {
  if (process.env.NODE_ENV === "development") {
    const logger = createLogger({
      collapsed: true,
    });

    middleware.push(logger);
  }
}

// Combine application's reducers, add routerReducer to the store and Apply
// middleware for navigation.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancers(applyMiddleware(...middleware))
);

export { store, history };
