import * as actions from "../actions/actionTypes.js";

const defaultState = {
  authenticated: null,
  DENIED_MODULE_NAMES: new Set([]),
  DENIED_PAPER_GROUPS: [],
  DENIED_MODULES: [], // Build 3.6.4.1 (Issue #32668)
  properties: {},
  settings: {
    tables: {
      visibility: {}, // Hidden columns for tables, e.g. { 'customer': [0, 5, 9] }
    },
  },
  publishers: { current: "", publishers: {} },
  defaults: {},
  parrowUserSettings: { invoiceLimit: 60 }, // Build 3.6.2.2 (Issue #31348)
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.AUTHENTICATED: {
      // Storing array of denied_module_names received from API as a Set
      const DENIED_MODULE_NAMES = new Set(action.DENIED_MODULE_NAMES);

      return {
        ...state,
        authenticated: action.authenticated,
        DENIED_MODULE_NAMES: DENIED_MODULE_NAMES,
        DENIED_PAPER_GROUPS: action.DENIED_PAPER_GROUPS,
        DENIED_MODULES: action.DENIED_MODULES, // Build 3.6.4.1 (Issue #32668)
      };
    }

    case actions.SET_USER_INFO: {
      return { ...state, properties: action.properties };
    }

    case actions.SET_USER_SETTINGS: {
      let settings = action.settings;
      // Settings must be an object with content, otherwise defaultState is used
      if (settings === null || typeof settings !== "object" || Object.keys(settings).length === 0) {
        settings = { ...defaultState.settings };
      }

      return { ...state, settings };
    }

    case actions.SET_PUBLISHERS: {
      const publishers = action.publishers;
      const current = action.current;

      return { ...state, publishers: { publishers, current } };
    }

    case actions.CHANGE_PUBLISHER: {
      const publishers = { ...state.publishers, current: action.publisher };

      return { ...state, publishers };
    }

    case actions.SET_TABLE_COLUMN_VISIBILITY: {
      const visibility = { ...state.settings.tables.visibility };
      visibility[action.tableName] = action.hiddenColumns;

      return {
        ...state,
        settings: {
          ...state.settings,
          tables: {
            ...state.settings.tables,
            visibility,
          },
        },
      };
    }

    case actions.SET_USER_DEFAULTS: {
      return { ...state, defaults: action.defaults };
    }

    // Build 3.6.2.2 (Issue #31348)
    case actions.SET_PARROW_USER_SETTINGS: {
      return { ...state, parrowUserSettings: action.parrowUserSettings };
    }

    default:
      return state;
  }
};

export default userReducer;
