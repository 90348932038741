import React from "react";
import { Route, Switch, withRouter } from "react-router";
import { Redirect } from "react-router-dom";

import { store } from "../store.js";
import { getCancelReasons } from "../actions/customer.js";
import { getSystemParameters } from "../actions/parameter.js";

// Components inside the main content view are loaded only when requested so that
// it isn't necessary to load the whole application on startup.
import lazyComponent from "./LazyComponent.jsx";
import HeaderContainer from "./Header.jsx";
import NavigationContainer from "./Navigation.jsx";
import EmailContainer from "./Email.jsx";
import DummyContainer from "./report/DummyContainer.jsx";

const Customer = lazyComponent(() =>
  import(/* webpackChunkName: "customer" */ "./customer/Customer.jsx")
);
const CoDistribution = lazyComponent(() =>
  import(/* webpackChunkName: "coDistribution" */ "./coDistribution/CoDistribution.jsx")
);
const UserFeedbacksContainer = lazyComponent(() =>
  import(/* webpackChunkName: "userFeedbacks" */ "./user/UserFeedbacks.jsx")
);
const UserCustomerContactsContainer = lazyComponent(() =>
  import(/* webpackChunkName: "userCustomerContacts" */ "./user/UserCustomerContacts.jsx")
);
// Issue #27319
const Reports = lazyComponent(() => import(/* webpackChunkName: "reports" */ "./Reports.jsx"));
// #33168 add users admin
const UserManagement = lazyComponent(() =>
  import(/* webpackChunkName: "users" */ "./admin/userManagement")
);

// Index route which redirects to another component (Customer in this case)
const IndexRoute = () => <Route render={() => <Redirect to={{ pathname: "/customer/" }} />} />;

const NavigationWithRouter = withRouter(NavigationContainer);

// Application's main container. Header and side navigation are always available
// and the main content is defined inside Switch, defaulting to home page.
class Main extends React.Component {
  // A user is authenticated when execution comes here, so non-critical application
  // data can be fetcher from the database here.
  componentDidMount() {
    store.dispatch(getCancelReasons());
    store.dispatch(getSystemParameters());
  }

  // NOTE: When creating or updating application's Routes sidebar navigation also
  // needs to be udpated. Navigation needs same options as defined here so that
  // link highlighting and redirection works properly. (Navigation is defined
  // in the file ui/src/containers/Navigation.jsx)

  // Issue #28110 /reports/new
  render() {
    return (
      <div className="page-container">
        <HeaderContainer />
        <div id="main-container">
          <NavigationWithRouter />
          <Switch>
            <Route path="/customer/:cusno?" component={Customer} />
            <Route path="/co-distribution/:subsno?" component={CoDistribution} />
            <Route path="/feedbacks/user" component={UserFeedbacksContainer} />
            <Route path="/customer-contacts/user" component={UserCustomerContactsContainer} />
            {/* Issue #27319 */}
            <Route path="/reports/new" component={DummyContainer} />
            <Route path="/reports" component={Reports} />
            {/** #33168 add admin user management */}
            <Route path="/admin/user-management" component={UserManagement} />
            <Route path="/" exact component={IndexRoute} />
            <Route component={IndexRoute} />
          </Switch>
        </div>
        <EmailContainer />
      </div>
    );
  }
}

export default Main;
