/* eslint-disable react/prop-types */
import React from "react";
import { Row, Col } from "reactstrap";

// Colored 1 column wide icon with text underneath. Use offset push icon(s) to right. Use only on first item on right.
// Usage: <Row><IconButton color='yellow' icon='ion-md-add-circle'>Title text</IconButton></Row>
export const IconButton = (props) => {
  const {
    icon = "ion-md-alert",
    color = "black",
    className: classes = "",
    offset,
    children,
    style,
    disabled = false,
    ...rest
  } = props;
  return (
    <div
      className={`icon-button icon-button-${color}${offset ? " ml-auto" : ""} ${
        disabled ? "disabled" : ""
      } ${classes}`}
      {...rest}
    >
      <Row className="ml-0 mr-0">
        <i className={`${icon}`} style={style}></i>
      </Row>
      <Row className="ml-0 mr-0">
        <span className={disabled ? "gray" : "black"}>{children}</span>
      </Row>
    </div>
  );
};

// Designed for white header component <HeaderWithIconButton> that has an expand icon in right side
// Usage: <Col xs={11}>Content</Col><IconSmall color='blue' icon='ion-ios-add-circle' />
export const IconSmall = (props) => {
  const {
    icon = "ion-ios-add-circle",
    color = "blue-dark",
    style,
    className: classes = "",
    ...rest
  } = props;
  return (
    <Col xs={1} className={`icon-small-${color} ${classes}`} {...rest}>
      <i className={`ion-md-${icon}`} style={style}></i>
    </Col>
  );
};

export const IconMedium = (props) => {
  const {
    icon = "ion-ios-add-circle",
    color = "blue-dark",
    style,
    className: c = "",
    float = "",
    ...rest
  } = props;
  return (
    <i
      className={`${icon} icon-medium-${color} ${c}`}
      style={{ float: float, ...style }}
      {...rest}
    ></i>
  );
};

// Arrow icon meant to be used with <HeaderContainer> when it needs to expand
// Usage: <ExpandIcon color='blue' open={isItOpen} />
// TODO: is color prop necessary or are they all going to be blue?
export const ExpandIcon = (props) => {
  const { color = "blue-dark", style, className: classes = "", open, ...rest } = props;
  const icon = open ? "ion-ios-arrow-up" : "ion-ios-arrow-down";
  return (
    <i
      className={`icon-expand bg-${color} ${icon} ${classes} ${open ? "open" : ""}`}
      style={style}
      {...rest}
    ></i>
  );
};

// Previous & next icon
export const PreviousNextIcon = (props) => {
  const { isPrevious, className: classes = "", ...rest } = props;
  const icon = isPrevious ? "ion-ios-arrow-back" : "ion-ios-arrow-forward";
  return <i className={`icon-previous-next bg-blue-dark ${icon} ${classes}`} {...rest}></i>;
};

// A small circle icon indicating success or failure, default false
// Usage: <ActiveDot /> or <ActiveDot active /> or <ActiveDot active={isItActive} />
export const ActiveDot = (props) => {
  const { active = false } = props;
  return <i className={`circle-sm bg-${active ? "green" : "red"}`}></i>;
};

// Colored circles representing different states
const lightColors = {
  0: "light-red",
  1: "light-yellow",
  2: "light-green",
  3: "gray",
  4: "secondary",
  5: "blue",
};

export const TrafficLight = ({ value, className = "" }) => {
  return <i className={`${className} circle-sm bg-${lightColors[value]}`}></i>;
};

// Icon with text under and colored square background-color
// Usage: <IconTile color='green' icon='ion-ios-sync'>Jatkotilaus</IconTile>
export const IconTile = (props) => {
  const {
    children,
    style,
    icon = "ion-ios-alert",
    color = "gray",
    className = "",
    ...rest
  } = props;

  return (
    <div className={`icon-tile-${color} ${className}`} {...rest}>
      <i className={icon} style={style}></i>
      <p>{children}</p>
    </div>
  );
};

// Used in page content header (for example add customer) and table settings button (hovered)
/* #33206 this doesn't seem to have anything to do with being a table settings button, only used as a primary button on 3 pages.
 * Semantically element should be a <button>, not <div>. Renamed component to match purpose and fixed semantic issue.
 */
export const PrimaryIconButton = (props) => {
  const {
    style,
    icon = "ion-md-settings",
    color = "blue-dark",
    text = "",
    className = "",
    onClick,
    ...rest
  } = props;

  return (
    <button className={`icon-settings-${color} ${className} pl-3 pr-3`} onClick={onClick}>
      <i className={icon} style={style} {...rest}></i>
      &nbsp;{text !== "" && <span className="icon-text">{text}</span>}
    </button>
  );
};
