import * as actions from "../actions/actionTypes.js";

/** #33168 add users admin */

const defaultState = {
  users: [],
  usersLoading: false,
  user: null,
  userDetailsLoading: false,
};

const adminReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.ADMIN_IS_LOADING_USERS: {
      return { ...state, users: [], usersLoading: true };
    }
    case actions.ADMIN_SET_ALL_USERS: {
      return { ...state, users: action.users, usersLoading: false };
    }
    case actions.ADMIN_IS_LOADING_USER_DETAILS: {
      return { ...state, userDetailsLoading: true };
    }
    case actions.ADMIN_SET_USER_DETAILS: {
      return { ...state, user: action.user, userDetailsLoading: false };
    }
    case actions.ADMIN_LOADING_USER_DETAILS_FAILED: {
      return { ...state, user: null, userDetailsLoading: false };
    }
    default:
      return state;
  }
};

export default adminReducer;
