import { Fetcher, fetchWithRetry } from "./fetcher.js";
import apiUrl from "./url.js";

const validate = () => {
  const url = apiUrl("validate", false);

  return fetchWithRetry("getJson", [url]);
};

const authenticate = (username, password) => {
  const url = apiUrl("login", false);
  const body = { user: { username: username, password: password } };

  return Fetcher.rawFetch(url, "POST", body).then((response) => {
    if (response.status !== 200) {
      const err = new Error();
      err.status = response.status;
      throw err;
    }

    return response.json();
  });
};

const logout = () => {
  const url = apiUrl("logout", false);

  return Fetcher.getJson(url);
};

export { authenticate, logout, validate };
