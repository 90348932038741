import React from "react";

import { store } from "../store.js";
import { notify } from "../actions/notification.js";

import Loading from "../components/Loading.jsx";

/*
 * lazyComponent allows importing application's main content components lazily.
 *
 * It uses dynamic imports to load components only when it's necessary instead
 * of loading everything at startup. This makes the application more lightweight.
 */
const lazyComponent = (componentImport) => {
  class LazyComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = { component: null };
      this.divStyle = {
        position: "relative",
        width: "100%",
        height: "100%",
      };
    }

    componentDidMount() {
      componentImport()
        .then((imported) => {
          // Extract the default export from the given module.
          this.setState({ component: imported.default });
        })
        .catch(() => {
          store.dispatch(notify("Component could not be loaded.", "error"));
        });
    }

    render() {
      return this.state.component ? (
        <this.state.component {...this.props} />
      ) : (
        <div style={this.divStyle}>
          <Loading loading={true} />
        </div>
      );
    }
  }

  return LazyComponent;
};

export default lazyComponent;
