// User related actions
export const AUTHENTICATED = "AUTHENTICATED";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const SET_PUBLISHERS = "SET_PUBLISHERS";
export const CHANGE_PUBLISHER = "CHANGE_PUBLISHER";
export const SET_TABLE_COLUMN_VISIBILITY = "SET_TABLE_COLUMN_VISIBILITY";
export const SET_USER_DEFAULTS = "SET_USER_DEFAULTS";
export const SET_PARROW_USER_SETTINGS = "SET_PARROW_USER_SETTINGS"; // Build 3.6.2.2 (Issue #31348)

/** #33168 add users admin */
// Admin actions
export const ADMIN_IS_LOADING_USERS = "ADMIN_IS_LOADING_USERS";
export const ADMIN_IS_LOADING_USER_DETAILS = "ADMIN_IS_LOADING_USER_DETAILS";
export const ADMIN_LOADING_USER_DETAILS_FAILED = "ADMIN_LOADING_USER_DETAILS_FAILED";
export const ADMIN_SET_ALL_USERS = "ADMIN_SET_ALL_USERS";
export const ADMIN_SET_USER_DETAILS = "ADMIN_SET_USER_DETAILS";

// Notification actions
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION";

// Translation actions
export const PROCESSING = "PROCESSING";
export const SET_TRANSLATION = "SET_TRANSLATION";
export const SWITCH_TRANSLATION = "SWITCH_TRANSLATION";
export const SET_AVAILABLE_TRANSLATIONS = "SET_AVAILABLE_TRANSLATIONS";
export const SET_TRANSLATION_DEBUG_MODE = "SET_TRANSLATION_DEBUG_MODE";

// Locale actions
export const SET_LOCALE = "SET_LOCALE";
export const SET_AVAILABLE_LOCALES = "SET_AVAILABLE_LOCALES";

// Customer actions
export const CUSTOMER_PROCESSING = "CUSTOMER_PROCESSING";
export const CLEAR_CUSTOMER = "CLEAR_CUSTOMER";
// --Search
export const SET_IS_SEARCHING_CUSTOMER = "SET_IS_SEARCHING_CUSTOMER";
export const TOGGLE_CUSTOMER_ADVANCED_SEARCH_MODAL = "TOGGLE_CUSTOMER_ADVANCED_SEARCH_MODAL";
export const SET_SEARCH_EXPANDED = "SET_SEARCH_EXPANDED";
export const SET_CUSTOMER_SEARCH_RESULT = "SET_CUSTOMER_SEARCH_RESULT";
export const SELECT_SEARCH_RESULT = "SELECT_SEARCH_RESULT";
export const TOGGLE_ADD_CUSTOMER_MODAL = "TOGGLE_ADD_CUSTOMER_MODAL";

export const SET_SUBSCRIPTIONS_AND_PRODUCTS = "SET_SUBSCRIPTIONS_AND_PRODUCTS";
export const SELECT_SUBSCRIPTIONS_AND_PRODUCTS_ROW = "SELECT_SUBSCRIPTIONS_AND_PRODUCTS_ROW";
export const UPDATE_RECENT_CUSTOMERS = "UPDATE_RECENT_CUSTOMERS";
export const LOAD_CUSTOMER = "LOAD_CUSTOMER";
export const LOAD_ORIGINAL_PINCODE = "LOAD_PINCODE";
export const TOGGLE_UPDATE_CUSTOMER_MODAL = "TOGGLE_UPDATE_CUSTOMER_MODAL";
export const SHOULD_CLEAR_CUSTOMER = "SHOULD_CLEAR_CUSTOMER";
export const LOAD_SUBSCRIPTIONS = "LOAD_SUBSCRIPTIONS";
export const LOAD_RAW_SUBSCRIPTIONS = "LOAD_RAW_SUBSCRIPTIONS";
export const CLEAR_SUBSCRIPTIONS = "CLEAR_SUBSCRIPTIONS";
export const SELECT_SUBSCRIPTION = "SELECT_SUBSCRIPTION";
// #30344, latest created subs should be selected after closing newsubscription modal
export const NEXT_SELECT_SUBSCRIPTION = "NEXT_SELECT_SUBSCRIPTION";
export const LOAD_CUSTOMER_EXTRA_PRODUCTS = "LOAD_CUSTOMER_EXTRA_PRODUCTS";
export const SELECT_CUSTOMER_EXTRA_PRODUCT = "SELECT_CUSTOMER_EXTRA_PRODUCT";
export const SELECT_CUSTOMER_EXTRA_PRODUCT_INSTALMENT = "SELECT_CUSTOMER_EXTRA_PRODUCT_INSTALMENT";
export const CLEAR_CUSTOMER_EXTRA_PRODUCTS = "CLEAR_CUSTOMER_EXTRA_PRODUCTS";
export const LOAD_CURRENT_BASIC_ADDRESS = "LOAD_CURRENT_BASIC_ADDRESS";
export const LOAD_PENDING_ADDRESS_CHANGE = "LOAD_PENDING_ADDRESS_CHANGE";
export const LOAD_PENDING_ADDRESSES_CHANGE = "LOAD_PENDING_ADDRESSES_CHANGE"; // Build 3.6.4.2 (ERP #11199)
export const LOAD_SUBS_TEMP_ADDRESSES = "LOAD_SUBS_TEMP_ADDRESSES";
export const LOAD_ALL_TEMP_ADDRESSES = "LOAD_ALL_TEMP_ADDRESSES";
export const RESET_HOLIDAY_ADDRESS = "RESET_HOLIDAY_ADDRESS";
export const LOAD_SUBS_SPLIT_ADDRESSES = "LOAD_SUBS_SPLIT_ADDRESSES";
export const RESET_SPLIT_ADDRESS = "RESET_SPLIT_ADDRESS";
export const LOAD_CURRENT_INVOICE_ADDRESS = "LOAD_CURRENT_INVOICE_ADDRESS";
export const LOAD_PENDING_INVOICE_ADDRESS = "LOAD_PENDING_INVOICE_ADDRESS";
export const LOAD_PAPERS = "LOAD_PAPERS";
export const LOAD_AVAILABLE_EXTRA_PRODUCTS = "LOAD_AVAILABLE_EXTRA_PRODUCTS";
export const SET_SELECTED_PAPER = "SET_SELECTED_PAPER";
export const SET_PRODUCTPACKAGE = "SET_PRODUCTPACKAGE";
export const SET_SELECTED_EXTRA_PRODUCT = "SET_SELECTED_EXTRA_PRODUCT";
export const RESET_SELECTED_PAPER = "RESET_SELECTED_PAPER";
export const RESET_SELECTED_EXTRA_PRODUCT = "RESET_SELECTED_EXTRA_PRODUCT";
export const LOAD_CAMPAIGNS = "LOAD_CAMPAIGNS";
export const LOAD_FILTERED_CAMPAIGNS = "LOAD_FILTERED_CAMPAIGNS";
export const LOAD_API_FILTERED_CAMPAIGNS = "LOAD_API_FILTERED_CAMPAIGNS";
export const RESET_FILTERED_CAMPAIGNS = "RESET_FILTERED_CAMPAIGNS";
export const SET_SELECTED_CAMPAIGN = "SET_SELECTED_CAMPAIGN";
export const RESET_SELECTED_CAMPAIGN = "RESET_SELECTED_CAMPAIGN";
export const RESET_PRODUCT_PACKAGE = "RESET_PRODUCT_PACKAGE";
export const SET_SELECTED_PRODUCT_PACKAGE = "SET_SELECTED_PRODUCT_PACKAGE";
export const RESET_SELECTED_PRODUCT_PACKAGE = "RESET_SELECTED_PRODUCT_PACKAGE";
export const LOAD_SUBSCRIPTION_TYPES = "LOAD_SUBSCRIPTION_TYPES";
export const ADD_PRODUCT_TO_SUBSCRIPTION = "ADD_PRODUCT_TO_SUBSCRIPTION";
export const RELOAD_SUBSCRIPTION_PRODUCTS = "RELOAD_SUBSCRIPTION_PRODUCTS";
export const REMOVE_PRODUCT_FROM_SUBSCRIPTION = "REMOVE_PRODUCT_FROM_SUBSCRIPTION";
export const RESET_SUBSCRIPTION_PRODUCTS = "RESET_SUBSCRIPTION_PRODUCTS";
export const SELECT_SUBSCRIPTION_PRODUCT = "SELECT_SUBSCRIPTION_PRODUCT";
export const CLEAR_SUBSCRIPTION_EXTRA_PRODUCTS = "CLEAR_SUBSCRIPTION_EXTRA_PRODUCTS";
export const SET_COMMUNECODE = "SET_COMMUNECODE";
export const SET_SHOULD_RELOAD_SUBSCRIPTIONS = "SET_SHOULD_RELOAD_SUBSCRIPTIONS";
export const SET_CANCEL_REASONS = "SET_CANCEL_REASONS";
export const SET_SUBMIT_RESULT = "SET_SUBMIT_RESULT";
export const EDIT_PRODUCT_IN_SUBSCRIPTION = "EDIT_PRODUCT_IN_SUBSCRIPTION";
export const SET_AVAILABLE_DISCOUNTS = "SET_AVAILABLE_DISCOUNTS";
export const RESET_AVAILABLE_DISCOUNTS = "RESET_AVAILABLE_DISCOUNTS";
export const SET_ALL_AVAILABLE_DISCOUNTS = "SET_ALL_AVAILABLE_DISCOUNTS";
export const SET_PRICE_GROUPS = "SET_PRICE_GROUPS";
export const SET_SINGLECOPY_PRICE_GROUPS = "SET_SINGLECOPY_PRICE_GROUPS"; // Build 3.6.4.2 (Issue #32834)
export const SET_SELECTED_SINGLESUBS_PERIODS = "SELECTED_SINGLESUBS_PERIODS"; // Build 3.6.4.2 (Issue #32834)
export const RESET_PRICE_GROUPS = "RESET_PRICE_GROUPS";
export const RESET_SINGLECOPY_PRICE_GROUPS = "RESET_SINGLECOPY_PRICE_GROUPS"; // Build 3.6.4.2 (Issue #32834)
export const SET_AVAILABLE_MONTH_LENGTHS = "SET_AVAILABLE_MONTH_LENGTHS";
export const RESET_AVAILABLE_MONTH_LENGTHS = "RESET_AVAILABLE_MONTH_LENGTHS";
export const SET_CAMPAIGN_DETAILS = "SET_CAMPAIGN_DETAILS";
// Build 3.6.4.1 (Issue #33098)
export const SET_SELECTEDSUBS_CAMPAIGN_DETAILS = "SET_SELECTEDSUBS_CAMPAIGN_DETAILS";
export const SET_SUBSCRIPTION_INSTALMENTS = "SET_SUBSCRIPTION_INSTALMENTS";
export const SET_SELECTED_SUBS_INSTALMENT = "SET_SELECTED_SUBS_INSTALMENT";
export const SET_SUBSCRIPTION_PAYER = "SET_SUBSCRIPTION_PAYER";
export const SET_SUBSCRIPTION_RECOMMENDER = "SET_SUBSCRIPTION_RECOMMENDER";
export const SET_SUBSCRIPTION_SALESMAN = "SET_SUBSCRIPTION_SALESMAN";
export const SET_CUSTOMER_INVOICE_MODES = "SET_CUSTOMER_INVOICE_MODES";
export const RESET_INVOICE_MODES = "RESET_INVOICE_MODES";
export const SET_VAT_TO_EXTRA_PRODUCT = "SET_VAT_TO_EXTRA_PRODUCT";
export const SET_PCS_TO_EXTRA_PRODUCT = "SET_PCS_TO_EXTRA_PRODUCT";
export const SET_MODIFIED_PRICE_TO_EXTRA_PRODUCT = "SET_MODIFIED_PRICE_TO_EXTRA_PRODUCT";
export const SET_DELIVERY_ISSUE = "SET_DELIVERY_ISSUE";
export const CLEAR_DELIVERY_ISSUES = "CLEAR_DELIVERY_ISSUES";
export const LOAD_FREE_SUBS_GROUP = "LOAD_FREE_SUBS_GROUP";
export const SET_SAME_ADDRESS_SUBSCRIPTIONS = "SET_SAME_ADDRESS_SUBSCRIPTIONS";
export const CLEAR_SAME_ADDRESS_SUBSCRIPTIONS = "CLEAR_SAME_ADDRESS_SUBSCRIPTIONS";
export const TOGGLE_SAME_ADDRESS_MODAL = "TOGGLE_SAME_ADDRESS_MODAL";
export const LOAD_SUBSCRIPTION_SLEEPS = "LOAD_SUBSCRIPTION_SLEEPS";
export const SET_SELECTED_SLEEP = "SET_SELECTED_SLEEP";
export const SET_CUSTOMER_EVENTS = "SET_CUSTOMER_EVENTS";
export const SET_CUSTOMER_EVENTS_CHANGED = "SET_CUSTOMER_EVENTS_CHANGED";
export const SELECT_CUSTOMER_EVENT = "SELECT_CUSTOMER_EVENT";
export const CLEAR_CUSTOMER_EVENTS = "CLEAR_CUSTOMER_EVENTS";
export const SELECT_CANCEL_SUBS_ITEM = "SELECT_CANCEL_SUBS_ITEM";
export const LOAD_PAID_AND_OPEN_ITEMS = "LOAD_PAID_AND_OPEN_ITEMS";
export const TOGGLE_SUBSCRIPTION_GIFT_MODAL = "TOGGLE_SUBSCRIPTION_GIFT_MODAL";
export const SET_ITEM_EXPENSES = "SET_ITEM_EXPENSES";
export const SET_GROSS_PRICES = "SET_GROSS_PRICES";
export const SET_SUBSCRIPTION_PAPER_PRODUCT = "SET_SUBSCRIPTION_PAPER_PRODUCT";
export const SET_AVAILABLE_ITEM_LENGTHS = "SET_AVAILABLE_ITEM_LENGTHS";
export const SET_PAPER_GROUPS = "SET_PAPER_GROUPS";
export const SET_GROUP_PAPERS = "SET_GROUP_PAPERS";
export const LOAD_SALES_GROUP_PAPERS = "LOAD_SALES_GROUP_PAPERS";
export const LOAD_SALES_GROUPS = "LOAD_SALES_GROUPS";
export const LOAD_SALES_FEE_GROUPS = "LOAD_SALES_FEE_GROUPS";
export const LOAD_SALES_PRIZES = "LOAD_SALES_PRIZES";
export const LOAD_MARKETING_PERMISSIONS = "LOAD_MARKETING_PERMISSIONS";
export const CLEAR_MARKETING_PERMISSIONS = "CLEAR_MARKETING_PERMISSIONS";
export const ADD_HIGHLIGHT_TO_SUBSPRODUCT = "ADD_HIGHLIGHT_TO_SUBSPRODUCT";
export const CLEAR_HIGHLIGHT_FROM_SUBSPRODUCT = "CLEAR_HIGHLIGHT_FROM_SUBSPRODUCT";
export const REMOVE_INVNO_FROM_SUBS_INSTALMENTS = "REMOVE_INVNO_FROM_SUBS_INSTALMENTS";
export const UPDATE_INSTALMENTS_WITH_NEW_DUE_DATE = "UPDATE_INSTALMENTS_WITH_NEW_DUE_DATE";
export const UPDATE_SINGLE_EXTRA_PRODUCT_INSTALMENTS = "UPDATE_SINGLE_EXTRA_PRODUCT_INSTALMENTS";
export const UPDATE_ALL_EXTRA_PRODUCT_INSTALMENTS = "UPDATE_ALL_EXTRA_PRODUCT_INSTALMENTS";
export const SET_SHOULD_ASK_CREATING_NEW_SUBS = "SET_SHOULD_ASK_CREATING_NEW_SUBS";
export const LOAD_CAMPAIGN_CO_PRODUCTS = "LOAD_CAMPAIGN_CO_PRODUCTS";
export const SHOULD_DISPLAY_CUSTOMER_CONFIRM_MODAL = "SHOULD_DISPLAY_CUSTOMER_CONFIRM_MODAL"; // #27610
export const CHANGING_CUSTOMER_PROPERTIES = "CHANGING_CUSTOMER_PROPERTIES";
export const SET_PREFER_INVMODE = "SET_PREFER_INVMODE"; // Build 3.6.2.2 (Issue #31348)
export const SET_INVMODE_PAPERLIST = "SET_INVMODE_PAPERLIST";
export const SET_MARKETING_MATERIAL = "SET_MARKETING_MATERIAL"; // Build 3.6.4.2 (Issue #33372)
export const SET_HISTORY_SELECTION = "SET_HISTORY_SELECTION"; // Build 3.4.6.2 (Issue #33409)
export const LOAD_WINSALE_TYPES = "LOAD_WINSALE_TYPES"; // Build 3.6.4.2 (Issue #26462)
export const LOAD_POST_MACHINES = "LOAD_POST_MACHINES"; // Build 3.6.4.2 (Issue #12471)

// Address actions
export const SHOULD_DISPLAY_ADDRESS_CONFIRM_MODAL = "SHOULD_DISPLAY_ADDRESS_CONFIRM_MODAL";
export const LOAD_DELIVERY_INFORMATION = "LOAD_DELIVERY_INFORMATION";
export const LOAD_EMPTY_DELIVERY_INFORMATION = "LOAD_EMPTY_DELIVERY_INFORMATION";
export const SET_SELECTED_WEEKDAY = "SET_SELECTED_WEEKDAY";
export const FOCUS_CHECK = "FOCUS_CHECK";
export const SHOW_CHANGE_FOCUS_MODAL = "SHOW_CHANGE_FOCUS_MODAL";
export const CLOSE_CHANGE_FOCUS_MODAL = "CLOSE_CHANGE_FOCUS_MODAL";
export const LOAD_EVENT_POOL_DATA = "LOAD_EVENT_POOL_DATA";
export const RESET_EVENT_POOL_DATA = "RESET_EVENT_POOL_DATA";
export const LOAD_COUNTRIES = "LOAD_COUNTRIES";
export const LOAD_DELIVERY_CHANGES = "LOAD_DELIVERY_CHANGES";
export const LOAD_CUSTOMER_ADDRESSES = "LOAD_CUSTOMER_ADDRESSES";
export const LOAD_CUSTOMER_ALL_ADDRESSES = "LOAD_CUSTOMER_ALL_ADDRESSES";
export const LOAD_DELIVERY_ADDRESS = "LOAD_DELIVERY_ADDRESS";
export const LOAD_DOOR_DELIVERIES = "LOAD_DOOR_DELIVERIES";
export const LOAD_SERVICED_DOOR_DELIVERIES = "LOAD_SERVICED_DOOR_DELIVERIES";
export const ADD_ADDRESS_TO_SERVICED_DOOR_DELIVERIES = "ADD_ADDRESS_TO_SERVICED_DOOR_DELIVERIES";
export const SELECT_DELIVERY_TO_DOOR_ADDRESS = "SELECT_DELIVERY_TO_DOOR_ADDRESS";
export const DELETE_SELECTED_DELIVERY_TO_DOOR_ADDRESS = "DELETE_SELECTED_DELIVERY_TO_DOOR_ADDRESS";
export const SET_DELIVERY_TO_DOOR_TYPE = "SET_DELIVERY_TO_DOOR_TYPE";
export const LOAD_LABEL_MACHINES = "LOAD_LABEL_MACHINES";
export const LOAD_ADDRESS_FOCUS_DATA = "LOAD_ADDRESS_FOCUS_DATA";
export const LOAD_PENDING_FOCUS_CHANGES = "LOAD_PENDING_FOCUS_CHANGES";
export const SHOULD_RELOAD_DELIVERY_INFORMATION = "SHOULD_RELOAD_DELIVERY_INFORMATION";
export const LOAD_EMPTY_ADDRESS = "LOAD_EMPTY_ADDRESS";
export const SET_MESSAGE_OPEN = "SET_MESSAGE_OPEN";
export const SET_ADDRESS_NOT_IN_DI_OPEN = "SET_ADDRESS_NOT_IN_DI_OPEN"; // #33092
export const SET_BASIC_ADDRESS_EXITS = "SET_BASIC_ADDRESS_EXITS"; // #33092
export const SET_HOLIDAY_ADDRESS_EXITS = "SET_HOLIDAY_ADDRESS_EXITS"; // #33092
export const SET_SPLIT_ADDRESS_EXITS = "SET_SPLIT_ADDRESS_EXITS"; // #33092
export const SET_INVOICING_ADDRESS_EXITS = "SET_INVOICING_ADDRESS_EXITS"; // #33092
export const SET_CUSTOMER_ADDRESS_EXITS = "SET_CUSTOMER_ADDRESS_EXITS"; // #33092
export const SET_BASIC_ADDRESS_CHECK_COMPLETE = "SET_BASIC_ADDRESS_CHECK_COMPLETE"; // #33092
export const SET_HOLIDAY_ADDRESS_CHECK_COMPLETE = "SET_HOLIDAY_ADDRESS_CHECK_COMPLETE"; // #33092
export const SET_SPLIT_ADDRESS_CHECK_COMPLETE = "SET_SPLIT_ADDRESS_CHECK_COMPLETE"; // #33092
export const SET_INVOICING_ADDRESS_CHECK_COMPLETE = "SET_INVOICING_ADDRESS_CHECK_COMPLETE"; // #33092
export const SET_CUSTOMER_ADDRESS_CHECK_COMPLETE = "SET_CUSTOMER_ADDRESS_CHECK_COMPLETE"; // #33092

// Invoice arguments actions
export const LOAD_INSTALMENTS = "LOAD_INSTALMENTS";
export const LOAD_EXTRA_CHARGE_INSTALMENTS = "LOAD_EXTRA_CHARGE_INSTALMENTS";
export const SET_SELECTED_INSTALMENT = "SET_SELECTED_INSTALMENT";
export const LOAD_ALL_ARGUMENTS = "LOAD_ALL_ARGUMENTS";
export const LOAD_PRICELIST_NUMBER = "LOAD_PRICELIST_NUMBER";
export const LOAD_PRICELIST = "LOAD_PRICELIST";
export const LOAD_STANDING_PRICELIST_ROWS = "LOAD_STANDING_PRICELIST_ROWS";
export const LOAD_PERIODIC_PRICELIST_ROWS = "LOAD_PERIODIC_PRICELIST_ROWS";
export const CLEAR_INVOICE_ARGUMENTS = "CLEAR_INVOICE_ARGUMENTS";
export const SET_IMMEDIATE_INVOICE_ROWS = "SET_IMMEDIATE_INVOICE_ROWS";
export const SET_SELECTED_INVOICE_INSTALMENTS = "SET_SELECTED_INVOICE_INSTALMENTS";
export const SET_SELECTED_INVOICE_EXTRA_CHARGES = "SET_SELECTED_INVOICE_EXTRA_CHARGES";
export const SELECT_INVOICE_INSTALMENT = "SELECT_INVOICE_INSTALMENT";
export const SELECT_INVOICE_EXTRA_CHARGE = "SELECT_INVOICE_EXTRA_CHARGE";
export const UNSELECT_INVOICE_INSTALMENT = "UNSELECT_INVOICE_INSTALMENT";
export const UNSELECT_INVOICE_EXTRA_CHARGE = "UNSELECT_INVOICE_EXTRA_CHARGE";
export const SET_SELECTED_CREDIT_INVOICE_ROWS = "SET_SELECTED_CREDIT_INVOICE_ROWS";
export const SET_SELECTED_CREDIT_INVOICE_ROW_ITEM = "SET_SELECTED_CREDIT_INVOICE_ROW_ITEM";
export const LOAD_INVOICE = "LOAD_INVOICE";
export const LOAD_INVOICE_TEXT = "LOAD_INVOICE_TEXT";
export const LOAD_INVOICE_DETAILS = "LOAD_INVOICE_DETAILS";
export const LOAD_INVOICE_HISTORY_DATES = "LOAD_INVOICE_HISTORY_DATES";
export const SET_AVAILABLE_OFFICES = "SET_AVAILABLE_OFFICES";
export const SET_CREDIT_INVOICE_CHECK = "SET_CREDIT_INVOICE_CHECK";
export const RESET_INSTALMENTS = "RESET_INSTALMENTS";

// Ledger actions
export const LOAD_LEDGER = "LOAD_LEDGER";
export const SELECT_LEDGER_LOG = "SELECT_LEDGER_LOG";
export const SELECT_SUB_FOR_LEDGER_LOG = "SELECT_SUB_FOR_LEDGER_LOG";
export const LOAD_PAYMENTS = "LOAD_PAYMENTS";
export const SET_SELECTED_PAYMENT = "SET_SELECTED_PAYMENT";
export const SET_SHOULD_RELOAD_LEDGER = "SET_SHOULD_RELOAD_LEDGER";

// Feedback actions
export const SET_FEEDBACKS = "SET_FEEDBACKS";
export const SELECT_FEEDBACK = "SELECT_FEEDBACK";
export const CLEAR_FEEDBACKS = "CLEAR_FEEDBACKS";
export const NEW_FEEDBACK = "NEW_FEEDBACK";
export const FEEDBACK_SAVED = "FEEDBACK_SAVED";
export const CLOSE_NEW_FEEDBACK = "CLOSE_NEW_FEEDBACK";
export const LOAD_ARGUMENT = "LOAD_ARGUMENT"; // Build 3.6.4.1 (Issue #32668)

// Customer contact actions
export const SET_CUSTOMER_CONTACTS = "SET_CUSTOMER_CONTACTS";
export const SELECT_CUSTOMER_CONTACT = "SELECT_CUSTOMER_CONTACT";
export const CLEAR_CUSTOMER_CONTACTS = "CLEAR_CUSTOMER_CONTACTS";

// Email actions
export const NEW_EMAIL = "NEW_EMAIL";
export const CLEAR_EMAIL = "CLEAR_EMAIL";
export const CLOSE_EMAIL = "CLOSE_EMAIL";

// Miscellaneous
export const SET_SYSTEM_PARAMETERS = "SET_SYSTEM_PARAMETERS";
export const SET_OFFICES_FROM_SYSTEM_PARAMETERS = "SET_OFFICES_FROM_SYSTEM_PARAMETERS";
export const SET_SOURCE_GROUPS = "SET_SOURCE_GROUPS";
export const SET_CONTACT_TYPES = "SET_CONTACT_TYPES";
export const SET_PHONECHECK_SOURCES = "SET_PHONECHECK_SOURCES";
export const SET_PHONE_STATUS = "SET_PHONE_STATUS";
export const SET_CUSTOMER_LANGUAGES = "SET_CUSTOMER_LANGUAGES";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const SET_RETURNCHANNELS = "SET_RETURNCHANNELS";
export const SET_CAI_PARAMETERS = "SET_CAI_PARAMETERS";
export const LOAD_INVOICE_MODE_FIELDS = "LOAD_INVOICE_MODE_FIELDS";
export const SET_SOURCEMATERIALS = "SET_SOURCEMATERIALS";
export const SET_COMMITMENTPERIODS = "SET_COMMITMENTPERIODS";
export const SET_PAYMCODE = "SET_PAYMCODE";
// #32663
export const SET_RETTYPE = "SET_RETTYPE";

// Co-distribution
export const SET_CO_DISTRIBUTION_SEARCH = "SET_CO_DISTRIBUTION_SEARCH";
export const SELECT_CO_DISTRIBUTION_SEARCH_RESULT = "SELECT_CO_DISTRIBUTION_SEARCH_RESULT";
export const CO_DISTRIBUTION_PROCESSING = "CO_DISTRIBUTION_PROCESSING";
export const LOAD_CO_DISTRIBUTION = "LOAD_CO_DISTRIBUTION";
export const TOGGLE_NEW_CO_DISTRIBUTION = "TOGGLE_NEW_CO_DISTRIBUTION";
export const LOAD_DELIVERY_CLIENTS = "LOAD_DELIVERY_CLIENTS";
export const LOAD_PAPER_LIST = "LOAD_PAPER_LIST";
export const LOAD_BUNDLES = "LOAD_BUNDLES";

// Autogiro
export const SET_AUTOGIRO = "SET_AUTOGIRO";

// Report
export const SET_REPORT_GRID = "SET_REPORT_GRID";
export const SELECT_REPORT_ROW = "SELECT_REPORT_ROW";
export const SELECT_REPREQUEST_ROW = "SELECT_REPREQUEST_ROW";

// Report Issue #28110
export const TOGGLE_NEW_REPORT_MODAL = "TOGGLE_NEW_REPORT_MODAL";

// Build 3.6.4.2 (ERP #11828)
// ContactInfo
export const SET_CONTACT_INFOS = "SET_CONTACT_INFOS";