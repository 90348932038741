import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router";

import { store } from "../store.js";
import { setAuth } from "../actions/user.js";
import { notify } from "../actions/notification.js";
import { initAvailableTranslations, initTranslation, tr } from "../utils/translation.js";
import { initLocales, initUserLocale } from "../utils/locale.js";
import { validate } from "../utils/auth.js";
import { initPublishers, getUserProperties, getUserSettings } from "../utils/user.js";

import MainContainer from "./Main.jsx";
import Loading from "../components/Loading.jsx";
import GlobalNotification from "./Notification.jsx";
import lazyComponent from "./LazyComponent.jsx";

import "../assets/styles/app.scss";

const Login = lazyComponent(() => import(/* webpackChunkName: "login" */ "./Login.jsx"));

// PrivateRoute is the parent component for every component that needs authentication.
// It checks authentication status and either redirects to the login page or to a given component.
const PrivateRoute = ({ component: Component, ...kwargs }) => (
  <Route
    {...kwargs}
    render={(props) =>
      store.getState().user.authenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

class App extends React.Component {
  _continueIfReady() {
    const ready =
      this.state.userReady &&
      this.state.settingsReady &&
      this.state.translationReady &&
      this.state.publishersReady &&
      this.state.validated;

    if (ready) {
      this.setState({ initialized: true });
    }
  }

  _setPublishersReady(success) {
    if (success) {
      this.setState({ publishersReady: true }, () => {
        this._continueIfReady();
      });
    }
  }

  _setUserReady() {
    this.setState({ userReady: true }, () => {
      this._continueIfReady();
    });
  }

  _setSettingsReady() {
    this.setState({ settingsReady: true }, () => {
      this._continueIfReady();
    });
  }

  _setValidated() {
    if (store.getState().user.authenticated !== null) {
      if (this.validated !== null) {
        this.validated();
      }
      this.setState({ validated: true }, () => {
        this._continueIfReady();
      });
    }
  }

  _stateChange() {
    const state = store.getState();
    const states = {
      translation: state.translation.current,
      locale: state.locale.current,
    };

    for (const key of Object.keys(states)) {
      if (this.state[key] !== states[key]) {
        this.setState({ ...states });
        break;
      }
    }
  }

  constructor(props) {
    super(props);
    // The application needs to check authentication status and localStorage state
    // before it can determine how to proceed. After checks have been performed,
    // 'initialized' will be set to true and the application is rendered.
    this.validated = null;
    this.state = {
      userReady: false,
      settingsReady: false,
      translationReady: false,
      publishersReady: false,
      validated: false,
      initialized: false,
      translation: store.getState().translation.current,
      locale: store.getState().locale.current,
      errorState: false,
    };
  }

  componentDidMount() {
    // Subscribe to application wide changes
    store.subscribe(this._stateChange.bind(this));
    // Subscribe to authenticated actions until it has been called once
    this.validated = store.subscribe(this._setValidated.bind(this));

    // Initialize application's locales (and set a default locale)
    initLocales();

    // Validate authentication status by polling the server
    validate()
      .then((data) => {
        const authenticated = data.authenticated;

        // Initialize available translations
        initAvailableTranslations(() => {
          // After available translations are known, set an appropriate translation
          initTranslation(authenticated, () => {
            this.setState({ translationReady: true }, () => {
              this._continueIfReady();
            });
          });
        });

        if (authenticated) {
          initUserLocale();
          getUserProperties(this._setUserReady.bind(this));
          getUserSettings(this._setSettingsReady.bind(this));
          initPublishers(this._setPublishersReady.bind(this));
        } else {
          this._setUserReady();
          this._setSettingsReady();
          this._setPublishersReady(true);
        }
        store.dispatch(setAuth(data));
      })
      .catch(() => {
        store.dispatch(notify(tr("dbConnectFailure"), "error"));
      });
  }

  /*
   * Show a generic "Something went wrong" error message when unrecoverable error occurs.
   * Default behaviour would be to show a blank page, which is not helpful for a normal user.
   */
  componentDidCatch() {
    this.setState({ errorState: true });
  }

  render() {
    return this.state.errorState ? (
      <div className="error-view-container">
        <div className="error-view">
          <h2>Oops! Something went wrong...</h2>
          <span>
            Try refreshing the page. If that doesn&apos;t help, contact the administrator.
          </span>
        </div>
      </div>
    ) : (
      <div className="page-container">
        <GlobalNotification />
        {!this.state.initialized ? (
          <Loading loading={true} />
        ) : (
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/reset-password" component={Login} />
            <Route path="/confirm-email" component={Login} />
            <PrivateRoute component={MainContainer} />
          </Switch>
        )}
      </div>
    );
  }
}

export default App;
