/* eslint-disable react/prop-types */
import React from "react";
import { Button } from "reactstrap";
import { SubmissionError } from "redux-form";

import Modal from "../Modal.jsx";
import { Container, ContentContainer } from "../common/layout.jsx";
import Loading from "../Loading.jsx";
import LanguageSelection from "../../containers/LanguageSelection.jsx";
import LocaleSelection from "./LocaleSelection.jsx";
import ChangePasswordForm from "./ChangePasswordForm.jsx";
import ChangeEmailForm from "./ChangeEmailForm.jsx";
import ConfirmationModal from "../ConfirmationModal.jsx";

import { store } from "../../store.js";
import { tr } from "../../utils/translation.js";
import { Fetcher } from "../../utils/fetcher";
import apiUrl from "../../utils/url.js";
import { setUserData } from "../../utils/user.js";
import { notify } from "../../actions/notification.js";
import { setUserSettings } from "../../actions/user.js";
import TextModeSelection from "../../containers/TextModeSelection.jsx";

/**
 * Modal to group all possible user customizable settings,
 * such as language selection or password change.
 */
class SettingsModal extends React.Component {
  _languageChange() {
    const value = store.getState().translation.processing;
    if (value !== undefined) {
      this.setState({ processingTranslation: value });
    }
  }

  _handlePasswordChangeSubmit(values) {
    // Make POST request to change password
    const url = apiUrl("users/change-password", false);

    const requestBody = {
      current_password: values.oldPassword,
      new_password: values.newPassword,
    };

    return Fetcher.rawFetch(url, "POST", requestBody)
      .then((response) => {
        switch (response.status) {
          case 200:
          case 204:
            store.dispatch(notify(tr("passwordUpdated"), "success"));
            return;

          case 401:
            store.dispatch(notify(tr("wrongOldPassword"), "warning"));
            throw new SubmissionError();

          case 409:
            store.dispatch(notify(tr("newPasswordSameAsOldPassword"), "warning"));
            throw new SubmissionError();

          default:
            store.dispatch(notify(tr("passwordUpdateFailed"), "error"));
            throw new SubmissionError();
        }
      })
      .catch((error) => {
        throw new SubmissionError(error);
      });
  }

  _handleEmailChangeSubmit(values) {
    // Request to change email
    const url = apiUrl("users/request-email-change", false);

    return Fetcher.post(url, values)
      .then((response) => {
        if (response.status === 204) {
          store.dispatch(notify(tr("emailChangeRequestSend"), "success"));
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          store.dispatch(notify(tr("unauthorized"), "warning"));
        } else {
          store.dispatch(notify(tr("emailChangeRequestFailed"), "error"));
        }
        throw new SubmissionError(error);
      });
  }

  _confirm(value) {
    if (value === "confirmed") {
      this._userSettingsReset();
    }

    this._toggleConfirm();
  }

  _toggleConfirm() {
    this.setState({ confirmModalOpen: !this.state.confirmModalOpen });
  }

  _userSettingsReset() {
    setUserData("settings", "{}");
    store.dispatch(setUserSettings(null));
    store.dispatch(notify(tr("settingsCleared"), "success"));
  }

  constructor(props) {
    super(props);

    this.state = {
      processingTranslation: false,
      confirmModalOpen: false,
    };

    this.unsubscribe = null;
    this._userSettingsReset = this._userSettingsReset.bind(this);
    this._confirm = this._confirm.bind(this);
    this._toggleConfirm = this._toggleConfirm.bind(this);
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(this._languageChange.bind(this));
  }

  componentWillUnmount() {
    if (this.unsubscribe !== null) {
      this.unsubscribe();
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title={tr("settings")}
        className="single-tab-modal"
      >
        <ContentContainer className="modal-content-container">
          <Container>
            <Loading loading={this.state.processingTranslation} />
            <div>
              <h2>{tr("changeLanguage")}</h2>
            </div>
            <div className="sub-content">
              <LanguageSelection />
            </div>
            <div>
              <h2>{tr("locale")}</h2>
            </div>
            <div className="sub-content">
              <LocaleSelection />
            </div>
            <ChangePasswordForm onSubmit={this._handlePasswordChangeSubmit} />
            <ChangeEmailForm onSubmit={this._handleEmailChangeSubmit} />
            {/* #27619, show or hide textno in webkayak */}
            <div>
              <h2>{tr("textmode")}</h2>
            </div>
            <div className="sub-content">
              <TextModeSelection />
            </div>
            <div className="sub-content">
              <h2>{tr("clearSettings")}</h2>
              <p>{tr("clearSettingsExplanation")}</p>
              <Button color="danger" className="float-right" onClick={this._toggleConfirm}>
                {tr("clearSettings").toUpperCase()}
              </Button>
            </div>
            <ConfirmationModal
              isOpen={this.state.confirmModalOpen}
              toggle={this._confirm}
              title={tr("confirm")}
              question={`${tr("clearSettings")}?`}
              confirmButtonText={tr("yes")}
              discardButtonText={tr("cancel")}
            />
          </Container>
        </ContentContainer>
        <div className="modal-footer">
          <Button color="default" onClick={this.props.toggle} className="no-margin">
            {tr("close")}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default SettingsModal;
