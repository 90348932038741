import React from "react";

/**
 * Component to provide visual presentation about how
 * weak or strong given password is.
 */
const PasswordStrength = (props) => {
  if (props.strength < 0) {
    return (
      <div className="password-strength-bar">
        <div className="password-light password-danger"></div>
        <div className="password-light"></div>
        <div className="password-light password-no-border"></div>
      </div>
    );
  } else if (props.strength === 0) {
    return (
      <div className="password-strength-bar">
        <div className="password-light password-warning"></div>
        <div className="password-light password-warning"></div>
        <div className="password-light password-no-border"></div>
      </div>
    );
  } else if (props.strength > 0) {
    return (
      <div className="password-strength-bar password-success">
        <div className="password-light"></div>
        <div className="password-light"></div>
        <div className="password-light password-no-border"></div>
      </div>
    );
  } else {
    return (
      <div className="password-strength-bar">
        <div className="password-light"></div>
        <div className="password-light"></div>
        <div className="password-light password-no-border"></div>
      </div>
    );
  }
};

export default PasswordStrength;
