import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";

import userReducer from "./userReducer.js";
import adminReducer from "./adminReducer.js"; // #33168 add users admin
import translationsReducer from "./translationsReducer.js";
import localeReducer from "./localeReducer.js";
import notificationReducer from "./notificationReducer.js";
import customerReducer from "./customerReducer.js";
import feedbackReducer from "./feedbackReducer.js";
import customerContactsReducer from "./customerContactsReducer.js";
import invoiceReducer from "./invoiceReducer.js";
import addressReducer from "./addressReducer.js";
import parameterReducer from "./parameterReducer.js";
import emailReducer from "./emailReducer.js";
import { CHANGE_PUBLISHER } from "../actions/actionTypes.js";
import coDistributionReducer from "./coDistributionReducer.js";

// Combine all reducers, rename them, and export them for the redux store to use.
const applicationReducer = combineReducers({
  user: userReducer,
  admin: adminReducer, // #33168 add users admin
  translation: translationsReducer,
  locale: localeReducer,
  form: reduxFormReducer,
  notification: notificationReducer,
  customer: customerReducer,
  feedback: feedbackReducer,
  customerContacts: customerContactsReducer,
  invoice: invoiceReducer,
  address: addressReducer,
  parameter: parameterReducer,
  email: emailReducer,
  coDistribution: coDistributionReducer,
});

const rootReducer = (state, action) => {
  // When the current publisher is changed, all the data in the Redux store related
  // to the old publisher should be wiped out. This is achieved by giving undefined
  // as a state to reducers which forces them to their default state.
  if (action.type === CHANGE_PUBLISHER) {
    state = {
      ...state,
      form: undefined,
      customer: undefined,
      parameter: undefined,
      user: { ...state.user, defaults: {} },
    };
  }

  return applicationReducer(state, action);
};

export default rootReducer;
