import { store } from "../store.js";

const API_VERSION = "v1";

export const getSearchParams = (query = "") => {
  const allSearchParams = new URLSearchParams(query);
  let paramsObj = null;

  const keys = allSearchParams.keys();
  for (const k of keys) {
    paramsObj = { ...paramsObj, [k]: allSearchParams.get(k) };
  }

  return paramsObj;
};

// Constructs an API URL.
// Takes a resource to request and usePrefix which denotes whether the current
// publisher specific api prefix should be included in the resulting URL.
const apiUrl = (resource, usePrefix = true) => {
  if (usePrefix) {
    const publishers = store.getState().user.publishers;
    const publisher = publishers.publishers[publishers.current];

    return `/api/${API_VERSION}/${publisher}/${resource}`;
  } else {
    return `/api/${API_VERSION}/${resource}`;
  }
};

export default apiUrl;
