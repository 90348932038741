/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";

import { switchLocale } from "../../utils/locale.js";
import Dropdown from "../Dropdown.jsx";

/**
 * LocaleSelection renders dropdown component which can be used
 * to change the output locale.
 *
 * Usage:
 * <LocaleSelection />
 */
const LocaleSelection = (props) => {
  return (
    <Dropdown
      sendSelection={(localeSelection) => switchLocale(localeSelection)}
      items={props.locales}
      placeholder={props.current}
    />
  );
};

const mapStateToProps = (state) => {
  const current = state.locale.current;
  const locales = state.locale.locales;

  return { current, locales };
};

export default connect(mapStateToProps)(LocaleSelection);
