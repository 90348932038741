import * as actions from "../actions/actionTypes.js";

const defaultState = {
  available: {},
  current: null,
  translations: {},
  translationDebugMode: "TRANSLATION_ONLY",
  translationDebugModes: {
    TRANSLATION_ONLY: "Only translation",
    TRANSLATION_AND_CODE: "Translation and code",
    ONLY_CODE: "Only code",
  },
};

const translationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_TRANSLATION: {
      const newTranslations = { ...state.translations, [action.langCode]: action.values };
      return { ...state, translations: newTranslations };
    }
    case actions.SWITCH_TRANSLATION:
      if (action.value in state.translations) {
        return { ...state, current: action.value };
      }
      return state;

    case actions.SET_AVAILABLE_TRANSLATIONS:
      return { ...state, available: action.value };

    case actions.PROCESSING:
      return { ...state, processing: action.value };

    case actions.SET_TRANSLATION_DEBUG_MODE:
      return { ...state, translationDebugMode: action.value };

    default:
      return state;
  }
};

export default translationsReducer;
