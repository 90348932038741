import React from "react";
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from "reactstrap";
import PropTypes from "prop-types";

import { tr } from "../../utils/translation.js";
import SettingsModal from "../settings/SettingsModal.jsx";

/*
 * Dropdown menu component used in the top header.
 */
class HeaderMenu extends React.Component {
  _toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  _toggleModal() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  constructor(props) {
    super(props);
    this.state = { menuOpen: false, modalOpen: false };
  }

  render() {
    return (
      <div
        id="header-menu"
        className={this.state.menuOpen ? "header-button active" : "header-button"}
      >
        <SettingsModal isOpen={this.state.modalOpen} toggle={this._toggleModal.bind(this)} />
        <Dropdown nav isOpen={this.state.menuOpen} toggle={this._toggleMenu.bind(this)}>
          <DropdownToggle nav caret>
            <span>
              <i className="icon ion-md-settings pr-2"></i>
              {this.props.user.username}
            </span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this._toggleModal.bind(this)}>{tr("settings")}</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.props.logout}>{tr("logout")}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  logout: PropTypes.func.isRequired, // A function to call on logout
  user: PropTypes.object.isRequired, // An object containing user information
};

export default HeaderMenu;
